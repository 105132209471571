/**
 * @module AddCardScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { STRINGS, STRIPE_CARD_ELEMENT_OPTIONS } from '../../../../../utils';

const paymentMethodStrings = STRINGS.modals.paymentMethod;

/**
 * Represents the Stripe card section shown to the user when adding a new
 * payment method.
 *
 * @param {object} props - The component props object.
 * @param {React.ReactElement} props.captchaElement - Google reCAPTCHA element to render on the form.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {Function} props.onStripeError - Handler function for Stripe error event.
 * @param {Function} props.onStripeSuccess - Handler function for Stripe success event.
 * @param {React.Ref} props.reference - React Ref attributed to a hidden button to trigger form submit.
 * @param {boolean} [props.useSingleCardElement] - Boolean flag denoting whether or not to use single-line CardElement Stripe component or separate components for card number, expiry, and cvc (default: true).
 *
 * @returns {React.ReactElement} The AddCardScreen component.
 *
 * @see {@link https://stripe.com/docs/js/element/the_element_container}.
 * @see {@link https://stripe.com/docs/payments/accept-card-payments?platform=web&ui=elements&client=react}.
 */
export function AddCardScreen({
  captchaElement,
  className,
  onStripeError,
  onStripeSuccess,
  reference,
  useSingleCardElement = true,
}) {
  const elements = useElements();
  const stripe = useStripe();

  /**
   * Handler function for form submission.
   */
  /* istanbul ignore next */
  async function handleFormSubmit() {
    // Use card element to create payment method.
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    });

    if (error) {
      onStripeError(error);
    } else {
      onStripeSuccess(paymentMethod);
    }
  }

  // Return if Stripe is unavailable.
  /* istanbul ignore next */
  if (!stripe || !elements) {
    return null;
  }

  return (
    <div className={className}>
      {
        /* istanbul ignore next */ useSingleCardElement ? (
          <CardElement
            className="form-control"
            options={STRIPE_CARD_ELEMENT_OPTIONS}
          />
        ) : (
          <>
            <div className="form-control g-12 grid-item">
              <label htmlFor="stripe_card_number">
                {paymentMethodStrings.add.labels.cardNumber}
              </label>
              <CardNumberElement
                id="form_stripe_card_number"
                name="stripe_card_number"
                options={STRIPE_CARD_ELEMENT_OPTIONS}
              />
            </div>
            <div className="row">
              <div className="form-control g-7 grid-item">
                <label htmlFor="stripe_exp_date">
                  {paymentMethodStrings.add.labels.expirationDate}
                </label>
                <CardExpiryElement
                  id="form_stripe_exp_date"
                  name="stripe_exp_date"
                  options={STRIPE_CARD_ELEMENT_OPTIONS}
                />
              </div>
              <div className="form-control g-4 grid-item">
                <label htmlFor="stripe_cvc">
                  {paymentMethodStrings.add.labels.cvc}
                </label>
                <CardCvcElement
                  id="form_stripe_cvc"
                  name="stripe_cvc"
                  options={STRIPE_CARD_ELEMENT_OPTIONS}
                />
              </div>
            </div>
          </>
        )
      }
      <div className="form-control g-12 grid-item">{captchaElement}</div>
      <div className="form-control g-12 grid-item">
        <button
          className="visually-hidden"
          onClick={handleFormSubmit}
          ref={reference}
        ></button>
      </div>
    </div>
  );
}
