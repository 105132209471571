/**
 * @module Processing
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentPage } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import Loading from '../../assets/svg/loading.svg';
import { ANALYTICS, STRINGS } from '../../utils';
import './Processing.scss';

/**
 * Represents a processing state overlay with loading spinner and text content
 * for status, a quote, and quote reference.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.includeQuote - Boolean flag denoting whether or not to include quote and reference.
 * @param {string} props.quote - The quote string value used to display.
 * @param {string} props.reference - The quote reference/attribution value.
 * @param {string} props.status - The status string (e.g. 'Processing').
 *
 * @returns {React.ReactElement} The Processing.
 */
export function Processing({
  includeQuote,
  quote,
  reference,
  status,
  ...passThroughProps
}) {
  /**
   * Single-run effect to trigger analytics event.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    callSegmentPage({
      category: '',
      name: ANALYTICS.pages.givingProcessing,
      properties: {
        logged_in: !!passThroughProps?.user,
        path: window?.location?.pathname,
        preferred_campus: passThroughProps?.preferredCampus,
        referrer: document?.referrer,
        screen_class: ANALYTICS.screens.classes.oneScreen,
        title: document?.title,
        url: /* istanbul ignore next */ window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="processing flex-center">
      <div className="processing-contents">
        <img alt={STRINGS.labels.loading} src={Loading} />
        {status ? <h2 className="status">{status}</h2> : null}
        {includeQuote ? (
          <>
            {quote ? <p className="quote">{quote}</p> : null}
            {reference ? <p className="reference">{reference}</p> : null}
          </>
        ) : null}
      </div>
    </div>
  );
}
