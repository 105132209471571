/**
 * @module FrequencyModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import {
  ANALYTICS,
  FORM_MODES,
  STRINGS,
  closestTwiceMonthlyDate,
} from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { RadioListItem } from '../../RadioListItem';
import '../Modal.scss';

/**
 * Represents the modal to show a list of Frequencies for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {'main'|'manage-gift'} [props.mode] - The mode for which to use for the component (Default: 'main').
 * @param {Function} props.onChange - Handler function for input change event.
 *
 * @returns {React.ReactElement} The FrequencyModal component.
 */
export function FrequencyModal({ isOpen, mode = FORM_MODES.main, onChange }) {
  const { user } = useAuth();
  const {
    frequencies,
    getFrequency,
    preferredCampus,
    scheduledGiftData,
    storeScheduledGiftData,
    storeUserGivingData,
    userGivingData,
  } = useGiving();
  const { handleModalClose, modals } = useModals();
  const selectedValue =
    mode === FORM_MODES.manageGift
      ? parseInt(getFrequency(scheduledGiftData?.attributes?.frequency)?.id, 10)
      : parseInt(userGivingData?.frequency?.id, 10);
  const frequencyList = frequencies?.filter(
    (frequency) =>
      frequency?.attributes?.code
        .toLowerCase()
        .replace(' ', '')
        .replace('-', '') !== 'onetime',
  );

  /**
   * Handler function for close trigger events.
   *
   * @param {Event} event - The Event object associated with the close event.
   */
  function handleClose(event) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingFrequency,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label:
          event.target.textContent === STRINGS.labels.done
            ? STRINGS.labels.done
            : STRINGS.labels.close,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingFrequency,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalClose(modals.frequency.id);
  }

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.givingFrequency,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingFrequency,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value:
          mode === FORM_MODES.manageGift
            ? scheduledGiftData?.attributes?.frequency
            : userGivingData?.frequency?.attributes?.name,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Represents a radio list item populated with the specified Frequency data.
   *
   * @param {object} props - The component props object.
   * @param {Frequency} props.frequency - The Frequency data object.
   *
   * @returns {React.ReactElement} FrequencyListItem component.
   */
  const FrequencyListItem = ({ frequency }) => {
    return (
      <RadioListItem
        inputName={frequency?.attributes?.name.toLowerCase().replace(' ', '-')}
        key={`${frequency?.attributes?.name.toLowerCase().replace(' ', '-')}`}
        label={frequency?.attributes?.name}
        onChange={
          /* istanbul ignore next */ (e) => {
            e.preventDefault();

            /* istanbul ignore next */
            callSegmentTrack({
              event: ANALYTICS.events.givingValueUpdated,
              properties: {
                action: ANALYTICS.actions.updated,
                component: ANALYTICS.screens.names.givingFrequency,
                component_url: null,
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.frequency,
                logged_in: !!user,
                preferred_campus: preferredCampus?.attributes?.code,
                referrer: document?.referrer,
                title: document?.title,
                url: window?.location?.href,
                user_id: user?.['https://www.life.church/rock_person_alias_id'],
                value: frequency?.attributes?.name,
              },
            });

            const isTwiceMonthly = parseInt(frequency?.id, 10) === 134;
            const dataPaymentDate =
              mode === FORM_MODES.main
                ? userGivingData.paymentDate
                : scheduledGiftData?.attributes?.next_payment_date;
            const storedPaymentDate = new Date(
              dataPaymentDate
                ? dataPaymentDate * 1000
                : new Date().getTime() / 1000,
            );
            const updatedPaymentDate =
              closestTwiceMonthlyDate(storedPaymentDate);
            if (mode === FORM_MODES.manageGift) {
              storeScheduledGiftData({
                attributes: {
                  frequency: frequency.attributes.code,
                  next_payment_date: isTwiceMonthly
                    ? updatedPaymentDate.getTime() / 1000
                    : storedPaymentDate.getTime() / 1000,
                },
                id: scheduledGiftData.id,
                type: scheduledGiftData.type,
              });
            } else {
              storeUserGivingData({
                ...userGivingData,
                frequency,
                paymentDate: isTwiceMonthly
                  ? updatedPaymentDate.getTime() / 1000
                  : storedPaymentDate.getTime() / 1000,
              });
            }

            /* istanbul ignore next */
            if (onChange && typeof onChange === 'function') {
              onChange(frequency, mode);
            }
          }
        }
        selectedValue={selectedValue}
        value={parseInt(frequency?.id, 10)}
      />
    );
  }; // NOSONAR

  return (
    <BaseModal
      content={
        <div
          className="list grouped mb-16"
          data-mode={mode}
          data-testid="frequency-modal"
        >
          {frequencyList?.map((frequencyItem) => {
            return (
              <FrequencyListItem
                frequency={frequencyItem}
                key={frequencyItem?.id}
              />
            );
          })}
        </div>
      }
      contentClassName="pt-none"
      footer={
        <StyledButton
          className="full-width"
          onClick={handleClose}
          variant={ButtonVariants.primary}
        >
          {STRINGS.labels.done}
        </StyledButton>
      }
      header={
        <ModalHeader
          onCloseClick={handleClose}
          title={STRINGS.modals.frequency.title}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
