/**
 * @module useAuth
 */
import React from 'react';
import { AuthContext } from '../../context/AuthContext';

/**
 * React hook for accessing `AuthContext`. Must be used inside of a `AuthProvider` context.
 *
 * @throws {Error} - Throws an error if used outside of a `AuthProvider` context.
 *
 * @returns {React.Context} - The AuthContext values.
 *
 * @example
 * import useAuth from 'hooks/useAuth';
 *
 * function SampleComponent() {
 *   const { isAuthenticated, user } = useAuth();
 *
 *   return (
 *     <SomeComponent isAuthenticated={isAuthenticated} userData={user} />
 *   )
 * }
 */
function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export default useAuth;
