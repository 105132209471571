/**
 * @module CreditCardIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents an icon for a credit card.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The CreditCardIcon element.
 */
export default function CreditCardIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="credit-card-icon">
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M28 8.41797V23.5846C28 24.0513 27.82 24.4596 27.46 24.8096C27.1 25.1596 26.68 25.3346 26.2 25.3346H5.8C5.32 25.3346 4.9 25.1596 4.54 24.8096C4.18 24.4596 4 24.0513 4 23.5846V8.41797C4 7.9513 4.18 7.54297 4.54 7.19297C4.9 6.84297 5.32 6.66797 5.8 6.66797H26.2C26.68 6.66797 27.1 6.84297 27.46 7.19297C27.82 7.54297 28 7.9513 28 8.41797ZM5.8 11.5971H26.2V8.41797H5.8V11.5971ZM5.8 15.3596V23.5846H26.2V15.3596H5.8Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
