/**
 * @module FundsModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import { ANALYTICS, FORM_MODES, STRINGS } from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { RadioListItem } from '../../RadioListItem';
import '../Modal.scss';

/**
 * Represents the modal to show a list of Funds for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {'main'|'manage-gift'} [props.mode] - The mode for which to use for the component (Default: 'main').
 *
 * @returns {React.ReactElement} The FundsModal component.
 */
export function FundsModal({ isOpen, mode = FORM_MODES.main }) {
  const { funds: fundsStrings } = STRINGS.modals;
  const { user } = useAuth();
  const {
    funds,
    getFund,
    preferredCampus,
    scheduledGiftData,
    storeScheduledGiftData,
    storeUserGivingData,
    userGivingData,
  } = useGiving();
  const { handleModalClose, modals } = useModals();
  const titheFunds = funds.filter((fund) => fund?.attributes['tithe?']);
  const beyondTitheFunds = funds.filter((fund) => !fund?.attributes['tithe?']);
  const selectedValue =
    mode === FORM_MODES.manageGift
      ? getFund(scheduledGiftData?.attributes?.fund)?.id
      : userGivingData?.fund?.id || null;

  /**
   * Handler function for the close event triggers.
   *
   * @param {Event} event - The Event object associated with the close event.
   */
  function handleClose(event) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingFund,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label:
          event.target.textContent === STRINGS.labels.done
            ? STRINGS.labels.done
            : STRINGS.labels.close,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingFund,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalClose(modals.funds.id);
  }

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.givingFund,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingFund,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: userGivingData?.fund?.attributes?.name,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Represents a radio list item populated with the specified Fund data.
   *
   * @param {object} props - The component props object.
   * @param {Fund} props.fund - The Fund data object.
   *
   * @returns {React.ReactElement} The FundListItem component.
   */
  const FundListItem = ({ fund }) => {
    return (
      <RadioListItem
        description={fund?.attributes?.description_text}
        icon={
          <img
            alt={fund?.attributes?.name}
            className="start-icon"
            src={fund?.attributes?.icon}
          />
        }
        inputName={fund?.attributes?.name?.toLowerCase().replace(' ', '-')}
        key={`${fund?.attributes?.name?.toLowerCase()?.replace(' ', '-')}`}
        onChange={
          /* istanbul ignore next */ (event) => {
            event.preventDefault();
            callSegmentTrack({
              event: ANALYTICS.events.givingValueUpdated,
              properties: {
                action: ANALYTICS.actions.updated,
                component: ANALYTICS.screens.names.givingFund,
                component_url: null,
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.close,
                logged_in: !!user,
                preferred_campus: preferredCampus?.attributes?.code,
                referrer: document?.referrer,
                title: document?.title,
                url: window?.location?.href,
                user_id: user?.['https://www.life.church/rock_person_alias_id'],
                value: fund?.attributes.name,
              },
            });

            if (mode === FORM_MODES.manageGift) {
              storeScheduledGiftData({
                attributes: {
                  fund: fund?.attributes?.name,
                },
                id: scheduledGiftData.id,
                type: scheduledGiftData.type,
              });
            } else {
              storeUserGivingData({ ...userGivingData, fund });
            }
          }
        }
        selectedValue={selectedValue}
        title={fund?.attributes?.name}
        titleProps={{ primary: true }}
        value={fund?.id}
      />
    );
  }; // NOSONAR

  return (
    <BaseModal
      content={
        <div className="list mb-16" data-mode={mode} data-testid="funds-modal">
          {titheFunds?.map((fundItem) => {
            return <FundListItem fund={fundItem} key={fundItem?.id} />;
          })}
          <p className={['gray100', 'uppercase'].join(' ')}>
            {fundsStrings.offerings}
          </p>
          <div className="list-container">
            {beyondTitheFunds?.map((fundItem) => {
              return <FundListItem fund={fundItem} key={fundItem?.id} />;
            })}
          </div>
        </div>
      }
      contentClassName="pt-none"
      footer={
        <StyledButton
          className="full-width"
          onClick={handleClose}
          variant={ButtonVariants.primary}
        >
          {STRINGS.labels.done}
        </StyledButton>
      }
      header={
        <ModalHeader onCloseClick={handleClose} title={fundsStrings.title} />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
