/**
 * @module PaymentMethodModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import {
  ButtonVariants,
  StyledButton,
} from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';
import braintree from 'braintree-web';
import { publicIpv4 } from 'public-ip';
import {
  createPaymentMethod,
  getFinancialConnectionsSession,
  verifyResponse,
} from '../../../api/giving';
import { AddBankAccountScreen } from './screens/AddBankAccountScreen';
import { AddCardScreen } from './screens/AddCardScreen';
import { AddPaymentMethodScreen } from './screens/AddPaymentMethodScreen';
import { SelectPaymentMethodScreen } from './screens/SelectPaymentMethodScreen';
import { Loading } from '../../../views/Loading';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import {
  ANALYTICS,
  APP_CONFIG,
  BRAINTREE_ERRORS,
  FORM_MODES,
  ICON_OVERRIDES,
  MODAL_MODES,
  SMART_PAY_PROVIDERS,
  STRINGS,
  logError,
} from '../../../utils';
import '../Modal.scss';
import './PaymentMethod.scss';

const paymentMethodStrings = STRINGS.modals.paymentMethod;

/**
 * Represents a convenience wrapper component that returns the screen component related to the specified type.
 *
 * @param {object} props - The component props object.
 * @param {object} props.captchaElement - Google reCAPTCHA elements to render on the form (contains keys and corresponding HTML elements).
 * @param {string} [props.className] - Optional class name to attribute to the component returned.
 * @param {Function} [props.onBankFormFieldChange] - Handler function for Bank form field change events.
 * @param {Function} [props.onStripeError] - Handler function for Stripe error event.
 * @param {Function} [props.onStripeSuccess] - Handler function for Stripe success event.
 * @param {object} [props.references] - Object of React Ref objects to attribute to bank and card screens.
 * @param {boolean} [props.submitNewBankForm] - Boolean flag denoting when to trigger form submit for new bank account.
 * @param {'bank'|'card'} [props.type] - The component type to return.
 *
 * @returns {React.ReactElement} The AddNewScreen component, which represents either a `AddBankAccountScreen` or `AddCardScreen` component.
 */
/* istanbul ignore next */
function AddNewScreen({
  captchaElement,
  className,
  onBankFormFieldChange,
  onStripeError,
  onStripeSuccess,
  references,
  type,
}) {
  switch (type) {
    case 'bank':
      return (
        <AddBankAccountScreen
          captchaElement={captchaElement.bank}
          className={className}
          onFormFieldChange={onBankFormFieldChange}
          onStripeError={onStripeError}
          onStripeSuccess={onStripeSuccess}
          reference={references?.bank}
        />
      );
    case 'card':
      return (
        <AddCardScreen
          captchaElement={captchaElement.card}
          className={className}
          onStripeError={onStripeError}
          onStripeSuccess={onStripeSuccess}
          reference={references?.card}
        />
      );
    default:
      return null;
  }
}

/**
 * Represents the modal to show a user's Payment Methods.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.iconOverride] - Optional icon override to use in place of default "x" close icon.
 * @param {boolean} [props.includePayPal] - Boolean flag denoting whether or not to include PayPal button and logic (default: true).
 * @param {boolean} [props.includeVenmo] - Boolean flag denoting whether or not to include Venmo button and logic (default: false).
 * @param {boolean} [props.includeSmartPay] - Boolean flag denoting whether or not to include Smart Pay options and logic (default: true).
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {'main'|'manage-gift'} [props.modalMenuMode] - The modal mode for which to use for the component (Default: 'main').
 * @param {('add'|'new-bank'|'new-card'|'select')} [props.mode] - The mode (state) of the modal (Default: 'select').
 *
 * @returns {React.ReactElement} The PaymentMethodModal component.
 */
export function PaymentMethodModal({
  iconOverride,
  includePayPal = true,
  includeSmartPay = true,
  includeVenmo = false,
  isOpen,
  modalMenuMode = FORM_MODES.main,
  mode,
  ...passThroughProps
}) {
  const { getAccessToken, isAuthenticated, logIn, user } = useAuth();
  const {
    braintreeClient,
    fetchGivingData,
    financialConnectionsSessionToken,
    paymentMethods,
    preferredCampus,
    scheduledGiftData,
    smartPayProviderData,
    storeFinancialConnectionsSessionToken,
    storeScheduledGiftData,
    storeUserGivingData,
    userGivingData,
  } = useGiving();
  const { handleModalClose, modals } = useModals();
  const stripe = useStripe();
  const [includeSelectMode, setIncludeSelectMode] = React.useState(
    /* istanbul ignore next */ (paymentMethods?.length > 0 ||
      !Object.values(smartPayProviderData).every((item) => item === false)) &&
      modalMenuMode !== FORM_MODES.managePaymentMethod,
  );
  const [modalMode, setModalMode] = React.useState(
    MODAL_MODES[
      includeSelectMode || mode === MODAL_MODES.select
        ? MODAL_MODES.select
        : MODAL_MODES.add
    ],
  );
  const [isValidBankForm, setIsValidBankForm] = React.useState(false);
  const [
    availableBraintreePaymentMethods,
    setAvailableBraintreePaymentMethods,
  ] = React.useState({
    payPal: false,
    venmo: false,
  });
  const addNewBankRef = React.createRef();
  const addNewCardRef = React.createRef();

  const [captchaElement] = React.useState({
    bank: (
      <div
        className="g-recaptcha new-bank"
        data-callback="onReCaptchaCallback"
        data-error-callback="onReCaptchaErrorOrExpired"
        data-expired-callback="onReCaptchaErrorOrExpired"
        data-sitekey={process.env.RECAPTCHA_SITE_KEY}
        id="id_grecaptcha_new-bank"
      ></div>
    ),
    card: (
      <div
        className="g-recaptcha new-card"
        data-callback="onReCaptchaCallback"
        data-error-callback="onReCaptchaErrorOrExpired"
        data-expired-callback="onReCaptchaErrorOrExpired"
        data-sitekey={process.env.RECAPTCHA_SITE_KEY}
        id="id_grecaptcha_new-card"
      ></div>
    ),
  });
  const [captchaToken, setCaptchaToken] = React.useState(null);
  const [isNetworkRequestProcessing, setIsNetworkRequestProcessing] =
    React.useState(false);

  // Convenience state to store new payment type, either 'bank' or 'card'.
  const [newPaymentType, setNewPaymentType] = React.useState('card');

  /**
   * Ensure specified mode is one supported by the modal.
   *
   * Note: Due to the conditionals used and change in mode set by state, the
   * ignore directive is needed even though all scenarios are covered in the
   * tests already, so there is confidence in functionality and coverage.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (
      Object.values(MODAL_MODES).includes(mode) &&
      (mode !== MODAL_MODES.select ||
        (mode === MODAL_MODES.select && includeSelectMode))
    ) {
      setModalMode(mode);
    }
  }, [includeSelectMode, mode]);

  /**
   * Convenience effect to determine whether or not to enable including mode for
   * selecting payment method.
   *
   * Note: The logic of the conditional check here sets the select and modal
   * modes based on the availability/length of user payment methods and whether
   * or not any smart pay providers are available. It also checks for the
   * presence of the stored action for adding payment method, and sets the
   * modal mode to "add" with select include mode set to true, and clears out
   * the stored action for adding payment method to ensure it won't re-load on
   * a regular page reload or modal close/re-open scenario. Also, there is a
   * very necessary check for user isAuthenticated, to ensure the "Add" mode is
   * not shown for unauthenticated users, as is an edge case if a user triggers
   * the login flow from clicking "Add" and then users the browser Back button
   * to come back to the site without actually authenticating.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (
      !paymentMethods?.length &&
      includeSmartPay &&
      Object.values(smartPayProviderData).every((item) => item === false)
    ) {
      setIncludeSelectMode(false);
      if (isAuthenticated) {
        setModalMode(MODAL_MODES.add);
      }
    } else if (userGivingData?.actionAddPaymentMethod) {
      if (modalMenuMode !== FORM_MODES.managePaymentMethod) {
        setIncludeSelectMode(true);
      }
      if (isAuthenticated) {
        setModalMode(MODAL_MODES.add);
      }
      storeUserGivingData({
        actionAddPaymentMethod: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods, smartPayProviderData]);

  /**
   * Handler function for modal close event.
   */
  /* istanbul ignore next */
  function handleClose() {
    if (isNetworkRequestProcessing) {
      return;
    }
    switch (passThroughProps?.testOverride ? mode : modalMode) {
      case MODAL_MODES.add:
        /**
         * Note: Ignore added due to in-component state; coverage added for the
         * general Segment function trigger which is sufficient.
         */
        /* istanbul ignore next */
        if (includeSelectMode) {
          callSegmentTrack({
            event: ANALYTICS.events.buttonAction,
            properties: {
              action: ANALYTICS.actions.clicked,
              component: ANALYTICS.screens.names.addPaymentMethod,
              component_url: null,
              context: ANALYTICS.contexts.oneScreen,
              label: ANALYTICS.labels.back,
              logged_in: !!user,
              preferred_campus: preferredCampus?.attributes?.code,
              referrer: document?.referrer,
              screen: ANALYTICS.screens.names.addPaymentMethod,
              title: document?.title,
              url: window?.location?.href,
              user_id: user?.['https://www.life.church/rock_person_alias_id'],
            },
          });
          setModalMode(MODAL_MODES.select);
        } else {
          callSegmentTrack({
            event: ANALYTICS.events.buttonAction,
            properties: {
              action: ANALYTICS.actions.clicked,
              component: ANALYTICS.screens.names.givingPaymentMethod,
              component_url: null,
              context: ANALYTICS.contexts.oneScreen,
              label: ANALYTICS.labels.close,
              logged_in: !!user,
              preferred_campus: preferredCampus?.attributes?.code,
              referrer: document?.referrer,
              screen: ANALYTICS.screens.names.givingPaymentMethod,
              title: document?.title,
              url: window?.location?.href,
              user_id: user?.['https://www.life.church/rock_person_alias_id'],
            },
          });
          handleModalClose(modals.paymentMethod.id);
        }
        break;
      case MODAL_MODES.newBank:
      case MODAL_MODES.newCard:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component:
              modalMode === MODAL_MODES.newBank
                ? ANALYTICS.screens.names.addBankAccount
                : ANALYTICS.screens.names.addCreditCard,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.back,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen:
              modalMode === MODAL_MODES.newBank
                ? ANALYTICS.screens.names.addBankAccount
                : ANALYTICS.screens.names.addCreditCard,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        setModalMode(MODAL_MODES.add);
        break;
      default:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.givingPaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.close,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.givingPaymentMethod,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        handleModalClose(modals.paymentMethod.id);
        break;
    }
  }

  /**
   * Handler function for click event of Manually Add Bank Account as payment method.
   */
  function handleAddBankAccountManualClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.addPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.bankAccount,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.addPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    setNewPaymentType('bank');
    setModalMode(MODAL_MODES.newBank);
  }

  /**
   * Handler function for Stripe token generation success event.
   *
   * @param {object} params - The function params object.
   * @param {Array<StripeFinancialConnectionsSessionAccount>} params.connectedAccounts - The Stripe financial connection accounts data object array.
   * @param {number} params.current - The current account number in the array of accounts.
   * @param {number} params.total - The total account number in the array of accounts.
   */
  /* istanbul ignore next */
  async function saveFinancialConnectionsAccounts({
    connectedAccounts,
    current,
    total,
  }) {
    // Stripe token successfully generated. Store in user giving data.
    const connectedAccountData = connectedAccounts[current];

    // Submit create payment method API call.
    try {
      const result = await createPaymentMethod({
        accessToken: getAccessToken(),
        paymentToken: connectedAccountData?.id,
      });

      callSegmentTrack({
        event: ANALYTICS.events.paymentMethodCreated,
        properties: {
          action: ANALYTICS.actions.created,
          component: ANALYTICS.screens.names.addPaymentMethod,
          component_url: null,
          context: ANALYTICS.contexts.oneScreen,
          label: ANALYTICS.events.paymentMethodCreated,
          logged_in: !!user,
          preferred_campus: preferredCampus?.attributes?.code,
          referrer: document?.referrer,
          title: document?.title,
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
          value: result?.data?.attributes?.payment_method_type,
        },
      });

      // If there are more accounts to save, call self to keep recursion going.
      if (current < total - 1) {
        saveFinancialConnectionsAccounts({
          connectedAccounts,
          current: current + 1,
          total,
        });
      } else {
        // Re-fetch user giving data and set back to select mode.
        fetchGivingData({
          callback: ({ error }) => {
            if (!error) {
              Log.log(
                'Financial connection payment method(s) successfully added, with last being set to payment method.',
              );
              if (
                [
                  FORM_MODES.manageGift,
                  FORM_MODES.managePaymentMethod,
                ].includes(modalMenuMode)
              ) {
                storeScheduledGiftData({
                  attributes: {
                    payment_method: {
                      ...result?.data?.attributes,
                      id: result?.data?.id,
                    },
                  },
                });
              } else {
                storeUserGivingData({
                  ...userGivingData,
                  paymentMethod: result?.data,
                });
              }
            }
            setModalMode(MODAL_MODES.select);
            setIsNetworkRequestProcessing(false);
          },
        });
      }
    } catch (error) {
      setIsNetworkRequestProcessing(false);
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(
        new Error(STRINGS.modals.paymentMethod.add.financialConnectionError),
        {
          browserConsole: false,
          bugsnag: false,
          windowAlert: true,
        },
      );

      // Re-fetch user giving data and set back to select mode.
      fetchGivingData({
        callback: () => {
          setModalMode(MODAL_MODES.select);
          setIsNetworkRequestProcessing(false);
        },
      });
    }
  }

  /**
   * Handler method for Braintree payment method tokenize success.
   *
   * @param {string} nonce - The Braintree payment method nonce.
   */
  /* istanbul ignore next */
  async function handleBraintreeTokenizeSuccess(nonce) {
    // Stripe token successfully generated. Store in user giving data.
    storeUserGivingData({
      ...userGivingData,
      token: nonce,
    });

    // Submit create payment method API call.
    try {
      const result = await createPaymentMethod({
        accessToken: getAccessToken(),
        paymentToken: nonce,
      });

      if (result?.errors) {
        const { detail } = result.errors[0];
        setIsNetworkRequestProcessing(false);

        callSegmentTrack({
          event: ANALYTICS.events.createPaymentMethodError,
          properties: {
            action: ANALYTICS.actions.error,
            component: ANALYTICS.screens.names.addPaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            error: detail,
            label: detail,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        logError(new Error(detail));

        /**
         * Calling logError() again, but with false values for bugsnag and
         * browserConsole so it only displays for user.
         */
        logError(
          new Error(STRINGS.modals.paymentMethod.add.createPaymentMethodError),
          {
            browserConsole: false,
            bugsnag: false,
            windowAlert: true,
          },
        );
      } else {
        /**
         * Note: Two separate events, one for payment created, one for giving
         * value updated to track changed payment method.
         */
        callSegmentTrack({
          event: ANALYTICS.events.paymentMethodCreated,
          properties: {
            action: ANALYTICS.actions.created,
            component: ANALYTICS.screens.names.addPaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.actions.created,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
            value: result?.data?.attributes?.payment_method_type,
          },
        });

        callSegmentTrack({
          event: ANALYTICS.events.givingValueUpdated,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.screens.names.addPaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.paymentMethod,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
            value: result?.data?.id,
          },
        });

        // Get the new payment method, re-fetch user giving data, set new method.
        fetchGivingData({
          callback: ({ error }) => {
            if (!error) {
              if (modalMenuMode === FORM_MODES.manageGift) {
                storeScheduledGiftData({
                  attributes: {
                    payment_method: {
                      ...result?.data?.attributes,
                      id: result?.data?.id,
                    },
                  },
                });
              } else {
                storeUserGivingData({
                  ...userGivingData,
                  paymentMethod: result?.data,
                });
              }
            }
            setIsNetworkRequestProcessing(false);
            if (includeSelectMode) {
              setModalMode(MODAL_MODES.select);
            } else if (modalMenuMode === FORM_MODES.managePaymentMethod) {
              handleModalClose(modals.paymentMethod.id);
            } else {
              handleClose();
            }
          },
        });
      }
    } catch (error) {
      setIsNetworkRequestProcessing(false);
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(
        new Error(STRINGS.modals.paymentMethod.add.createPaymentMethodError),
        {
          browserConsole: false,
          bugsnag: false,
          windowAlert: true,
        },
      );
    }
  }

  /**
   * Function to collect and trigger Stripe financial connections account flow.
   *
   * @param {string} fcSession - The financial connections session token.
   *
   * @see {@link https://stripe.com/docs/financial-connections/other-data-powered-products?platform=web}.
   */
  /* istanbul ignore next */
  async function collectStripeFinancialConnectionsAccounts(fcSession) {
    try {
      const financialConnectionsSessionResult =
        await stripe.collectFinancialConnectionsAccounts({
          clientSecret: financialConnectionsSessionToken ?? fcSession,
        });
      /**
       * If the list of accounts is more than zero, kick off the recursive
       * function to save each of the connected accounts as payment methods in
       * the Giving API.
       */
      if (
        financialConnectionsSessionResult?.financialConnectionsSession?.accounts
          ?.length
      ) {
        setIsNetworkRequestProcessing(true);
        saveFinancialConnectionsAccounts({
          connectedAccounts:
            financialConnectionsSessionResult?.financialConnectionsSession
              ?.accounts,
          current: 0,
          total:
            financialConnectionsSessionResult?.financialConnectionsSession
              ?.accounts?.length,
        });
      } else {
        setIsNetworkRequestProcessing(false);
      }
    } catch (error) {
      logError(error);
      setIsNetworkRequestProcessing(false);
    }
  }

  /**
   * Handler function for click event of Sign in to Add Bank Account as payment method.
   */
  /* istanbul ignore next */
  async function handleAddBankAccountSignInClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.addPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.bankAccountSignIn,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.addPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    try {
      const financialConnectionsSessionResponse =
        await getFinancialConnectionsSession({
          accessToken: getAccessToken(),
        });
      const fcSession = financialConnectionsSessionResponse?.fc_session;
      storeFinancialConnectionsSessionToken(fcSession);
      collectStripeFinancialConnectionsAccounts(fcSession);
    } catch (error) {
      logError(error);
    }
  }

  /**
   * Handler function for click event of Add payment method.
   */
  function handleAddCardClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.addPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.creditDebitCard,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.addPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    setNewPaymentType('card');
    setModalMode(MODAL_MODES.newCard);
  }

  /**
   * Handler function for click event of Add payment method.
   */
  function handleAddPaymentMethodClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.add,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    // If user is authenticated, set to "add" mode. Otherwise, store the action
    // for adding payment method in user giving data and trigger login flow.
    /* istanbul ignore next */
    if (isAuthenticated) {
      setModalMode(MODAL_MODES.add);
    } else {
      storeUserGivingData({
        actionAddPaymentMethod: true,
      });
      logIn();
    }
  }

  /**
   * Handler function for PayPal click event of Add payment method.
   */
  /* istanbul ignore next */
  function handlePayPalClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.addPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.payPal,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.addPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    /**
     * Create PayPal instance via Braintree. If successful, call tokenize
     * method and pass result nonce to handler for payment method creation.
     */
    braintree.paypal.create(
      {
        client: braintreeClient,
      },
      (payPalError, payPalInstance) => {
        if (payPalError) {
          logError(new Error(payPalError));

          /**
           * Calling logError() again, but with false values for bugsnag and
           * browserConsole so it only displays for user.
           */
          logError(new Error(STRINGS.modals.paymentMethod.add.payPalError), {
            browserConsole: false,
            bugsnag: false,
            windowAlert: true,
          });
        } else {
          payPalInstance.tokenize(
            {
              flow: 'vault',
              intent: 'authorize',
            },
            (tokenizeError, payload) => {
              if (tokenizeError) {
                switch (tokenizeError.code) {
                  case BRAINTREE_ERRORS.tokenize.payPal.popupClosed:
                    logError(new Error('Customer closed PayPal popup.'), {
                      bugsnag: false,
                      windowAlert: false,
                    });
                    break;
                  case BRAINTREE_ERRORS.tokenize.payPal
                    .accountTokenizationFailed:
                  case BRAINTREE_ERRORS.tokenize.payPal.flowFailed:
                    logError(new Error(tokenizeError.details), {
                      bugsnag: false,
                    });
                    break;
                  default:
                    logError(new Error(tokenizeError));
                    break;
                }

                /**
                 * Calling logError() again, but with false values for bugsnag and
                 * browserConsole so it only displays for user.
                 */
                logError(
                  new Error(STRINGS.modals.paymentMethod.add.payPalError),
                  {
                    browserConsole: false,
                    bugsnag: false,
                    windowAlert: true,
                  },
                );
              } else {
                handleBraintreeTokenizeSuccess(payload?.nonce);
              }
            },
          );
        }
      },
    );
  }

  /**
   * Handler function for Venmo click event of Add payment method.
   */
  /* istanbul ignore next */
  function handleVenmoClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.addPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.venmo,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.addPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    /**
     * Create Venmo instance via Braintree. If successful, call tokenize
     * method and pass result nonce to handler for payment method creation.
     *
     * Source: https://braintree.github.io/braintree-web/current/module-braintree-web_venmo.html#.create.
     * Source: https://braintree.github.io/braintree-web/current/Venmo.html.
     *
     * Note: This needs to be tested and configured properly based on decisions
     * about browser support option.
     */
    braintree.venmo
      .create({
        allowAndroidRecreation: false,
        allowDesktop: true,
        // allowDesktopWebLogin: true,
        // allowNewBrowserTab: false,
        client: braintreeClient,
        // mobileWebFallBack: true,
      })
      .then((venmoInstance) => {
        venmoInstance
          .tokenize()
          .then((payload) => {
            handleBraintreeTokenizeSuccess(payload?.nonce);
          })
          .catch((tokenizeError) => {
            switch (tokenizeError.code) {
              case BRAINTREE_ERRORS.tokenize.venmo.appCanceled:
                logError(new Error('User canceled Venmo flow.'), {
                  bugsnag: false,
                });
                break;
              case BRAINTREE_ERRORS.tokenize.venmo.canceled:
                logError(
                  new Error(
                    'User canceled Venmo, or Venmo app is not available.',
                  ),
                  {
                    bugsnag: false,
                  },
                );
                break;
              default:
                logError(new Error(tokenizeError));
                break;
            }
          });
      })
      .catch((venmoError) => {
        logError(new Error(venmoError));

        /**
         * Calling logError() again, but with false values for bugsnag and
         * browserConsole so it only displays for user.
         */
        logError(new Error(STRINGS.modals.paymentMethod.add.venmoError), {
          browserConsole: false,
          bugsnag: false,
          windowAlert: true,
        });
      });
  }

  /**
   * Handler function for bank form field change events.
   *
   * @param {object} data - Form data, with key for `errors`, containing errors (or null) for accountNumber and routingNumber.
   */
  /* istanbul ignore next */
  function handleBankFormFieldChange(data) {
    let formHasErrors = false;
    Object.values(data.errors).forEach((value) => {
      if (value) {
        formHasErrors = true;
      }
    });
    setIsValidBankForm(formHasErrors);
  }

  /**
   * Handler function for modal footer click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  function handleModalFooterButton(event) {
    if (event) {
      event.preventDefault();
    }
    switch (modalMode) {
      case MODAL_MODES.newBank:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.addBankAccount,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.done,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.addBankAccount,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        setIsNetworkRequestProcessing(true);
        if (addNewBankRef?.current) {
          addNewBankRef.current.click();
        }
        break;
      case MODAL_MODES.newCard:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.addCreditCard,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.done,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.addCreditCard,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        setIsNetworkRequestProcessing(true);
        if (addNewCardRef?.current) {
          addNewCardRef.current.click();
        }
        break;
      default:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.givingPaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.done,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.givingPaymentMethod,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        handleModalClose(modals.paymentMethod.id);
        break;
    }
  }

  /**
   * Handler function for Stripe token generation error event.
   *
   * @param {Error} error - The event Error object.
   *
   * @see {@link https://stripe.com/docs/api/errors}.
   */
  /* istanbul ignore next */
  function handleStripeError(error) {
    callSegmentTrack({
      event: ANALYTICS.events.paymentMethodError,
      properties: {
        action: ANALYTICS.actions.error,
        component: ANALYTICS.screens.names.addPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        error: error.message,
        label: error.message,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.addPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    setIsNetworkRequestProcessing(false);
    logError(error);

    // Calling again, but with false values for bugsnag and browserConsole so it
    // only displays for user.
    logError(new Error(STRINGS.modals.paymentMethod.add.errorHints.invalid), {
      browserConsole: false,
      bugsnag: false,
      windowAlert: true,
    });
  }

  /**
   * Handler function for Stripe token generation success event.
   *
   * @param {StripeToken} token - The Stripe token object.
   */
  /* istanbul ignore next */
  async function handleStripeSuccess(token) {
    // Stripe token successfully generated. Store in user giving data.
    storeUserGivingData({
      ...userGivingData,
      token,
    });

    // Submit create payment method API call.
    try {
      const result = await createPaymentMethod({
        accessToken: getAccessToken(),
        paymentToken: token?.id,
      });

      if (result?.errors) {
        const { detail } = result.errors[0];
        setIsNetworkRequestProcessing(false);

        callSegmentTrack({
          event: ANALYTICS.events.createPaymentMethodError,
          properties: {
            action: ANALYTICS.actions.error,
            component: ANALYTICS.screens.names.addPaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            error: detail,
            label: detail,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        logError(new Error(detail));

        /**
         * Calling logError() again, but with false values for bugsnag and
         * browserConsole so it only displays for user.
         */
        logError(
          new Error(STRINGS.modals.paymentMethod.add.createPaymentMethodError),
          {
            browserConsole: false,
            bugsnag: false,
            windowAlert: true,
          },
        );
      } else {
        /**
         * Note: Two separate events, one for payment created, one for giving
         * value updated to track changed payment method.
         */
        callSegmentTrack({
          event: ANALYTICS.events.paymentMethodCreated,
          properties: {
            action: ANALYTICS.actions.created,
            component: ANALYTICS.screens.names.addPaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.actions.created,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
            value: result?.data?.attributes?.payment_method_type,
          },
        });

        callSegmentTrack({
          event: ANALYTICS.events.givingValueUpdated,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.screens.names.addPaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.paymentMethod,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
            value: result?.data?.id,
          },
        });

        // Get the new payment method, re-fetch user giving data, set new method.
        fetchGivingData({
          callback: ({ error }) => {
            if (!error) {
              if (
                [
                  FORM_MODES.manageGift,
                  FORM_MODES.managePaymentMethod,
                ].includes(modalMenuMode)
              ) {
                storeScheduledGiftData({
                  attributes: {
                    payment_method: {
                      ...result?.data?.attributes,
                      id: result?.data?.id,
                    },
                  },
                });
              } else {
                storeUserGivingData({
                  ...userGivingData,
                  paymentMethod: result?.data,
                });
              }
            }
            setIsNetworkRequestProcessing(false);
            if (includeSelectMode) {
              setModalMode(MODAL_MODES.select);
            } else if (modalMenuMode === FORM_MODES.managePaymentMethod) {
              handleModalClose(modals.paymentMethod.id);
            } else {
              handleClose();
            }
          },
        });
      }
    } catch (error) {
      setIsNetworkRequestProcessing(false);
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(
        new Error(STRINGS.modals.paymentMethod.add.createPaymentMethodError),
        {
          browserConsole: false,
          bugsnag: false,
          windowAlert: true,
        },
      );
    }
  }

  /**
   * Convenience function to reset state-stored reCAPTCHA token and trigger it
   * to be reset at the window level.
   */
  /* istanbul ignore next */
  function resetCaptcha() {
    setCaptchaToken(null);
    window.resetCaptcha();
  }

  /**
   * Verification function for reCAPTCHA. If failure, reset to force the user
   * to try again.
   */
  /* istanbul ignore next */
  const verifyCaptcha = React.useCallback(async () => {
    try {
      const remoteIp = await publicIpv4();
      const reCaptchaResponse = await verifyResponse({
        remoteIp,
        response: window.reCaptchaToken,
        siteKey: process.env.RECAPTCHA_SITE_KEY,
        type: APP_CONFIG.reCaptchaVersion,
      });
      if (!reCaptchaResponse?.success) {
        resetCaptcha();
      }
    } catch (error) {
      logError(error);
      resetCaptcha();
    }
  }, []);

  /**
   * Convenience effect to initialize reCAPTCHA, when on an appropriate mode.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (
      window.isReCaptchaLoaded &&
      [MODAL_MODES.newBank, MODAL_MODES.newCard].includes(modalMode)
    ) {
      window.initReCaptcha(modalMode);
    }
  }, [modalMode]);

  /**
   * Convenience effect to set scroll position on mode change.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    const pmModalContentCollection =
      document.getElementsByClassName('modal-content');
    const pmModalContent = pmModalContentCollection
      ? pmModalContentCollection[0]
      : null;
    if (pmModalContent) {
      pmModalContent.scrollTop = 0;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalMode]);

  /**
   * Handler function for Google reCAPTCHA events.
   *
   * @param {Event} event - The Event object.
   */
  /* istanbul ignore next */
  function handleReCaptchaEvent(event) {
    if (event) {
      switch (event.detail) {
        case 'callback':
          setCaptchaToken(window.reCaptchaToken);
          verifyCaptcha();
          break;
        case 'errorOrExpired':
          setCaptchaToken(null);
          break;
        case 'reset':
          setCaptchaToken(null);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Single-run convenience effect to add reCAPTCHA event listeners.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    window.addEventListener('onReCaptchaEvent', handleReCaptchaEvent);
    return () => {
      window.removeEventListener('onReCaptchaEvent', handleReCaptchaEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    let smartPayProviderIncludesComparator =
      userGivingData?.paymentMethod?.attributes?.payment_method_type;
    let paymentMethodTypeComparator =
      userGivingData?.paymentMethod?.attributes?.payment_type;
    /* istanbul ignore next */
    if (
      [FORM_MODES.manageGift, FORM_MODES.managePaymentMethod].includes(
        modalMenuMode,
      )
    ) {
      smartPayProviderIncludesComparator =
        scheduledGiftData?.attributes?.payment_method?.payment_method_type;
      paymentMethodTypeComparator =
        scheduledGiftData?.attributes?.payment_method?.payment_type;
    }

    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.givingPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        count: paymentMethods?.length,
        label: ANALYTICS.labels.givingPaymentMethod,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],

        value: /* istanbul ignore next */ Object.keys(
          SMART_PAY_PROVIDERS,
        ).includes(smartPayProviderIncludesComparator)
          ? SMART_PAY_PROVIDERS[smartPayProviderIncludesComparator].attributes
              ?.display_label
          : paymentMethodTypeComparator,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect to figure out availability of Braintree payment options.
   *
   * Note: Ignore directive added due to not mocking third-party package object
   * and integration. Overrides set via passThroughProps for test to accurately
   * test this functionality for inclusion.
   *
   * Source: https://braintree.github.io/braintree-web/current/module-braintree-web_paypal.html#.isSupported.
   * Source: https://braintree.github.io/braintree-web/current/module-braintree-web_venmo.html#.isBrowserSupported.
   *
   * Note: This needs to be tested and configured properly based on decisions
   * about browser support option for Venmo.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (braintreeClient) {
      setAvailableBraintreePaymentMethods({
        payPal: includePayPal && braintree.paypal.isSupported(),
        venmo:
          includeVenmo &&
          braintree.venmo.isBrowserSupported({
            allowNewBrowserTab: false,
            allowWebviews: true,
          }),
      });
    }
  }, [braintreeClient, includePayPal, includeVenmo]);

  // Convenience variables to store class names for the main parent content div.
  const contentClassName = 'animatable-content payment-methods thirds';
  let contentPlacementClass = 'active-1';
  let modalHeaderClassName =
    iconOverride && iconOverride === ICON_OVERRIDES.back
      ? 'modal-header-mode-back'
      : '';

  /* istanbul ignore next */
  if (modalMode === MODAL_MODES.add) {
    contentPlacementClass = 'active-2';
    if (includeSelectMode) {
      modalHeaderClassName = 'modal-header-mode-back';
    }
  } else if ([MODAL_MODES.newBank, MODAL_MODES.newCard].includes(modalMode)) {
    contentPlacementClass = 'active-3';
    modalHeaderClassName = 'modal-header-mode-back';
  }

  return (
    <BaseModal
      className={iconOverride ? 'animate-rtl' : ''}
      content={
        <>
          <div
            className={[contentClassName, contentPlacementClass].join(' ')}
            data-testid="payment-method-modal"
          >
            <SelectPaymentMethodScreen
              includeSmartPay={includeSmartPay}
              mode={modalMenuMode}
              paymentMethods={paymentMethods}
            />
            <AddPaymentMethodScreen
              includePayPal={
                /* istanbul ignore next */ availableBraintreePaymentMethods.payPal ||
                (passThroughProps?.testOverride &&
                  passThroughProps?.includePayPalOverride)
              }
              includeSmartPay={includeSmartPay}
              includeVenmo={
                /* istanbul ignore next */ availableBraintreePaymentMethods.venmo ||
                (passThroughProps?.testOverride &&
                  passThroughProps?.includeVenmoOverride)
              }
              onAddBankAccountManualClick={handleAddBankAccountManualClick}
              onAddBankAccountSignInClick={handleAddBankAccountSignInClick}
              onAddCardClick={handleAddCardClick}
              onPayPalClick={handlePayPalClick}
              onVenmoClick={handleVenmoClick}
            />
            <AddNewScreen
              captchaElement={captchaElement}
              className="form"
              onBankFormFieldChange={handleBankFormFieldChange}
              onStripeError={handleStripeError}
              onStripeSuccess={handleStripeSuccess}
              references={{
                bank: addNewBankRef,
                card: addNewCardRef,
              }}
              type={newPaymentType}
            />
          </div>
          {
            /* istanbul ignore next */ isNetworkRequestProcessing ? (
              <div className="loading-wrapper">
                <Loading />
              </div>
            ) : null
          }
        </>
      }
      contentClassName={['pt-none', 'animatable'].join(' ')}
      footer={
        modalMode !== MODAL_MODES.add ? (
          <StyledButton
            className="full-width ml-0 mr-0"
            disabled={
              /* istanbul ignore next */
              isNetworkRequestProcessing ||
              ([MODAL_MODES.newBank, MODAL_MODES.newCard].includes(modalMode) &&
                !captchaToken) ||
              (modalMode === MODAL_MODES.newBank && isValidBankForm)
            }
            onClick={handleModalFooterButton}
            variant={ButtonVariants.primary}
          >
            {
              /* istanbul ignore next */ isNetworkRequestProcessing ? (
                <div className="circular loader"></div>
              ) : (
                STRINGS.labels.done
              )
            }
          </StyledButton>
        ) : null
      }
      header={
        <ModalHeader
          className={modalHeaderClassName}
          endButton={
            modalMode === MODAL_MODES.select ? (
              <button
                data-testid="add-payment-method-button"
                onClick={handleAddPaymentMethodClick}
              >
                {paymentMethodStrings.add.labels.add}
              </button>
            ) : null
          }
          onCloseClick={handleClose}
          title={paymentMethodStrings[modalMode].title}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
