/**
 * @module Address
 */

/**
 * Convenience function to format the provided address data.
 *
 * @param {object} params - The function params object.
 * @param {string} [params.city] - The address city (Example: 'Edmond').
 * @param {string} [params.state] - The address state (Example: 'OK').
 * @param {string} [params.street1] - The address street1 (Example: '4600 E. 2nd St.').
 * @param {string} [params.street2] - The address street2 (Example: 'Suite 1996').
 * @param {string} [params.zipCode] - The address zip/postal code (Example: '73034').
 *
 * @returns {string} The formatted address.
 */
export function formatAddress({ city, state, street1, street2, zipCode } = {}) {
  if (!city && !state && !street1 && !street2 && !zipCode) {
    return '';
  }
  let formattedString = '';
  if (street1 && typeof street1 === 'string') {
    formattedString = street1;
  }
  if (street2 && typeof street2 === 'string') {
    if (formattedString) {
      formattedString = `${formattedString}, ${street2}`;
    } else {
      formattedString = street2;
    }
  }
  if (city && typeof city === 'string') {
    if (formattedString) {
      formattedString = `${formattedString} ${city}`;
    } else {
      formattedString = city;
    }
  }
  if (state && typeof state === 'string') {
    if (formattedString) {
      formattedString = `${formattedString}, ${state}`;
    } else {
      formattedString = state;
    }
  }
  if (zipCode && typeof zipCode === 'string') {
    if (formattedString) {
      formattedString = `${formattedString} ${zipCode}`;
    } else {
      formattedString = zipCode;
    }
  }
  return formattedString;
}
