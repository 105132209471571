/**
 * @module ModalHeader
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import '../Modal.scss';

/**
 * Represents the base modal header element.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to modal header container.
 * @param {*} [props.endButton] - Optional button that will be placed on the right side (end) of the component.
 * @param {Function} [props.onCloseClick] - Handler function for modal close button click event.
 * @param {string} [props.title] - Modal title value.
 *
 * @returns {React.ReactElement} The ModalHeader component.
 */
export function ModalHeader({ className, endButton, onCloseClick, title }) {
  return (
    <div className={className}>
      <button className="btn-modal-close" onClick={onCloseClick}>
        <i className="icon"></i>
      </button>
      {endButton ? <div className="btn-modal-right">{endButton}</div> : null}
      <h2 className="modal-title">{title}</h2>
    </div>
  );
}
