/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module BaseModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import '../Modal.scss';

/**
 * Represents a basic modal component element with customizable header, content, and footer sections.
 *
 * @param {object} props - The component Props object.
 * @param {string} [props.className] - The class name to apply to the main reveal (parent) element.
 * @param {React.ReactElement} props.content - The content for the modal (required).
 * @param {React.ReactElement} [props.contentClassName] - Optional class name to apply to the content area (will be added to default `.modal-content` class)..
 * @param {React.ReactElement} [props.footer] - Optional footer content for the modal.
 * @param {React.ReactElement} [props.header] - Optional header content for the modal.
 * @param {string} [props.id] - Optional id attribute to apply to the main reveal (parent) element.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {Function} props.onClose - Handler function for modal close event.
 *
 * @returns {React.ReactElement} The BaseModal component.
 */
export function BaseModal({
  className,
  content,
  contentClassName,
  footer,
  header,
  id,
  isOpen,
  onClose,
}) {
  let clickTarget = null;

  /**
   * Handler function for mouse down event.
   *
   * @param {Event} event - The Event object associated with the mouse event.
   */
  function handleMouseDown(event) {
    if (event.target.matches('.reveal')) {
      clickTarget = event.target;
    }
  }

  /**
   * Handler function for mouse up event.
   *
   * @param {Event} event - The Event object associated with the mouse event.
   */
  function handleMouseUp(event) {
    if (event.target.matches('.reveal') && clickTarget === event.target) {
      clickTarget = null;
      onClose(event);
    }
  }

  return (
    <div
      className={`${['reveal full', className].join(' ')} ${
        isOpen ? 'open' : 'close'
      }`}
      data-testid="modal-dialog"
      id={id}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className="modal">
        {header ? <div className="modal-header">{header}</div> : null}
        <div className={['modal-content', contentClassName || ''].join(' ')}>
          {content}
        </div>
        {footer ? <div className="modal-footer">{footer}</div> : null}
      </div>
    </div>
  );
}
