/**
 * @module RecurringPaymentIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents an icon for recurring payments.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#666667').
 *
 * @returns {React.ReactElement} The RecurringPaymentIcon element.
 */
export default function RecurringPaymentIcon({ color = '#666667' }) {
  return (
    <div
      className="icon"
      data-color={color}
      data-testid="recurring-payment-icon"
    >
      <svg
        fill="none"
        height="30"
        viewBox="0 0 32 30"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.38161 29H26.6184C30.1096 29 32 27.1841 32 23.8598V6.14017C32 2.8159 30.1096 1 26.6184 1H5.38161C1.90567 1 0 2.8159 0 6.14017V23.8598C0 27.1841 1.90567 29 5.38161 29ZM5.67127 25.2218C4.52787 25.2218 3.9333 24.6946 3.9333 23.5377V10.5188C3.9333 9.36192 4.52787 8.83473 5.67127 8.83473H26.3287C27.4569 8.83473 28.0667 9.36192 28.0667 10.5188V23.5377C28.0667 24.6946 27.4569 25.2218 26.3287 25.2218H5.67127Z"
          fill={color}
          stroke="white"
        />
        <path
          d="M18.1349 12.3853H8.00195C7.8915 12.3853 7.80195 12.4749 7.80195 12.5853V16.4474C7.80195 16.5579 7.8915 16.6474 8.00195 16.6474H9.33494C9.4454 16.6474 9.53494 16.5579 9.53494 16.4474V14.0727H18.1349V15.8037C18.1349 15.884 18.1828 15.9565 18.2567 15.9878C18.3306 16.0192 18.4161 16.0033 18.4738 15.9476L21.1398 13.3729C21.1788 13.3352 21.2008 13.2833 21.2008 13.229C21.2008 13.1748 21.1788 13.1228 21.1398 13.0851L18.4738 10.5104C18.4161 10.4547 18.3306 10.4388 18.2567 10.4702C18.1828 10.5016 18.1349 10.574 18.1349 10.6543V12.3853Z"
          fill={color}
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="0.4"
        />
        <path
          d="M12.8671 21.474L23 21.474C23.1105 21.474 23.2 21.3845 23.2 21.274L23.2 17.412C23.2 17.3015 23.1105 17.212 23 17.212L21.667 17.212C21.5566 17.212 21.467 17.3015 21.467 17.412L21.467 19.7867L12.8671 19.7867L12.8671 18.0557C12.8671 17.9754 12.8191 17.9029 12.7452 17.8716C12.6713 17.8402 12.5859 17.856 12.5281 17.9118L9.86217 20.4865C9.82315 20.5242 9.80111 20.5761 9.80111 20.6304C9.80111 20.6846 9.82315 20.7365 9.86217 20.7742L12.5281 23.3489C12.5859 23.4047 12.6713 23.4205 12.7452 23.3892C12.8191 23.3578 12.8671 23.2853 12.8671 23.2051L12.8671 21.474Z"
          fill={color}
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="0.4"
        />
      </svg>
    </div>
  );
}
