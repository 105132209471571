/**
 * @module validator
 */
import { STRINGS } from './strings';

/* istanbul ignore next */
const { errorMessages } = STRINGS.validator;

/* istanbul ignore next */
const coreAttrs = ['attributes', 'id', 'type'];
const userGivingDataAttrs = [
  'amount',
  'campus',
  'frequency',
  'fund',
  'paymentDate',
  'paymentMethod',
];

/* istanbul ignore next */
const attrs = {
  campus: coreAttrs,
  frequency: coreAttrs,
  fund: coreAttrs,
  paymentMethod: coreAttrs,
};

/* istanbul ignore next */
const defaultConfigs = {
  validateAmount: {
    checkMax: true,
    checkMin: true,
    max: STRINGS.inputs.amount.max,
    min: STRINGS.inputs.amount.min,
  },
  validateString: {
    checkMax: true,
    checkMin: true,
    max: 999999999,
    min: 1,
  },
};

/**
 * Function to validate the specified amount value.
 *
 * @param {string} value - The amount value.
 * @param {object} [options] - Configurable options for validation checks.
 * @param {boolean} [options.checkMax] - Boolean flag denoting whether or not to validate the provided amount is greater than the max value specified.
 * @param {boolean} [options.checkMin] - Boolean flag denoting whether or not to validate the provided amount is greater than the min value specified.
 * @param {number|string} [options.max] - Maximum value allowed.
 * @param {number|string} [options.min] - Minimum value allowed.
 *
 * @returns {boolean} Boolean flag denoting whether or not the specified value is valid.
 */
export function validateAmount(
  value,
  { checkMax, checkMin, max, min } = defaultConfigs.validateAmount,
) {
  if (!value || typeof value !== 'string' || !/^\d*(\.\d{0,2})?$/.test(value)) {
    return false;
  }
  if (
    checkMax &&
    parseInt(Math.round(parseFloat(value) * 100), 10) >
      parseInt(Math.round(parseFloat(max) * 100), 10)
  ) {
    return false;
  }
  return (
    !checkMin ||
    (checkMin &&
      parseInt(Math.round(parseFloat(value) * 100), 10) >=
        parseInt(Math.round(parseFloat(min) * 100), 10))
  );
}

/**
 * Function to validate the specified campus, frequency, fund, or payment method object.
 *
 * @param {Campus|Frequency|Fund|PaymentMethod} object - The data object.
 * @param {'campus'|'frequency'|'fund'|'paymentMethod'} type - The data type.
 *
 * @returns {boolean} Boolean flag denoting whether or not the specified object is valid.
 */
export function validateObject(object, type) {
  const objAttrs = attrs[type];
  if (
    !object ||
    typeof object !== 'object' ||
    !type ||
    typeof type !== 'string' ||
    !attrs[type]
  ) {
    return false;
  }

  let isValid = true;
  objAttrs.forEach((key) => {
    if (!Object.prototype.hasOwnProperty.call(object, key)) {
      isValid = false;
    }
  });
  return isValid;
}

/**
 * Function to validate the specified string value.
 *
 * @param {string} value - The string value.
 * @param {object} [options] - Configurable options for validation checks.
 * @param {boolean} [options.checkMax] - Boolean flag denoting whether or not to validate the provided string is greater than the max value specified.
 * @param {boolean} [options.checkMin] - Boolean flag denoting whether or not to validate the provided string is less than the min value specified.
 * @param {number|string} [options.max] - Maximum string length allowed.
 * @param {number|string} [options.min] - Minimum string length allowed.
 *
 * @returns {boolean} Boolean flag denoting whether or not the specified value is valid.
 */
export function validateString(
  value,
  { checkMax, checkMin, max, min } = defaultConfigs.validateAmount,
) {
  if (!value || typeof value !== 'string') {
    return false;
  }
  if (checkMax && value.length > parseInt(max, 10)) {
    return false;
  }
  return !checkMin || (checkMin && value.length >= parseInt(min, 10));
}

/**
 * Function to validate the specified payment date value.
 *
 * @param {number} value - The payment date, in Unix epoch time.
 *
 * @returns {boolean} Boolean flag denoting whether or not the specified payment date is valid.
 */
export function validatePaymentDate(value) {
  return (
    value && typeof value === 'number' && !Number.isNaN(parseInt(value, 10))
  );
}

/**
 * Function to validate the specified user form data in preparation for submission to the Giving API.
 *
 * @param {UserGivingData} formData - The user giving data object.
 *
 * @returns {Array<FormError>} Array of form error objects.
 */
export function validateGivingFormData(formData) {
  const errors = [];
  userGivingDataAttrs.forEach((attr) => {
    switch (attr) {
      case 'amount':
        if (!validateAmount(formData[attr])) {
          errors.push({ field: attr, message: errorMessages[attr] });
        }
        break;
      case 'paymentDate':
        if (!validatePaymentDate(formData[attr])) {
          errors.push({ field: attr, message: errorMessages[attr] });
        }
        break;
      default:
        if (!validateObject(formData[attr], attr)) {
          errors.push({ field: attr, message: errorMessages[attr] });
        }
        break;
    }
  });
  return errors.length ? errors : null;
}
