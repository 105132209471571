/**
 * @module useGiving
 */
import React from 'react';
import { GivingContext } from '../../context/GivingContext';

/**
 * React hook for accessing `GivingContext`. Must be used inside of a `GivingProvider` context.
 *
 * @throws {Error} - Throws an error if used outside of a `GivingProvider` context.
 *
 * @returns {React.Context} - The GivingContext values.
 *
 * @example
 * import useGiving from 'hooks/useGiving';
 *
 * function SampleComponent() {
 *   const { funds } = useGiving();
 *
 *   return (
 *     <FundsModal data={funds} />
 *   )
 * }
 */
function useGiving() {
  const context = React.useContext(GivingContext);
  if (context === undefined) {
    throw new Error('useGiving must be used within a GivingProvider');
  }
  return context;
}

export default useGiving;
