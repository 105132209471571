/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module LocationIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a LocationIcon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.fillColor] - The fill color to apply to the icon (Default: 'transparent').
 * @param {string} [props.strokeColor] - The stroke color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The Location Icon element.
 */
export default function LocationIcon(
  /* istanbul ignore next */
  { fillColor = 'transparent', strokeColor = '#000000' },
) {
  return (
    <div
      className="icon"
      data-fill-color={fillColor}
      data-stroke-color={strokeColor}
      data-testid="location-icon"
    >
      <svg
        fill="none"
        height="14"
        viewBox="0 0 14 14"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.665464 5.31296C-0.372392 5.78779 -0.155324 7.23943 1.03177 7.24622L6.07859 7.26657C6.18034 7.26657 6.21426 7.30727 6.21426 7.40902L6.22783 12.4219C6.22783 13.5887 7.68625 13.8532 8.20179 12.7272L13.3368 1.61602C13.8863 0.428931 12.9637 -0.35794 11.8309 0.164379L0.665464 5.31296Z"
          fill={fillColor}
        />
        <path
          d="M0.665464 5.31296C-0.372392 5.78779 -0.155324 7.23943 1.03177 7.24622L6.07859 7.26657C6.18034 7.26657 6.21426 7.30727 6.21426 7.40902L6.22783 12.4219C6.22783 13.5887 7.68625 13.8532 8.20179 12.7272L13.3368 1.61602C13.8863 0.428931 12.9637 -0.35794 11.8309 0.164379L0.665464 5.31296ZM2.27991 6.02521C2.22564 6.02521 2.20529 5.96416 2.26634 5.93024L11.8309 1.55497C11.9123 1.52105 11.9598 1.56854 11.9191 1.64994L7.53702 11.1874C7.50988 11.2552 7.44205 11.2416 7.44205 11.1806L7.46918 6.61536C7.46918 6.20158 7.27247 5.99808 6.84511 5.99808L2.27991 6.02521Z"
          fill={strokeColor}
        />
      </svg>
    </div>
  );
}
