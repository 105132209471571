/**
 * @module LeafIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Leaf icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#9F9FA0').
 *
 * @returns {React.ReactElement} The LeafIcon element.
 */
export default function LeafIcon({ color = '#9F9FA0', ...passThroughProps }) {
  return (
    <div
      className="svg-icon"
      data-color={color}
      data-testid={passThroughProps?.['data-testid'] || 'leaf-icon'}
    >
      <svg
        fill="none"
        height="24"
        style={{ ...passThroughProps?.style }}
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          height="20"
          id="mask0_4666_7104"
          maskUnits="userSpaceOnUse"
          width="20"
          x="2"
          y="2"
        >
          <rect
            fill="#D9D900"
            height="19.8379"
            width="19.8379"
            x="2.16211"
            y="2.16211"
          />
        </mask>
        <g mask="url(#mask0_4666_7104)">
          <path
            d="M12.0811 18.6937C11.3096 18.6937 10.5829 18.5731 9.90097 18.3321C9.21905 18.091 8.59567 17.75 8.03084 17.3092L6.87363 18.4457C6.72209 18.5973 6.52922 18.673 6.29502 18.673C6.06083 18.673 5.86796 18.5973 5.71642 18.4457C5.56488 18.2942 5.48911 18.1013 5.48911 17.8671C5.48911 17.6329 5.56488 17.44 5.71642 17.2885L6.85296 16.152C6.41212 15.5871 6.07116 14.9603 5.83007 14.2715C5.58899 13.5827 5.46844 12.8525 5.46844 12.0811C5.46844 10.235 6.10904 8.67142 7.39024 7.39022C8.67144 6.10902 10.235 5.46843 12.0811 5.46843H18.6937V12.0811C18.6937 13.9271 18.0531 15.4907 16.7719 16.7719C15.4907 18.0531 13.9271 18.6937 12.0811 18.6937ZM12.0811 17.0405C13.4587 17.0405 14.6297 16.5584 15.594 15.594C16.5584 14.6297 17.0405 13.4587 17.0405 12.0811V7.12158H12.0811C10.7034 7.12158 9.53246 7.60375 8.56812 8.5681C7.60377 9.53244 7.1216 10.7034 7.1216 12.0811C7.1216 12.6183 7.20426 13.1315 7.36958 13.6206C7.53489 14.1096 7.7622 14.5539 8.0515 14.9534L12.329 10.6759C12.4806 10.5243 12.6735 10.4486 12.9077 10.4486C13.1419 10.4486 13.3347 10.5243 13.4863 10.6759C13.6516 10.8412 13.7342 11.0375 13.7342 11.2648C13.7342 11.4921 13.6516 11.6884 13.4863 11.8537L9.20871 16.1313C9.60823 16.4206 10.0525 16.6445 10.5416 16.8029C11.0306 16.9613 11.5438 17.0405 12.0811 17.0405Z"
            fill={color}
          />
        </g>
      </svg>
    </div>
  );
}
