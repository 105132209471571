/**
 * @module DownloadIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a Download icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#666667').
 *
 * @returns {React.ReactElement} The DownloadIcon element.
 */
export default function LeafIcon({ color = '#666667', ...passThroughProps }) {
  return (
    <div
      className="svg-icon"
      data-color={color}
      data-testid={passThroughProps?.['data-testid'] || 'download-icon'}
    >
      <svg
        fill="none"
        height="24"
        style={{ ...passThroughProps?.style }}
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.2222 12V18.2222H5.77778V12H4V18.2222C4 19.2 4.8 20 5.77778 20H18.2222C19.2 20 20 19.2 20 18.2222V12H18.2222ZM12.8889 12.5956L15.1911 10.3022L16.4444 11.5556L12 16L7.55556 11.5556L8.80889 10.3022L11.1111 12.5956V4H12.8889V12.5956Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
