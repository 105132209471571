/**
 * @module currency
 */
export const SUPPORTED_CHARACTERS = {
  amount: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'],
};

/**
 * Convenience function to cleanse the specified value of unsupported characters.
 *
 * @param {number|string} value - The value to cleanse.
 *
 * @returns {string} The cleansed string value, stripped of non-supported characters.
 *
 * @see {@link https://regexr.com/3eqpa}.
 */
export function cleanseInput(value) {
  // Split value into individual characters and iterate to remove unsupported.
  const valueAsArray = value.split('');
  const newValueAsArray = [];
  valueAsArray.forEach((char) => {
    if (SUPPORTED_CHARACTERS.amount.includes(char)) {
      if ((char === '.' && newValueAsArray.indexOf('.') < 0) || char !== '.') {
        newValueAsArray.push(char);
      }
    }
  });

  // Calculate dollars and cents, updating value to only show 2 decimals.
  let finalValue = newValueAsArray.join('');
  const dollarsCents = finalValue.split('.');
  if (dollarsCents[1] && dollarsCents[1].length > 2) {
    dollarsCents[1] = dollarsCents[1].substring(0, 2);
    finalValue = `${dollarsCents[0]}.${dollarsCents[1]}`;
  }
  return finalValue;
}

/**
 * Format the specified number as localized currency.
 *
 * @param {object} params - Function params object.
 * @param {string} [params.currency] - Currency code (e.g. 'USD', 'EUR').
 * @param {string} [params.locale] - Locale key (e.g. 'en', 'fr', 'it').
 * @param {string} [params.number] - String value of the number to format.
 *
 * @example
 * // Returns { display: '€123.45', number: '123.45', symbol: '€', symbol_placement: 'before' }
 * formatNumberAsCurrency({ currency: 'EUR', locale: 'en', number: '123.45' })
 *
 * @example
 * // Returns { display: '123,45 €', number: '123,45', symbol: '€', symbol_placement: 'after' }
 * formatNumberAsCurrency({ currency: 'EUR', locale: 'fr', number: '123.45' })
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat}.
 *
 * @returns {CurrencyFormat} The CurrencyFormat object.
 */
export function formatNumberAsCurrency({
  currency = 'USD',
  locale = 'en',
  number = '0',
} = {}) {
  const numberFormat = new Intl.NumberFormat([locale, 'en'], {
    currency,
    style: 'currency',
  }).format(number);
  // eslint-disable-next-line no-restricted-globals
  const isSymbolBefore = isNaN(numberFormat.charAt(0));

  return {
    display: numberFormat,
    number: isSymbolBefore
      ? String(numberFormat).slice(1).trim()
      : String(numberFormat).slice(0, -1).trim(),
    symbol_placement: isSymbolBefore ? 'before' : 'after',
  };
}

/**
 * Convenience function format the specified string with commas.
 *
 * @param {string} value - The value to which to apply commas.
 *
 * @returns {string} The supplied string value formatted with commas.
 */
export function formatWithCommas(value) {
  if (!value) {
    return value;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
