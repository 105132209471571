/**
 * @module Helpers
 */

/**
 * Convenience function to validate if the provided string is a valid http(s) URL.
 *
 * @param {string} urlString - The URL string to validate.
 *
 * @returns {boolean} Boolean flag denoting whether or not the provided value is a valid URL.
 */
export function isValidHttpUrl(urlString) {
  if (!urlString) {
    return false;
  }
  let url;
  try {
    url = new URL(urlString);
  } catch (error) {
    return false;
  }
  return ['http:', 'https:'].includes(url.protocol);
}

/**
 * Convert params object to JSON encoded string.
 *
 * @param {object} params - A params object.
 *
 * @throws {Error} - Throws an error if `params` is not an object.
 *
 * @returns {string} - The JSON encoded string.
 */
export function paramsToJSON(params) {
  if (!params) return '';
  if (typeof params !== 'object') {
    throw new Error('Supplied `params` must be an object.');
  }
  return JSON.stringify(params);
}

/**
 * Convert params object to valid query string with support for bracket-less arrays.
 *
 * @param {object} params - A params object.
 *
 * @throws {Error} - Throws an error if `params` is not an object.
 *
 * @returns {string} - The query string.
 */
export function paramsToQuery(params) {
  if (typeof params !== 'object') {
    throw new Error('Supplied `params` must be an object.');
  }

  return Object.keys(params)
    .map((name) => {
      const value = params[name];

      if (typeof value === 'undefined') {
        return '';
      }

      if (Array.isArray(value)) {
        return value
          .map((arrayValue) => {
            return `${name}=${arrayValue}`;
          })
          .join('&');
      }

      if (['number', 'string'].indexOf(typeof value) === -1) {
        const type = value === null ? 'null' : typeof value;
        throw new Error(
          `Param value with the name ${name} was of type ${type} which is not allowed.`,
        );
      }

      return `${name}=${value}`;
    })
    .filter((value) => value?.length)
    .sort((a, b) => a < b)
    .join('&');
}
