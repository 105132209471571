/**
 * @module EmailAddressScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { ANALYTICS, STRINGS } from '../../../../../utils';
import '../../PaperlessPreference.scss';

const { 'email-address': emailStrings } = STRINGS.modals.paperlessPreference;
const FORM_FIELD_IDS = {
  email: 'email-address-form-email',
};
const emailRegEx = /^[\w.+-]+@[\w.-]+\.[a-zA-Z]{2,}$/;

/**
 * Represents a screen for email address input and update.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {object} [props.formData] - Data object of form data values. Attributes align with form input field names.
 * @param {Function} [props.onFormChange] - Handler function for form change event trigger. The form data object is sent as function argument.
 *
 * @returns {React.ReactElement} The EmailAddressScreen component.
 */
export function EmailAddressScreen({
  className,
  formData,
  onFormChange,
  ...passThroughProps
}) {
  /**
   * Handler function for email input field change.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  function handleEmailChange(event) {
    onFormChange({
      attributes: {
        ...formData?.attributes,
        email: /* istanbul ignore next */ event?.target?.value,
      },
      errors: {
        ...formData?.errors,
        email:
          /* istanbul ignore next */ !event?.target?.value ||
          !emailRegEx.test(event?.target?.value) ||
          !event?.target?.value.includes('@'),
      },
    });
  }

  /**
   * Handler function for email input field blur.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  function handleEmailBlur(event) {
    callSegmentTrack({
      event: ANALYTICS.events.emailAddressUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.emailAddress,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.emailAddress,
        logged_in: !!passThroughProps?.user,
        preferred_campus: passThroughProps?.preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.emailAddress,
        title: document?.title,
        url: window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
        value: /* istanbul ignore next */ event?.target?.value,
      },
    });
  }

  /**
   * Handler function for email input field focus.
   *
   * @param {Event} event - The Event object associated with the change event.
   */
  function handleEmailFocus(event) {
    callSegmentTrack({
      event: ANALYTICS.events.emailAddressUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.emailAddress,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.emailAddress,
        logged_in: !!passThroughProps?.user,
        preferred_campus: passThroughProps?.preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.emailAddress,
        title: document?.title,
        url: window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
        value: /* istanbul ignore next */ event?.target?.value,
      },
    });
  }

  return (
    <div
      className={`email-address-screen ${className || ''}`.trim()}
      data-testid="email-address-screen"
    >
      <div className="screen-content">
        <div className="list">
          <div className="content-block form-container list-container vertical">
            <div className="form-field-control">
              <label htmlFor={FORM_FIELD_IDS.email}>
                {emailStrings.labels.email}
              </label>
              <input
                className={formData?.errors?.email ? 'error' : ''}
                data-testid={FORM_FIELD_IDS.email}
                id={FORM_FIELD_IDS.email}
                name={FORM_FIELD_IDS.email}
                onBlur={handleEmailBlur}
                onChange={handleEmailChange}
                onFocus={handleEmailFocus}
                placeholder={emailStrings.placeholders.email}
                type="text"
                value={formData?.attributes?.email || ''}
              />
              {formData?.errors?.email ? (
                <p className="input-disclaimer error mb-0 mt-4">
                  {emailStrings.errors.completeRequiredField}
                </p>
              ) : null}
              <p
                className={`input-disclaimer mb-0 mt-${
                  formData?.errors?.email ? '0' : '4'
                }`}
              >
                {emailStrings.disclaimers.emailCommunication}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
