/**
 * @module ManagePaymentMethodModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import {
  callSegmentPage,
  callSegmentTrack,
} from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import {
  deleteScheduledGift,
  updatePaymentMethod,
  updateScheduledGift,
} from '../../../api/giving';
import { EditPaymentMethodScreen } from './screens/EditPaymentMethodScreen';
import { PaymentMethodDetailScreen } from './screens/PaymentMethodDetailScreen';
import { PaymentMethodListScreen } from './screens/PaymentMethodListScreen';
import { ManageGift } from '../ManageGivingModal/Screens/ManageGift';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { Loading } from '../../../views/Loading';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import {
  ANALYTICS,
  API_PAYMENT_METHOD_TYPES,
  HTTP_METHODS,
  ICON_OVERRIDES,
  MODAL_MODES,
  SMART_PAY_PROVIDERS,
  STRINGS,
  TYPES,
  calculateDaysOffset,
  logError,
} from '../../../utils';
import '../Modal.scss';
import './ManagePaymentMethod.scss';

/**
 * Represents a view for Manage Payment Method.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.frequencyLabel] - Label value for the Frequency list item.
 * @param {string} [props.iconOverride] - Optional icon override to use in place of default "x" close icon.
 * @param {boolean} [props.isLocationCurrentLocation] - Boolean flag denoting whether or not the current location is the selected location.
 * @param {boolean} [props.isOpen] - Boolean flag denoting the visibility of the modal.
 * @param {('detail'|'edit'|'manage'|'select')} [props.mode] - The mode (state) of the modal (Default: 'select').
 *
 * @returns {React.ReactElement} The ManagePaymentMethodModal component.
 */
export function ManagePaymentMethodModal({
  frequencyLabel,
  iconOverride,
  isLocationCurrentLocation,
  isOpen,
  mode,
  ...passThroughProps
}) {
  const { getAccessToken, user } = useAuth();
  const {
    fetchGivingData,
    fetchScheduledGifts,
    getScheduledGiftsForPaymentMethod,
    paymentMethods,
    preferredCampus,
    scheduledGiftData,
    smartPayProviderData,
    storeScheduledGiftData,
    storeUserGivingData,
    today,
    userGivingData,
  } = useGiving();
  const {
    handleModalClose,
    handleModalVisibility,
    modals,
    storeConfirmationDialogData,
  } = useModals();
  const { managePaymentMethod: managePaymentMethodStrings } = STRINGS.modals;
  const { manageGift: manageGiftStrings } = STRINGS.modals;
  const screen1ContentRef = React.useRef(null);

  const [modalMode, setModalMode] = React.useState(MODAL_MODES.select);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState(
    passThroughProps?.selectedPaymentMethod || null,
  );
  const [
    scheduledGiftsForSelectedPaymentMethod,
    setScheduledGiftsForSelectedPaymentMethod,
  ] = React.useState([]);
  const [editPaymentMethodFormData, setEditPaymentMethodFormData] =
    React.useState(passThroughProps?.editPaymentMethodFormData || null);
  const [isEditPaymentMethodFormUpdated, setIsEditPaymentMethodFormUpdated] =
    React.useState(false);
  const [editManageGiftFormHasErrors, setEditManageGiftFormHasErrors] =
    React.useState(false);
  const [editPaymentMethodFormHasErrors, setEditPaymentMethodFormHasErrors] =
    React.useState(false);
  const [networkRequestStatus, setNetworkRequestStatus] = React.useState({
    method: null,
    processing: false,
  });
  const [scrollPosition, setScrollPosition] = React.useState({
    screen1: 0,
  });

  /* istanbul ignore next */
  const comparitor = passThroughProps?.testOverride ? mode : modalMode;

  /**
   * Ensure specified mode is one supported by the modal.
   */
  React.useEffect(() => {
    if (Object.values(MODAL_MODES).includes(mode)) {
      setModalMode(mode);
    } else {
      setModalMode(MODAL_MODES.select);
    }
  }, [mode]);

  /**
   * Handler function to trigger call to Giving API endpoint for deleting scheduled gift.
   *
   * Note: Ignore directive added since delete button trigger and functionality covered in individual modal screen test.
   */
  /* istanbul ignore next */
  async function callApiDeleteScheduledGift() {
    setNetworkRequestStatus({
      method: HTTP_METHODS.del,
      processing: true,
    });

    try {
      await deleteScheduledGift({
        accessToken: getAccessToken(),
        giftId: scheduledGiftData?.id,
      });

      callSegmentTrack({
        event: ANALYTICS.events.deleteScheduledGift,
        properties: {
          action: ANALYTICS.actions.deleted,
          component: ANALYTICS.screens.names.managePaymentMethod,
          component_url: null,
          context: ANALYTICS.contexts.oneScreen,
          label: ANALYTICS.labels.delete,
          logged_in: !!user,
          preferred_campus: preferredCampus?.attributes?.code,
          referrer: document?.referrer,
          title: document?.title,
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });

      await fetchScheduledGifts();
      const upcomingGifts = await getScheduledGiftsForPaymentMethod(
        scheduledGiftData?.attributes?.payment_method?.id,
      );
      setScheduledGiftsForSelectedPaymentMethod(upcomingGifts);
      setNetworkRequestStatus({
        method: null,
        processing: false,
      });
      setModalMode(MODAL_MODES.detail);
    } catch (error) {
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(new Error(manageGiftStrings.deleteScheduledGiftError), {
        browserConsole: false,
        bugsnag: false,
        windowAlert: true,
      });

      await fetchScheduledGifts();
      setNetworkRequestStatus({
        method: null,
        processing: false,
      });
    }
  }

  /**
   * Handler function to trigger call to Giving API endpoint for updating scheduled gift.
   *
   * Note: Ignore directive added since save (update) button trigger and functionality covered in individual modal screen test.
   */
  /* istanbul ignore next */
  async function callApiUpdateScheduledGift() {
    setNetworkRequestStatus({
      method: HTTP_METHODS.patch,
      processing: true,
    });

    try {
      const updateScheduledGiftResponse = await updateScheduledGift({
        accessToken: getAccessToken(),
        amount: scheduledGiftData?.attributes?.amount,
        campus: scheduledGiftData?.attributes?.campus,
        frequency: scheduledGiftData?.attributes?.frequency,
        fund: scheduledGiftData?.attributes?.fund,
        giftId: scheduledGiftData?.id,
        nextPaymentDate: scheduledGiftData?.attributes?.next_payment_date,
        paymentMethod: scheduledGiftData?.attributes?.payment_method?.id,
      });

      if (updateScheduledGiftResponse?.errors) {
        const [responseError] = updateScheduledGiftResponse.errors;
        logError(new Error(responseError.detail));
        setNetworkRequestStatus({
          method: null,
          processing: false,
        });

        /**
         * Calling logError() again, but with false values for bugsnag and
         * browserConsole so it only displays for user.
         */
        logError(
          new Error(managePaymentMethodStrings.updateScheduledGiftError),
          {
            browserConsole: false,
            bugsnag: false,
            windowAlert: true,
          },
        );
      } else {
        const donationDate = new Date(
          scheduledGiftData.attributes.next_payment_date * 1000,
        );
        const donationDateOffset = calculateDaysOffset({
          endDate: donationDate,
          startDate: today,
        });
        const userPaymentMethods = [
          ...new Set(
            Array.from(
              paymentMethods,
              (paymentMethod) => paymentMethod.attributes.payment_method_type,
            ),
          ),
        ];
        if (smartPayProviderData?.applePay) {
          userPaymentMethods.push(
            SMART_PAY_PROVIDERS.apple_pay.attributes.display_label,
          );
        }
        if (smartPayProviderData?.googlePay) {
          userPaymentMethods.push(
            SMART_PAY_PROVIDERS.google_pay.attributes.display_label,
          );
        }

        callSegmentTrack({
          event: ANALYTICS.events.givingScheduledUpdated,
          properties: {
            action: ANALYTICS.actions.updated,

            amount: scheduledGiftData?.attributes?.amount,
            campus: scheduledGiftData?.attributes?.campus,
            component: ANALYTICS.screens.names.managePaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            frequency: scheduledGiftData?.attributes?.frequency,
            fund: scheduledGiftData?.attributes?.fund,
            label: ANALYTICS.labels.save,
            logged_in: !!user,
            payment_method:
              scheduledGiftData?.attributes?.payment_method
                ?.payment_method_type,
            payment_method_id:
              scheduledGiftData?.attributes?.payment_method?.id,
            payment_methods: userPaymentMethods,
            person_alias_id: user?.[TYPES.user.lcRockPersonAliasId],
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            schedule_start_date: donationDate.toISOString().split('T')[0], // Outputs to YYYY-MM-DD format.
            schedule_start_date_offset: donationDateOffset,
            scheduled_gift_id: scheduledGiftData?.id,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });

        await fetchScheduledGifts();
        const upcomingGifts = await getScheduledGiftsForPaymentMethod(
          scheduledGiftData?.attributes?.payment_method?.id,
        );
        setScheduledGiftsForSelectedPaymentMethod(upcomingGifts);
        setNetworkRequestStatus({
          method: null,
          processing: false,
        });
        setModalMode(MODAL_MODES.detail);
      }
    } catch (error) {
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(new Error(manageGiftStrings.updateScheduledGiftError), {
        browserConsole: false,
        bugsnag: false,
        windowAlert: true,
      });

      await fetchScheduledGifts();
      setNetworkRequestStatus({
        method: null,
        processing: false,
      });
    }
  }

  /**
   * Handler function to trigger call to Giving API endpoint for updating payment method.
   *
   * Note: Ignore directive added since save (update) button trigger and functionality covered in individual modal screen test.
   *
   * @param {object} paymentMethodFormData - Data object of Edit Payment Method form data.
   */
  /* istanbul ignore next */
  async function callApiUpdatePaymentMethod(paymentMethodFormData) {
    setNetworkRequestStatus({
      method: HTTP_METHODS.patch,
      processing: true,
    });

    try {
      const isCreditCard =
        selectedPaymentMethod?.attributes?.payment_method_type ===
        'Credit Card';
      const updatePaymentMethodResponse = await updatePaymentMethod({
        accessToken: getAccessToken(),
        expiration: paymentMethodFormData?.attributes?.expiration_label,
        name: paymentMethodFormData?.attributes?.name,
        paymentMethodId: selectedPaymentMethod?.id,
        type: API_PAYMENT_METHOD_TYPES.update[
          isCreditCard
            ? API_PAYMENT_METHOD_TYPES.update.card
            : API_PAYMENT_METHOD_TYPES.update.bank
        ],
      });
      if (updatePaymentMethodResponse?.errors) {
        const [responseError] = updatePaymentMethodResponse.errors;
        logError(new Error(responseError.detail));
        setNetworkRequestStatus({
          method: null,
          processing: false,
        });

        /**
         * Calling logError() again, but with false values for bugsnag and
         * browserConsole so it only displays for user.
         */
        logError(
          new Error(managePaymentMethodStrings.updatePaymentMethodError),
          {
            browserConsole: false,
            bugsnag: false,
            windowAlert: true,
          },
        );
      } else {
        callSegmentTrack({
          event: ANALYTICS.events.paymentMethodUpdated,
          properties: {
            action: ANALYTICS.actions.updated,
            component: ANALYTICS.screens.names.managePaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.save,
            logged_in: !!user,
            payment_method_detail: paymentMethodFormData?.attributes?.name,
            payment_method_expiration_date:
              paymentMethodFormData?.attributes?.expiration_label,
            payment_method_id: selectedPaymentMethod?.id,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });

        // Re-fetch user giving data and set back to select mode.
        fetchGivingData({
          callback: async () => {
            const updatedPaymentMethodData = updatePaymentMethodResponse?.data;
            const updatedPaymentMethodDataId = parseInt(
              updatedPaymentMethodData?.id,
              10,
            );
            const userGivingDataPaymentMethodData =
              userGivingData?.paymentMethod;
            const userGivingDataPaymentMethodDataId = parseInt(
              userGivingDataPaymentMethodData?.id,
              10,
            );
            if (
              updatedPaymentMethodDataId === userGivingDataPaymentMethodDataId
            ) {
              storeUserGivingData({
                paymentMethod: updatedPaymentMethodData,
              });
            }
            setSelectedPaymentMethod(updatePaymentMethodResponse?.data);
            setEditPaymentMethodFormData({
              attributes: {
                expiration_label:
                  updatePaymentMethodResponse?.data?.attributes
                    ?.expiration_label,
                name: updatePaymentMethodResponse?.data?.attributes?.name,
              },
              errors: {
                expirationDate: false,
                name: false,
              },
            });
            const upcomingGifts = await getScheduledGiftsForPaymentMethod(
              updatePaymentMethodResponse?.data?.id,
            );
            setScheduledGiftsForSelectedPaymentMethod(upcomingGifts);
            setNetworkRequestStatus({
              method: null,
              processing: false,
            });
            setModalMode(MODAL_MODES.detail);
          },
        });
      }
    } catch (error) {
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(new Error(managePaymentMethodStrings.updatePaymentMethodError), {
        browserConsole: false,
        bugsnag: false,
        windowAlert: true,
      });

      // Re-fetch user giving data and set back to select mode.
      fetchGivingData({
        callback: () => {
          setNetworkRequestStatus({
            method: null,
            processing: false,
          });
          setModalMode(MODAL_MODES.detail);
        },
      });
    }
  }

  /**
   * Handler function for click event of Add payment method.
   */
  function handleAddPaymentMethodClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.managePaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.addNewPaymentMethod,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.managePaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      iconOverride: ICON_OVERRIDES.back,
      isOpen: true,
      modalId: modals.paymentMethod.id,
      modalProps: {
        mode: MODAL_MODES.add,
      },
    });
  }

  /**
   * Handler function for payment method list item click event.
   *
   * Note: callSegmentTrack() is triggered from within the sub-screen component
   * and does not need to be invoked here. Also, ignore directive added since
   * list item click is covered in the list screen component test.
   *
   * @param {object} params - The function params object.
   * @param {PaymentMethod} params.paymentMethod - The payment method data object.
   */
  /* istanbul ignore next */
  async function handlePaymentMethodListItemClick({ paymentMethod }) {
    setNetworkRequestStatus({
      method: HTTP_METHODS.get,
      processing: true,
    });

    setSelectedPaymentMethod(paymentMethod);
    setEditPaymentMethodFormData({
      attributes: {
        expiration_label: paymentMethod?.attributes?.expiration_label,
        name: paymentMethod?.attributes?.name,
      },
      errors: {
        expirationDate: false,
        name: false,
      },
    });
    const upcomingGifts = await getScheduledGiftsForPaymentMethod(
      paymentMethod?.id,
    );
    setScheduledGiftsForSelectedPaymentMethod(upcomingGifts);
    setNetworkRequestStatus({
      method: null,
      processing: false,
    });

    setModalMode(MODAL_MODES.detail);

    const activeScrollTop = screen1ContentRef?.current?.scrollTop;
    setScrollPosition({
      screen1: activeScrollTop,
    });
  }

  /**
   * Handler function for screen 1 content scroll event, which sets the scroll
   * position for the screen to be stored for ensuring accurate placement kept.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main handler function exists.
   */
  /* istanbul ignore next */
  function handleScreen1ContentScroll() {
    const activeScrollTop = screen1ContentRef?.current?.scrollTop;
    setScrollPosition({
      screen1: activeScrollTop,
    });
  }

  /**
   * Handler function for payment method list Add Payment Method button click event.
   *
   * Note: callSegmentTrack() is triggered from within the sub-screen component
   * and does not need to be invoked here.
   */
  /* istanbul ignore next */
  function handlePaymentMethodListAddPaymentMethodClick() {
    handleModalVisibility({
      iconOverride: ICON_OVERRIDES.back,
      isOpen: true,
      modalId: modals.paymentMethod.id,
    });
  }

  /**
   * Handler function for payment method list item edit click event.
   *
   * Note: callSegmentTrack() is triggered from within the sub-screen component
   * and does not need to be invoked here. Also, ignore directive added since
   * list item click is covered in the list screen component test.
   *
   * @param {object} params - The function params object.
   * @param {PaymentMethod} params.paymentMethod - The payment method data object.
   */
  /* istanbul ignore next */
  function handlePaymentMethodListItemEditClick({ paymentMethod }) {
    setSelectedPaymentMethod(paymentMethod);
    setEditPaymentMethodFormData({
      attributes: {
        expiration_label: paymentMethod?.attributes?.expiration_label,
        name: paymentMethod?.attributes?.name,
      },
      errors: {
        expirationDate: false,
        name: false,
      },
    });
    setModalMode(MODAL_MODES.edit);
  }

  /**
   * Handler function for payment method detail screen navigate back trigger.
   *
   * Note: callSegmentTrack() is triggered from within the sub-screen component
   * and does nto need to be invoked here. Also, ignore directive added since
   * handler prop function is covered in the child component test.
   */
  /* istanbul ignore next */
  function handlePaymentMethodDetailNavigateBack() {
    setModalMode(MODAL_MODES.select);
  }

  /**
   * Handler function for upcoming gift entry list item click event.
   *
   * Note: Ignore directive added since handler prop function is covered in the
   * child component test.
   *
   * @param {object} params - The function params object.
   * @param {Subscription} params.giftData - The Subscription data object for the selected scheduled gift.
   */
  /* istanbul ignore next */
  function handleUpcomingGiftEntryListItemClick({ giftData }) {
    storeScheduledGiftData(giftData);
    setModalMode(MODAL_MODES.manage);
  }

  /**
   * Handler function for close button click.
   *
   * Note: Ignore directive added only because passThroughProps is only ever
   * used in tests and will always be truthy for test coverage.
   */
  function handleClose() {
    /* istanbul ignore next */
    if (networkRequestStatus.processing) {
      return;
    }

    switch (/* istanbul ignore next */ comparitor) {
      case MODAL_MODES.detail:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.paymentMethodDetail,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.back,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.paymentMethodDetail,
            title: document?.title,
            url: /* istanbul ignore next */ window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        // Timeout for animation duration of navigating back to previous mode.
        /* istanbul ignore next */
        setTimeout(() => {
          setScheduledGiftsForSelectedPaymentMethod([]);
          setSelectedPaymentMethod(null);
          setEditPaymentMethodFormData({
            attributes: {
              expiration_label: '',
              name: '',
            },
            errors: {
              expirationDate: false,
              name: false,
            },
          });
        }, 350);
        setModalMode(MODAL_MODES.select);
        break;
      case MODAL_MODES.edit:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.editPaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.back,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.editPaymentMethod,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });

        /* istanbul ignore next */
        if (isEditPaymentMethodFormUpdated) {
          storeConfirmationDialogData({
            cancelLabel:
              managePaymentMethodStrings.dialogs.editPaymentMethodUnsavedChanges
                .cancelLabel,
            confirmLabel:
              managePaymentMethodStrings.dialogs.editPaymentMethodUnsavedChanges
                .confirmLabel,
            icon: null,
            isOpen: true,
            message:
              managePaymentMethodStrings.dialogs.editPaymentMethodUnsavedChanges
                .message,
            onCancelClick: () => {
              storeConfirmationDialogData({ isOpen: false });
              callSegmentTrack({
                event: ANALYTICS.events.buttonAction,
                properties: {
                  action: ANALYTICS.actions.clicked,
                  component: ANALYTICS.screens.names.editPaymentMethodModal,
                  component_url: null,
                  context: ANALYTICS.contexts.oneScreen,
                  label:
                    managePaymentMethodStrings.dialogs
                      .editPaymentMethodUnsavedChanges.cancelLabel,
                  logged_in: !!user,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer,
                  screen: ANALYTICS.screens.names.editPaymentMethodModal,
                  title: document?.title,
                  url: window?.location?.href,
                  user_id:
                    user?.['https://www.life.church/rock_person_alias_id'],
                },
              });
              // Timeout to match transition time of dialog hiding.
              setTimeout(() => {
                setModalMode(MODAL_MODES.detail);
              }, 300);
            },
            onClose: () => {
              storeConfirmationDialogData({
                isOpen: false,
              });
              callSegmentTrack({
                event: ANALYTICS.events.buttonAction,
                properties: {
                  action: ANALYTICS.actions.clicked,
                  component: ANALYTICS.screens.names.editPaymentMethodModal,
                  component_url: null,
                  context: ANALYTICS.contexts.oneScreen,
                  label: ANALYTICS.labels.close,
                  logged_in: !!user,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer,
                  screen: ANALYTICS.screens.names.editPaymentMethodModal,
                  title: document?.title,
                  url: window?.location?.href,
                  user_id:
                    user?.['https://www.life.church/rock_person_alias_id'],
                },
              });
            },
            onConfirmClick: () => {
              storeConfirmationDialogData({
                isOpen: false,
              });
              callSegmentTrack({
                event: ANALYTICS.events.buttonAction,
                properties: {
                  action: ANALYTICS.actions.clicked,
                  component: ANALYTICS.screens.names.editPaymentMethodModal,
                  component_url: null,
                  context: ANALYTICS.contexts.oneScreen,
                  label:
                    managePaymentMethodStrings.dialogs
                      .editPaymentMethodUnsavedChanges.confirmLabel,
                  logged_in: !!user,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer,
                  screen: ANALYTICS.screens.names.editPaymentMethodModal,
                  title: document?.title,
                  url: window?.location?.href,
                  user_id:
                    user?.['https://www.life.church/rock_person_alias_id'],
                },
              });
              callApiUpdatePaymentMethod(editPaymentMethodFormData);
            },
            title: null,
          });
        } else {
          setModalMode(MODAL_MODES.detail);
        }
        break;
      case MODAL_MODES.manage:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.managePaymentMethod,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.back,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.managePaymentMethod,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        setModalMode(MODAL_MODES.detail);
        break;
      default:
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.paymentMethodList,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.close,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.paymentMethodList,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        handleModalClose(modals.managePaymentMethod.id);
    }
  }

  /**
   * Handler function for help button click event.
   *
   * Note: Ignore directive added simply because at present, the Help button is
   * not present on payment method list screen, but that is set as the default
   * as a failsafe after checking all other cases. Also, adding where check for
   * passThroughProps?.testOverride exists since that is optional and only used
   * in tests and will always be truthy.
   */
  function handleHelpClick() {
    let segmentPropertiesScreen;

    switch (/* istanbul ignore next */ comparitor) {
      case MODAL_MODES.detail:
        segmentPropertiesScreen = ANALYTICS.screens.names.paymentMethodDetail;
        break;
      case MODAL_MODES.edit:
        segmentPropertiesScreen = ANALYTICS.screens.names.editPaymentMethod;
        break;
      case MODAL_MODES.manage:
        segmentPropertiesScreen = ANALYTICS.screens.names.managePaymentMethod;
        break;
      /* istanbul ignore next */
      default:
        segmentPropertiesScreen = ANALYTICS.screens.names.paymentMethodList;
        break;
    }
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: segmentPropertiesScreen,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.help,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: segmentPropertiesScreen,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.help.id,
    });
  }

  /**
   * Handler function for Delete gift button click.
   */
  /* istanbul ignore next */
  function handleDeleteGiftClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.delete,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    storeConfirmationDialogData({
      cancelLabel: manageGiftStrings.dialogs.deleteScheduledGift.cancelLabel,
      confirmLabel: manageGiftStrings.dialogs.deleteScheduledGift.confirmLabel,
      icon: null,
      isOpen: true,
      message: manageGiftStrings.dialogs.deleteScheduledGift.message,
      onCancelClick: () => {
        storeConfirmationDialogData({ isOpen: false });
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.manageGivingDeleteModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: manageGiftStrings.dialogs.deleteScheduledGift.cancelLabel,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.manageGivingDeleteModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
      },
      onClose: () => {
        storeConfirmationDialogData({
          isOpen: false,
        });
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.manageGivingDeleteModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.close,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.manageGivingDeleteModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
      },
      onConfirmClick: () => {
        storeConfirmationDialogData({
          isOpen: false,
        });
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.manageGivingDeleteModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: manageGiftStrings.dialogs.deleteScheduledGift.confirmLabel,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.manageGivingDeleteModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        callApiDeleteScheduledGift();
      },
      title: manageGiftStrings.dialogs.deleteScheduledGift.title,
    });
  }

  /**
   * Handler function for Save button click.
   */
  /* istanbul ignore next */
  function handleSaveGiftClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.save,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    callApiUpdateScheduledGift();
  }

  /**
   * Handler function for Save button click of payment method edit mode.
   */
  /* istanbul ignore next */
  function handlePaymentMethodSaveClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.editPaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.save,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.editPaymentMethod,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    callApiUpdatePaymentMethod(editPaymentMethodFormData);
  }

  /**
   * Handler function for the onFormChange prop for edit payment method screen, triggered from within the child component.
   *
   * Note: Ignore directive added since handler prop function is covered in the
   * child component test.
   *
   * @param {object} paymentMethodFormData - Data object of Edit Payment Method form data.
   */
  /* istanbul ignore next */
  function handleEditPaymentMethodFormChange(paymentMethodFormData) {
    let isUpdated = false;
    let hasErrors = false;
    if (selectedPaymentMethod && paymentMethodFormData) {
      Object.entries(paymentMethodFormData?.attributes).forEach(
        ([attribute, value]) => {
          if (value && selectedPaymentMethod.attributes[attribute]) {
            if (value !== selectedPaymentMethod.attributes[attribute]) {
              isUpdated = true;
            }
          }
        },
      );
      Object.values(paymentMethodFormData?.errors).forEach((value) => {
        if (value) {
          hasErrors = true;
        }
      });
      setEditPaymentMethodFormData(paymentMethodFormData);
      setIsEditPaymentMethodFormUpdated(isUpdated);
      setEditPaymentMethodFormHasErrors(hasErrors);
    }
  }

  /**
   * Handler function for Manage Gift form change events.
   *
   * Note: Ignore directive added since handler prop function is covered in the
   * child component test.
   *
   * @param {object} formData - Data object of Manage Gift form data.
   */
  /* istanbul ignore next */
  function handleManageGiftFormChange(formData) {
    const hasErrors =
      !formData?.attributes?.amount ||
      parseInt(formData?.attributes?.amount, 10) <= 0 ||
      Number.isNaN(parseInt(formData?.attributes?.amount, 10)) ||
      !formData?.attributes?.campus ||
      !formData?.attributes?.fund ||
      !formData?.attributes?.payment_method;
    setEditManageGiftFormHasErrors(hasErrors);
  }

  /**
   * Convenience effect to set the scroll listener for the first screen.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (screen1ContentRef.current) {
      screen1ContentRef?.current?.addEventListener(
        'scroll',
        handleScreen1ContentScroll,
      );
    }
  }, [screen1ContentRef]);

  /**
   * Convenience effect to ensure the scroll position is kept for the first
   * screen, as potential re-renders may occur with transition to screen 2.
   * While this looks nearly identical to the effect above, its dependency
   * array ensures it fires off when modal mode or scroll position changes, not
   * just when the content ref gets set (as is the case above).
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (screen1ContentRef?.current) {
      screen1ContentRef.current.scrollTop = scrollPosition?.screen1;
    }
  }, [modalMode, scrollPosition.screen1]);

  /**
   * Single-run effect to trigger analytics event.
   *
   * Note: Ignore added since generic url value asserted in tests and the
   * optional chainer used is always used when referencing for window items to
   * ensure minimal errors.
   */
  React.useEffect(() => {
    callSegmentPage({
      category: '',
      name: ANALYTICS.pages.managePaymentMethods,
      properties: {
        logged_in: !!user,
        path: window?.location?.pathname,
        preferred_campus: preferredCampus,
        referrer: document?.referrer,
        screen_class: ANALYTICS.screens.classes.oneScreen,
        title: document?.title,
        url: /* istanbul ignore next */ window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    let segmentPropertiesLabel;
    switch (mode) {
      case MODAL_MODES.detail:
        segmentPropertiesLabel = ANALYTICS.labels.paymentMethodDetail;
        break;
      case MODAL_MODES.edit:
        segmentPropertiesLabel = ANALYTICS.labels.editPaymentMethod;
        break;
      case MODAL_MODES.manage:
        segmentPropertiesLabel = ANALYTICS.labels.manageGift;
        break;
      default:
        segmentPropertiesLabel = ANALYTICS.labels.paymentMethodList;
        break;
    }

    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.managePaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: segmentPropertiesLabel,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Convenience variables to store class names for the main parent content div.
  const contentClassName = 'animatable-content manage-payment-method thirds';
  /* istanbul ignore next */
  let contentPlacementClass = 'active-1';
  /* istanbul ignore next */
  let modalHeaderClassName =
    iconOverride && iconOverride === ICON_OVERRIDES.back
      ? 'modal-header-mode-back'
      : '';

  /* istanbul ignore next */
  if (
    [MODAL_MODES.detail, MODAL_MODES.edit, MODAL_MODES.manage].includes(
      modalMode,
    )
  ) {
    contentPlacementClass = `active-${
      modalMode === MODAL_MODES.detail ? '2' : '3'
    }`;
    modalHeaderClassName = 'modal-header-mode-back';
  }

  return (
    <BaseModal
      className={/* istanbul ignore next */ iconOverride ? 'animate-rtl' : ''}
      content={
        <div
          className={[contentClassName, contentPlacementClass].join(' ')}
          data-testid="manage-payment-method-modal"
        >
          <PaymentMethodListScreen
            className="payment-method-list screen-1"
            contentRef={screen1ContentRef}
            onAddPaymentMethodClick={
              handlePaymentMethodListAddPaymentMethodClick
            }
            onEntryListItemClick={handlePaymentMethodListItemClick}
          />
          <PaymentMethodDetailScreen
            className="payment-method-detail screen-2"
            onEditClick={handlePaymentMethodListItemEditClick}
            onNavigateBack={handlePaymentMethodDetailNavigateBack}
            onUpcomingGiftEntryListItemClick={
              handleUpcomingGiftEntryListItemClick
            }
            paymentMethodData={selectedPaymentMethod}
            upcomingGifts={scheduledGiftsForSelectedPaymentMethod}
          />

          {
            /* istanbul ignore next */ modalMode === MODAL_MODES.manage ? (
              <ManageGift
                className="manage-gift screen-3"
                frequencyLabel={frequencyLabel}
                isLocationCurrentLocation={isLocationCurrentLocation}
                onFormChange={handleManageGiftFormChange}
              />
            ) : (
              <EditPaymentMethodScreen
                className="edit-payment-method screen-3"
                formData={editPaymentMethodFormData}
                onFormChange={handleEditPaymentMethodFormChange}
                paymentMethodData={selectedPaymentMethod}
                user={user}
              />
            )
          }

          {
            /* istanbul ignore next */ networkRequestStatus.processing ? (
              <div className="loading-wrapper">
                <Loading />
              </div>
            ) : null
          }
        </div>
      }
      contentClassName="animatable pt-none"
      data-testid="manage-payment-method-modal"
      footer={
        comparitor === MODAL_MODES.manage || comparitor === MODAL_MODES.edit ? (
          <>
            {comparitor === MODAL_MODES.manage ? (
              <div className="btn-lineup">
                <StyledButton
                  disabled={networkRequestStatus.processing}
                  onClick={handleDeleteGiftClick}
                  variant={ButtonVariants.secondary}
                >
                  {
                    /* istanbul ignore next */ networkRequestStatus.processing &&
                    networkRequestStatus.method === HTTP_METHODS.del ? (
                      <div className="circular loader"></div>
                    ) : (
                      <>{STRINGS.labels.delete}</>
                    )
                  }
                </StyledButton>
                <StyledButton
                  disabled={
                    /* istanbul ignore next */ networkRequestStatus.processing ||
                    editManageGiftFormHasErrors
                  }
                  onClick={handleSaveGiftClick}
                  variant={ButtonVariants.primary}
                >
                  {
                    /* istanbul ignore next */ networkRequestStatus.processing &&
                    networkRequestStatus.method === HTTP_METHODS.patch ? (
                      <div className="circular loader"></div>
                    ) : (
                      <>{STRINGS.labels.save}</>
                    )
                  }
                </StyledButton>
              </div>
            ) : (
              <StyledButton
                className="full-width"
                disabled={
                  /* istanbul ignore next */ networkRequestStatus.processing ||
                  !isEditPaymentMethodFormUpdated ||
                  editPaymentMethodFormHasErrors
                }
                onClick={handlePaymentMethodSaveClick}
                variant={ButtonVariants.primary}
              >
                {
                  /* istanbul ignore next */ networkRequestStatus.processing &&
                  networkRequestStatus.method === HTTP_METHODS.patch ? (
                    <div className="circular loader"></div>
                  ) : (
                    <>{STRINGS.labels.save}</>
                  )
                }
              </StyledButton>
            )}
          </>
        ) : null
      }
      header={
        <ModalHeader
          className={modalHeaderClassName}
          endButton={
            comparitor === MODAL_MODES.select ? (
              <button
                data-testid="add-payment-method-button"
                onClick={handleAddPaymentMethodClick}
              >
                {managePaymentMethodStrings.labels.add}
              </button>
            ) : (
              <button
                data-testid="manage-payment-method-modal-help-button"
                onClick={handleHelpClick}
              >
                {managePaymentMethodStrings.labels.help}
              </button>
            )
          }
          onCloseClick={handleClose}
          title={
            comparitor === MODAL_MODES.detail
              ? selectedPaymentMethod?.attributes?.payment_method_type ||
                managePaymentMethodStrings[modalMode].title
              : managePaymentMethodStrings[modalMode].title
          }
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
