/**
 * @module Loading
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import LoadingSvg from '../../assets/svg/loading.svg';
import { STRINGS } from '../../utils';
import './Loading.scss';

/**
 * Represents a simple animated loader used for display when data is loading.
 *
 * @returns {React.ReactElement} The Loading view.
 */
export function Loading() {
  return (
    <div className="loading flex-center">
      <img alt={STRINGS.labels.loading} src={LoadingSvg} />
    </div>
  );
}
