/**
 * @module PaymentMethodDetailScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { deletePaymentMethod } from '../../../../../api/giving';
import { ScheduledGiftEntryListItem } from '../../../GivingMenuModal/components/ScheduledGiftEntryListItem';
import { PaymentIcon } from '../../../../PaymentIcon';
import TrashCanIcon from '../../../../ui/TrashCanIcon';
import WarningIcon from '../../../../ui/WarningIcon';
import {
  ANALYTICS,
  APP_CONFIG,
  BADGES,
  EDITABLE_PAYMENT_METHOD_TYPES,
  HTTP_METHODS,
  STRINGS,
  calculateMonthOffset,
  logError,
} from '../../../../../utils';
import useAuth from '../../../../../hooks/useAuth';
import useGiving from '../../../../../hooks/useGiving';
import useModals from '../../../../../hooks/useModals';
import '../../../Modal.scss';
import '../../ManagePaymentMethod.scss';

const {
  deletePaymentMethodError,
  detail: paymentMethodDetailStrings,
  dialogs: paymentMethodDialogStrings,
} = STRINGS.modals.managePaymentMethod;

/**
 * Represents the HTML markup and contents for the manage payment method modal's payment method detail.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {Function} props.onEditClick - Handler function for edit button click event.
 * @param {Function} props.onNavigateBack - Handler function for navigation back trigger event.
 * @param {Function} props.onUpcomingGiftEntryListItemClick - Handler function for upcoming gift entry list item click event.
 * @param {PaymentMethod} props.paymentMethodData - The payment method data object.
 * @param {Array<Subscription>} props.upcomingGifts - Array of upcoming scheduled gifts associated with the payment method.
 *
 * @returns {React.ReactElement} The PaymentMethodDetailScreen component.
 */
export function PaymentMethodDetailScreen({
  className,
  onEditClick,
  onNavigateBack,
  onUpcomingGiftEntryListItemClick,
  paymentMethodData,
  upcomingGifts,
}) {
  const filteredUpcomingGifts = upcomingGifts?.filter((gift) => {
    return (
      parseInt(gift?.attributes?.payment_method?.id, 10) ===
      parseInt(paymentMethodData?.id, 10)
    );
  });
  const { getAccessToken, user } = useAuth();
  const { fetchGivingData, getFund, preferredCampus, today } = useGiving();
  const { storeConfirmationDialogData } = useModals();
  const [networkRequestStatus, setNetworkRequestStatus] = React.useState({
    method: null,
    processing: false,
  });
  const isCreditCard =
    paymentMethodData?.attributes?.payment_method_type === 'Credit Card';
  const isEditablePaymentMethodType =
    Object.keys(EDITABLE_PAYMENT_METHOD_TYPES).includes(
      paymentMethodData?.attributes?.payment_method_type,
    ) &&
    !APP_CONFIG.excludedEditablePaymentMethodTypes.includes(
      paymentMethodData?.attributes?.payment_method_type,
    );

  /**
   * Handler function to trigger call to Giving API endpoint for deleting scheduled gift.
   */
  /* istanbul ignore next */
  async function callApiDelete() {
    setNetworkRequestStatus({
      method: HTTP_METHODS.del,
      processing: true,
    });

    try {
      await deletePaymentMethod({
        accessToken: getAccessToken(),
        paymentMethodId: paymentMethodData?.id,
      });

      callSegmentTrack({
        event: ANALYTICS.events.deletePaymentMethod,
        properties: {
          action: ANALYTICS.actions.deleted,
          component: ANALYTICS.screens.names.paymentMethodDetail,
          component_url: null,
          context: ANALYTICS.contexts.oneScreen,
          label: ANALYTICS.labels.delete,
          logged_in: !!user,
          preferred_campus: preferredCampus?.attributes?.code,
          referrer: document?.referrer,
          screen: ANALYTICS.screens.names.paymentMethodDetail,
          title: document?.title,
          url: window?.location?.href,
          user_id: user?.['https://www.life.church/rock_person_alias_id'],
        },
      });

      // Re-fetch user giving data and set back to select mode.
      fetchGivingData({
        callback: () => {
          onNavigateBack();
          setNetworkRequestStatus({
            method: null,
            processing: false,
          });
        },
      });
    } catch (error) {
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(new Error(deletePaymentMethodError), {
        browserConsole: false,
        bugsnag: false,
        windowAlert: true,
      });

      // Re-fetch user giving data and set back to select mode.
      fetchGivingData({
        callback: () => {
          onNavigateBack();
          setNetworkRequestStatus({
            method: null,
            processing: false,
          });
        },
      });
    }
  }

  /**
   * Handler function for Delete button click.
   */
  function handleDeleteButtonClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.deletePaymentMethod,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.deletePaymentMethod,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.paymentMethodDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    storeConfirmationDialogData({
      cancelLabel: paymentMethodDialogStrings.deletePaymentMethod.cancelLabel,
      confirmLabel: paymentMethodDialogStrings.deletePaymentMethod.confirmLabel,
      icon: null,
      isOpen: true,
      message: paymentMethodDialogStrings.deletePaymentMethod.message,
      onCancelClick: /* istanbul ignore next */ () => {
        storeConfirmationDialogData({ isOpen: false });
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.paymentMethodDeleteModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: paymentMethodDialogStrings.deletePaymentMethod.cancelLabel,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.paymentMethodDeleteModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
      },
      onClose: /* istanbul ignore next */ () => {
        storeConfirmationDialogData({
          isOpen: false,
        });
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.paymentMethodDeleteModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.close,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.paymentMethodDeleteModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
      },
      onConfirmClick: /* istanbul ignore next */ () => {
        storeConfirmationDialogData({
          isOpen: false,
        });
        callSegmentTrack({
          event: ANALYTICS.events.buttonAction,
          properties: {
            action: ANALYTICS.actions.clicked,
            component: ANALYTICS.screens.names.paymentMethodDeleteModal,
            component_url: null,
            context: ANALYTICS.contexts.oneScreen,
            label: paymentMethodDialogStrings.deletePaymentMethod.confirmLabel,
            logged_in: !!user,
            preferred_campus: preferredCampus?.attributes?.code,
            referrer: document?.referrer,
            screen: ANALYTICS.screens.names.paymentMethodDeleteModal,
            title: document?.title,
            url: window?.location?.href,
            user_id: user?.['https://www.life.church/rock_person_alias_id'],
          },
        });
        callApiDelete();
      },
      title: paymentMethodDialogStrings.deletePaymentMethod.title,
    });
  }

  /**
   * Handler function for Edit button click.
   */
  function handleEditButtonClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.paymentMethodDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.edit,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.paymentMethodDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    onEditClick({ paymentMethod: paymentMethodData });
  }

  const { attributes: paymentMethodAttributes } = paymentMethodData || {};
  const {
    display_label: displayLabel,
    exp_month: expMonth,
    exp_year: expYear,
    name,
  } = paymentMethodAttributes || {};
  const monthOffset =
    expMonth !== null && expYear !== null
      ? calculateMonthOffset({
          endDate: new Date(expYear, expMonth - 1), // Note: exp month is 1-based, not 0-based like JS Date object.
          startDate: today,
        })
      : null;

  let paymentMethodName = name;
  if (!paymentMethodName || paymentMethodName === '') {
    paymentMethodName = displayLabel;
  }

  let warningStatus = null;
  let upcomingGiftLabel = '';
  let updateToDeleteLabel = '';

  if (monthOffset < 0) {
    warningStatus = 'error';
  } else if (monthOffset < 2) {
    warningStatus = 'warning';
  }

  if (filteredUpcomingGifts?.length) {
    const updateToDeleteReplacer = `${
      paymentMethodDetailStrings.disclaimers.updateToDelete.gift
    }${
      filteredUpcomingGifts?.length > 1
        ? paymentMethodDetailStrings.labels.pluralizer
        : ''
    }`;
    upcomingGiftLabel = `${paymentMethodDetailStrings.labels.upcomingGift}${
      filteredUpcomingGifts?.length > 1
        ? paymentMethodDetailStrings.labels.pluralizer
        : ''
    }`;
    updateToDeleteLabel =
      `${paymentMethodDetailStrings.disclaimers.updateToDelete.main}`.replace(
        paymentMethodDetailStrings.disclaimers.updateToDelete.replacer,
        updateToDeleteReplacer,
      );
  }

  return (
    <div
      className={`${className || ''}`}
      data-testid="manage-payment-method-detail"
    >
      <div className="screen-content">
        <div className="list">
          {/* Details content */}
          <div className="content-block">
            <div className="flex row justify space-between">
              <PaymentIcon height={64} paymentMethodData={paymentMethodData} />
              {isEditablePaymentMethodType ? (
                <StyledButton
                  className="ml-0 mr-0"
                  onClick={handleEditButtonClick}
                  variant={ButtonVariants.primary}
                >
                  {STRINGS.labels.edit}
                </StyledButton>
              ) : null}
            </div>
            {isCreditCard && warningStatus ? (
              <div className={`badge ${warningStatus}`}>
                <WarningIcon color={BADGES[warningStatus].color} />
                {warningStatus === 'error'
                  ? STRINGS.labels.paymentMethodExpired
                  : STRINGS.labels.paymentMethodExpiring}
              </div>
            ) : null}
            {paymentMethodData?.attributes?.last_4 ? (
              <div className="flex column section grid">
                <div className="label-text">
                  {
                    paymentMethodDetailStrings.labels[
                      isCreditCard ? 'cardNumber' : 'accountNumber'
                    ]
                  }
                </div>
                <div className="title-text primary">
                  {`****`}
                  {paymentMethodData?.attributes?.last_4}
                </div>
              </div>
            ) : null}
            {isCreditCard &&
            (paymentMethodData?.attributes?.expiration_label ||
              paymentMethodData?.attributes?.op_1) ? (
              <div className="flex column section grid">
                <div className="label-text">
                  {paymentMethodDetailStrings.labels.expirationDate}
                </div>
                <div className="title-text primary">
                  {paymentMethodData.attributes.op_1 ||
                    paymentMethodData.attributes.expiration_label}
                </div>
              </div>
            ) : null}
            {paymentMethodName ? (
              <div className="flex column section grid">
                <div className="label-text">
                  {
                    paymentMethodDetailStrings.labels[
                      isEditablePaymentMethodType ||
                      paymentMethodData?.attributes?.payment_method_type
                        ?.toLowerCase()
                        .indexOf('ach') >= 0
                        ? 'name'
                        : 'username'
                    ]
                  }
                </div>
                <div className="title-text primary">{paymentMethodName}</div>
              </div>
            ) : null}
          </div>

          {filteredUpcomingGifts?.length ? (
            <>
              <p className="section-title">{upcomingGiftLabel}</p>
              <div
                className="list list-container grouped pb-0"
                data-testid="giving-menu-my-giving-list"
              >
                {filteredUpcomingGifts.map((entry) => {
                  /* istanbul ignore next */
                  const fundIcon = getFund(entry?.attributes?.fund)?.attributes
                    ?.icon;
                  return (
                    <ScheduledGiftEntryListItem
                      data={entry}
                      icon={fundIcon}
                      key={entry?.id}
                      onClick={() => {
                        /**
                         * Note: This is covered with tests but doesn't show as covered in coverage report.
                         */
                        /* istanbul ignore next */
                        onUpcomingGiftEntryListItemClick({ giftData: entry });
                      }}
                    />
                  );
                })}
              </div>
            </>
          ) : null}

          {/* Delete and disclaimer */}
          <div className="mb-16 mt-16">
            <StyledButton
              className="full-width ml-0 mr-0"
              data-testid="payment-method-detail-screen-delete-button"
              disabled={filteredUpcomingGifts?.length}
              onClick={handleDeleteButtonClick}
              variant={
                paymentMethodDetailStrings.ctas.deletePaymentMethod.variant
              }
            >
              {
                /* istanbul ignore next */ networkRequestStatus.processing &&
                networkRequestStatus.method === HTTP_METHODS.del ? (
                  <div className="circular loader"></div>
                ) : (
                  <>
                    <TrashCanIcon />
                    {paymentMethodDetailStrings.ctas.deletePaymentMethod.label}
                  </>
                )
              }
            </StyledButton>
            {filteredUpcomingGifts?.length ? (
              <p className="disclaimer mt-8 text-center">
                {updateToDeleteLabel}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
