/**
 * @module sorter
 */

/**
 * Convenience function to sort the given history entries into month-specific
 * arrays for use in populating organized output of list items.
 *
 * @param {Array<HistoryDonation>} historyEntries - Array of history donations.
 *
 * @returns {object} Object of month-specific arrays of HistoryDonations.
 */
export function sortGivingHistory(historyEntries) {
  // Reduce, combine, and sort history before setting in history state.
  const historyInfo = historyEntries.reduce((acc, curr) => {
    const d = new Date(curr.attributes.payment_date * 1000);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    const month = d.getMonth();
    const currState = acc?.[month] ?? [];
    acc[month] = [...currState, curr];
    return acc;
  }, {});

  const sortedHistory = Object.keys(historyInfo)
    .sort(/* istanbul ignore next */ (a, b) => a < b)
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = historyInfo[key];
      return obj;
    }, {});

  // Sort by newest date first. Note that this likely is already ordered, and
  // as such, Jest ignore is added to avoid needing to test edge case.
  /* istanbul ignore next */
  Object.entries(sortedHistory).forEach(([entryMonth, entryDates]) => {
    sortedHistory[entryMonth] = entryDates.sort((a, b) =>
      a.attributes.payment_date < b.attributes.payment_date ? 1 : -1,
    );
  });

  return sortedHistory;
}
