/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module LockIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a LockIcon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#666667').
 *
 * @returns {React.ReactElement} The Lock Icon element.
 */
export default function LockIcon({ color = '#666667' }) {
  return (
    <div className="icon">
      <svg
        fill="none"
        height="15"
        viewBox="0 0 10 15"
        width="10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.51367 14.6479H8.48633C9.43115 14.6479 9.89258 14.1792 9.89258 13.1538V7.77783C9.89258 6.85498 9.51172 6.37891 8.72803 6.29834V4.45264C8.72803 1.69141 6.91895 0.358398 5 0.358398C3.08105 0.358398 1.27197 1.69141 1.27197 4.45264V6.32031C0.554199 6.4375 0.107422 6.91357 0.107422 7.77783V13.1538C0.107422 14.1792 0.568848 14.6479 1.51367 14.6479ZM2.45117 4.29883C2.45117 2.46045 3.63037 1.48633 5 1.48633C6.36963 1.48633 7.54883 2.46045 7.54883 4.29883V6.29102H2.45117V4.29883Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
