/**
 * @module TrashCanIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents an icon for a trash can.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The TrashCanIcon element.
 */
export default function TrashCanIcon({
  color = '#000000',
  ...passThroughProps
}) {
  const wrapperClass = ['icon', passThroughProps?.className || ''].join(' ');

  return (
    <div
      className={wrapperClass}
      data-color={color}
      data-testid="trash-can-icon"
    >
      <svg
        fill="none"
        height="18"
        viewBox="0 0 19 18"
        width="19"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 6.75V14.25H6.5V6.75H12.5ZM11.375 2.25H7.625L6.875 3H4.25V4.5H14.75V3H12.125L11.375 2.25ZM14 5.25H5V14.25C5 15.075 5.675 15.75 6.5 15.75H12.5C13.325 15.75 14 15.075 14 14.25V5.25Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
