/**
 * @module apiClient
 */

/* eslint-disable max-classes-per-file */
import 'cross-fetch/polyfill';
import { REQUEST_HEADERS, logError, paramsToQuery } from '../../../utils';

/**
 * Giving API v3 Notes: May 2004.
 *
 * With the update to use Giving API v3, while still offering support for v2 if
 * needed, some extra conditional logic was needed for this file and the params
 * and headers for certain calls. Most notably, the 'Content-Type' header has
 * the value of multipart form data or application/json depending on the version
 * of the API, and its corresponding params are either set as string-based query
 * parameter style form body or using JSON.stringify().
 */

/**
 * Base apiClient that wraps fetch.
 *
 * @param {object} config - The config object.
 * @param {object} config.options - The config options.
 * @param {string} config.url - The url to fetch.
 *
 * @throws {Error}
 *
 * @returns {Promise} - The response model object.
 */
async function apiClient({ options, url }) {
  let errorMessage;
  try {
    const response = await fetch(url, options);
    let json;
    try {
      // Handle blank/null response (e.g. from preflight check) by getting
      // response text, checking length, then parsing as needed.
      const text = await response.text();
      if (text.length) {
        json = JSON.parse(text);
      } else {
        json = {};
      }
    } catch (e) {
      /* istanbul ignore next */
      errorMessage = `Sorry, there was a problem parsing the data retrieved from ${url}.`;
      /* istanbul ignore next */
      logError(new Error(errorMessage), { bugsnag: false });
    }
    return json;
  } catch (error) {
    // Note: Only error logging browser console, as there are API calls made
    // that likely will fail/return non-200 status codes, in the case that a
    // user is not authenticated, as there's some calls that are made with
    // access token that may not be present, and thus, fallback data is used.
    /* istanbul ignore next */
    logError(new Error(errorMessage || error.message), {
      browserConsole: true,
      bugsnag: false,
      windowAlert: false,
    });
    /* istanbul ignore next */
    return null;
  }
}

/**
 * Make a DELETE call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} [config.params] - DELETE params, will be converted to valid query string with support for bracket-less arrays.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function del({
  options = {},
  params = {},
  url,
  ...passThroughProps
}) {
  const queryParams = paramsToQuery(params).length
    ? `?${paramsToQuery(params)}`
    : '';
  return apiClient({
    options: {
      ...options,
      method: 'DELETE',
    },
    url: `${url}${queryParams}`,
    ...passThroughProps,
  });
}

/**
 * Make a GET call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} [config.params] - GET params, will be converted to valid query string with support for bracket-less arrays.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function get({
  options = {},
  params = {},
  url,
  ...passThroughProps
}) {
  const queryParams = paramsToQuery(params).length
    ? `?${paramsToQuery(params)}`
    : '';
  return apiClient({
    options: {
      ...options,
      method: 'GET',
    },
    url: `${url}${queryParams}`,
    ...passThroughProps,
  });
}

/* istanbul ignore next */
/**
 * Make a HEAD call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} [config.params] - HEAD params, will be converted to valid query string with support for bracket-less arrays.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function head({
  options = {},
  params = {},
  url,
  ...passThroughProps
}) {
  const queryParams = paramsToQuery(params).length
    ? `?${paramsToQuery(params)}`
    : '';
  return apiClient({
    options: {
      ...options,
      method: 'HEAD',
    },
    url: `${url}${queryParams}`,
    ...passThroughProps,
  });
}

/* istanbul ignore next */
/**
 * Make a PATCH call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} config.params - PATCH body params.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function patch({
  options = {},
  params,
  url,
  ...passThroughProps
}) {
  const formBody = [];
  const keyValues = Object.entries(params);
  keyValues.forEach((entry) => {
    const encodedKey = encodeURIComponent(entry[0]);
    const encodedValue = encodeURIComponent(entry[1]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  return apiClient({
    options: {
      ...options,
      body:
        /* istanbul ignore next */ process.env.GIVING_API_VERSION === 'v2' &&
        options?.headers?.['Content-Type'] !==
          REQUEST_HEADERS.contentType.values.applicationJson
          ? formBody.join('&')
          : JSON.stringify(params),
      headers: {
        'Content-Type':
          /* istanbul ignore next */ REQUEST_HEADERS.contentType.values[
            process.env.GIVING_API_VERSION === 'v2'
              ? 'multipartFormData'
              : 'applicationJson'
          ], // Default, may be overwritten via options.headers in calling method.
        ...options.headers,
      },
      method: 'PATCH',
    },
    url,
    ...passThroughProps,
  });
}

/**
 * Make a POST call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object|string} config.params - POST body params.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function post({ options = {}, params, url, ...passThroughProps }) {
  const formBody = [];
  const keyValues = Object.entries(params);
  keyValues.forEach((entry) => {
    const encodedKey = encodeURIComponent(entry[0]);
    const encodedValue = encodeURIComponent(entry[1]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  /* istanbul ignore next */
  let finalBody =
    process.env.GIVING_API_VERSION === 'v2'
      ? formBody.join('&')
      : JSON.stringify(params);
  if (
    options?.headers?.['Content-Type'] ===
    REQUEST_HEADERS.contentType.values.xWwwFormUrlEncoded
  ) {
    finalBody = formBody.join('&');
  }

  return apiClient({
    options: {
      ...options,
      body: finalBody,
      headers: {
        'Content-Type':
          REQUEST_HEADERS.contentType.values[
            /* istanbul ignore next */ process.env.GIVING_API_VERSION === 'v2'
              ? 'multipartFormData'
              : 'applicationJson'
          ], // Default, may be overwritten via options.headers in calling method.
        ...options.headers,
      },
      method: 'POST',
    },
    url,
    ...passThroughProps,
  });
}

/* istanbul ignore next */
/**
 * Make a PUT call.
 *
 * @param {object} config - The configuration object.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {object} config.params - PUT body params.
 * @param {string} config.url - The URL endpoint path.
 *
 * @returns {Promise} The fetch response.
 */
export async function put({ options = {}, params, url, ...passThroughProps }) {
  const formBody = [];
  const keyValues = Object.entries(params);
  keyValues.forEach((entry) => {
    const encodedKey = encodeURIComponent(entry[0]);
    const encodedValue = encodeURIComponent(entry[1]);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  return apiClient({
    options: {
      ...options,
      body:
        /* istanbul ignore next */ process.env.GIVING_API_VERSION === 'v2'
          ? formBody.join('&')
          : JSON.stringify(params),
      headers: {
        'Content-Type':
          REQUEST_HEADERS.contentType.values[
            /* istanbul ignore next */ process.env.GIVING_API_VERSION === 'v2'
              ? 'multipartFormData'
              : 'applicationJson'
          ], // Default, may be overwritten via options.headers in calling method.
        ...options.headers,
      },
      method: 'PUT',
    },
    url,
    ...passThroughProps,
  });
}
