/**
 * @module EmailIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents an icon for an email envelope.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The EmailIcon element.
 */
export default function EmailIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="email-icon">
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3903_823)">
          <path
            d="M25.6 6.66797H6.4C5.08 6.66797 4.012 7.71797 4.012 9.0013L4 23.0013C4 24.2846 5.08 25.3346 6.4 25.3346H25.6C26.92 25.3346 28 24.2846 28 23.0013V9.0013C28 7.71797 26.92 6.66797 25.6 6.66797ZM25.6 23.0013H6.4V11.3346L16 17.168L25.6 11.3346V23.0013ZM16 14.8346L6.4 9.0013H25.6L16 14.8346Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_3903_823">
            <rect fill="white" height="32" width="32" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
