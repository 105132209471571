/**
 * @module RepeatIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents an icon for a repeating calendar.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The RepeatIcon element.
 */
export default function RepeatIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="repeat-icon">
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.71429 25.6983C5.25714 25.6983 4.85714 25.5255 4.51429 25.1801C4.17143 24.8346 4 24.4316 4 23.971V6.12251C4 5.66191 4.17143 5.25888 4.51429 4.91342C4.85714 4.56797 5.25714 4.39524 5.71429 4.39524H7.57143V2.66797H9.42857V4.39524H19.1429V2.66797H21V4.39524H22.8571C23.3143 4.39524 23.7143 4.56797 24.0571 4.91342C24.4 5.25888 24.5714 5.66191 24.5714 6.12251V14.7589H22.8571V11.5922H5.71429V23.971H14.9714V25.6983H5.71429ZM22.2857 28.0013C20.8952 28.0013 19.6809 27.5647 18.6429 26.6915C17.6048 25.8182 16.9524 24.7195 16.6857 23.3952H18.4571C18.7048 24.2397 19.1762 24.9306 19.8714 25.468C20.5667 26.0053 21.3714 26.274 22.2857 26.274C23.3905 26.274 24.3333 25.8806 25.1143 25.0937C25.8952 24.3069 26.2857 23.3569 26.2857 22.2437C26.2857 21.1306 25.8952 20.1806 25.1143 19.3937C24.3333 18.6069 23.3905 18.2134 22.2857 18.2134C21.7333 18.2134 21.219 18.3142 20.7429 18.5157C20.2667 18.7172 19.8476 19.0003 19.4857 19.3649H21.1429V21.0922H16.5714V16.4861H18.2857V18.1271C18.8 17.6281 19.4 17.2298 20.0857 16.9324C20.7714 16.6349 21.5048 16.4861 22.2857 16.4861C23.8667 16.4861 25.2143 17.0475 26.3286 18.1702C27.4429 19.293 28 20.6508 28 22.2437C28 23.8367 27.4429 25.1945 26.3286 26.3172C25.2143 27.4399 23.8667 28.0013 22.2857 28.0013ZM5.71429 9.86494H22.8571V6.12251H5.71429V9.86494Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
