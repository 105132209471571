/**
 * @module WarningIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a WarningIcon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#e33300').
 *
 * @returns {React.ReactElement} The Warning Icon element.
 */
export default function WarningIcon({ color = '#e33300' }) {
  return (
    <div className="icon" data-color={color} data-testid="warning-icon">
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.1 14.7H12.9V16.5H11.1V14.7ZM11.1 7.5H12.9V12.9H11.1V7.5ZM11.991 3C7.023 3 3 7.032 3 12C3 16.968 7.023 21 11.991 21C16.968 21 21 16.968 21 12C21 7.032 16.968 3 11.991 3ZM12 19.2C8.022 19.2 4.8 15.978 4.8 12C4.8 8.022 8.022 4.8 12 4.8C15.978 4.8 19.2 8.022 19.2 12C19.2 15.978 15.978 19.2 12 19.2Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
