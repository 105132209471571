/**
 * @module errorLogger
 */
import Bugsnag from '@bugsnag/js';
import { Log } from '@lifechurch/web-tools-io/dist/utils/helpers/browserLogger';

const defaultOptions = {
  browserConsole: true,
  bugsnag: true,
  windowAlert: false,
};

/**
 * Convenience function to log an Error to the specified utilities.
 *
 * @param {Error} error - The Error object to log and handle.
 * @param {ErrorLoggerOptions} options - Options object for handling and logging the specified error.
 * @param {boolean} [options.browserConsole] - Boolean flag denoting whether to log the error to the browser console.
 * @param {boolean}  [options.bugsnag] - Boolean flag denoting whether to track and notify with Bugsnag.
 * @param {boolean}  [options.windowAlert] - Boolean flag denoting whether to call `alert` to display the Error message.
 */
export function logError(
  error,
  {
    browserConsole = true,
    bugsnag = true,
    windowAlert = false,
  } = defaultOptions,
) {
  if (!error?.message || !(error instanceof Error)) {
    // Not a valid Error object provided. Do nothing.
  } else {
    if (windowAlert) {
      // eslint-disable-next-line no-alert
      alert(error.message);
    }
    /* istanbul ignore next */
    if (bugsnag && Bugsnag.isStarted()) {
      Bugsnag.notify(error);
    }
    /* istanbul ignore next */
    if (browserConsole) {
      Log.error(error);
    }
  }
}
