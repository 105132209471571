/**
 * @module position
 */
export const CALCULATION_METHODS = Object.freeze({
  cosine: 'cosine',
  haversine: 'haversine',
});

const earthRadiusInMeters = 6371e3;

/**
 * Convenience function to determine distance between the supplied coordinates using Cosine formula.
 *
 * @param {LatitudeLongitude} coords1 - Coordinates object of first location.
 * @param {LatitudeLongitude} coords2 - Coordinates object of second location.
 *
 * @returns {object} Data object containing distances between the specified coordinates, with both `km` and `m` values.
 *
 * @see {@link https://henry-rossiter.medium.com/calculating-distance-between-geographic-coordinates-with-javascript-5f3097b61898}.
 */
function cosineDistanceBetweenPoints(coords1, coords2) {
  const R = earthRadiusInMeters;
  const p1 = (coords1.latitude * Math.PI) / 180;
  const p2 = (coords2.latitude * Math.PI) / 180;
  const deltaP = p2 - p1;
  const deltaLon = coords2.longitude - coords1.longitude;
  const deltaLambda = (deltaLon * Math.PI) / 180;
  const a =
    Math.sin(deltaP / 2) * Math.sin(deltaP / 2) +
    Math.cos(p1) *
      Math.cos(p2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2);
  const d = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)) * R;
  return {
    km: d / 1000,
    m: d,
  };
}

/**
 * Convenience function to determine distance between the supplied coordinates using Haversine formula.
 *
 * @param {LatitudeLongitude} coords1 - Coordinates object of first location.
 * @param {LatitudeLongitude} coords2 - Coordinates object of second location.
 *
 * @returns {object} Data object containing distances between the specified coordinates, with both `km` and `m` values.
 *
 * @see {@link https://henry-rossiter.medium.com/calculating-distance-between-geographic-coordinates-with-javascript-5f3097b61898}.
 */
function haversineDistanceBetweenPoints(coords1, coords2) {
  const R = earthRadiusInMeters;
  const p1 = (coords1.latitude * Math.PI) / 180;
  const p2 = (coords2.latitude * Math.PI) / 180;
  const deltaLon = coords2.longitude - coords1.longitude;
  const deltaLambda = (deltaLon * Math.PI) / 180;
  const d =
    Math.acos(
      Math.sin(p1) * Math.sin(p2) +
        Math.cos(p1) * Math.cos(p2) * Math.cos(deltaLambda),
    ) * R;
  return {
    km: d / 1000,
    m: d,
  };
}

/**
 * Convenience function to calculate the distance between the two specified geolocation coordinates.
 *
 * @param {object} params - The function params object.
 * @param {GeolocationCalculationMethod} [params.calculationMethod] - The calculation method to use when calculating distances (Default: 'cosine').
 * @param {LatitudeLongitude} params.coords1 - Coordinates object of first location.
 * @param {LatitudeLongitude} params.coords2 - Coordinates object of second location.
 *
 * @returns {object} Data object containing distances between the specified coordinates, with both `km` and `m` values.
 *
 * @see {@link https://henry-rossiter.medium.com/calculating-distance-between-geographic-coordinates-with-javascript-5f3097b61898}.
 */
export function calculateDistanceFromGeolocationCoordinates({
  calculationMethod,
  coords1,
  coords2,
}) {
  if (
    !coords1?.latitude ||
    !coords1?.longitude ||
    !coords2?.latitude ||
    !coords2?.longitude
  ) {
    return {
      km: null,
      m: null,
    };
  }

  if (calculationMethod === CALCULATION_METHODS.haversine) {
    return haversineDistanceBetweenPoints(coords1, coords2);
  }
  return cosineDistanceBetweenPoints(coords1, coords2);
}
