/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @module AuthButton
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useAuth from '../../hooks/useAuth';
import defaultUserIcon from '../../assets/svg/user.svg';
import { STRINGS } from '../../utils';

/**
 * Represents a button used for authentication, tied in to using Auth0 flow.
 *
 * @param {object} props - The component props object.
 * @param {Function} [props.onClick] - Handler function for click event.
 *
 * @returns {React.ReactElement} The AuthButton component.
 */
function AuthButton({ onClick }) {
  const { isAuthenticated, user } = useAuth();
  const [userIcon, setUserIcon] = React.useState(defaultUserIcon);

  /* istanbul ignore next */
  React.useEffect(() => {
    if (user?.picture) {
      /* istanbul ignore next */
      setUserIcon(user.picture);
    }
  }, [isAuthenticated, user?.picture]);

  return (
    <a data-testid="auth-button" href="" onClick={onClick}>
      {
        /* istanbul ignore next */ isAuthenticated ? (
          <img
            alt={STRINGS.auth.profile.avatarImgAlt}
            className="avatar"
            onError={({ currentTarget }) => {
              setUserIcon(defaultUserIcon);
              currentTarget.onerror = null;
              currentTarget.src = defaultUserIcon;
            }}
            src={userIcon}
          />
        ) : null
      }
      <span className="login-text">
        {
          /* istanbul ignore next */ !isAuthenticated
            ? STRINGS.general.signIn
            : ''
        }
      </span>
    </a>
  );
}

export default AuthButton;
