/**
 * @module RadioList
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { RadioListItem } from '../RadioListItem';

/**
 * Represents a list of radio list items.
 *
 * @param {object} props - The component props object.
 * @param {string} props.inputName - The list input name value.
 * @param {Function} props.onChange - Handler function for list change event.
 * @param {number|string} props.selectedValue - The selected value.
 * @param {Array} props.values - Array of values used to populate the list.
 *
 * @returns {React.ReactElement} The RadioList component.
 */
export function RadioList({
  inputName,
  onChange,
  selectedValue,
  values,
  ...passThroughProps
}) {
  /**
   * Note: With the addition of passThroughProps (i.e. `rest`), it allows the
   * component to get and use additional props, such as `className`. As such,
   * use a dynamically-built list of class names, based on the standard for the
   * component, but adding in additionally-provided values as well.
   */
  const wrapperClass = ['list', passThroughProps?.className || ''].join(' ');

  return (
    <div className={wrapperClass} data-testid="radio-list">
      {values.map((value) => {
        return (
          <RadioListItem
            inputName={inputName}
            key={`${value}`}
            label={value}
            onChange={(event) => {
              event.preventDefault();
              onChange(value);
            }}
            selectedValue={selectedValue}
            value={value}
          />
        );
      })}
    </div>
  );
}
