/**
 * @module GivingStatementsScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { base } from '@lifechurch/web-tools-io/dist/themes';
import { Loading } from '../../../../../views/Loading';
import { ListItem } from '../../../../ListItem';
import { GoPaperless } from '../../components/GoPaperless';
import DownloadIcon from '../../../../ui/DownloadIcon';
import LeafIcon from '../../../../ui/LeafIcon';
import { STRINGS } from '../../../../../utils';

/**
 * Convenience variable to store strings.
 */
const givingHistoryStrings = STRINGS.modals.givingHistory;

/**
 * Represents a list item for a specific giving statement year entry.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onClick - Handler function for the list item click event.
 * @param {number|string} props.year - The year value of the giving statement.
 *
 * @returns {React.ReactElement} The GivingStatementEntryListItem component.
 */
function GivingStatementEntryListItem({ onClick, year }) {
  return (
    <ListItem
      actionIcon={<DownloadIcon />}
      className="giving-statement-entry-list-item"
      id={`giving-statement-entry-list-item-${year}`}
      key={`giving-statement-entry-list-item-${year}`}
      onClick={
        /* istanbul ignore next */ (event) => {
          onClick(event, year);
        }
      }
      title={`${year} ${givingHistoryStrings['giving-statements'].labels.givingStatements}`}
      titleProps={{ large: true }}
    />
  );
}

/**
 * Represents a view for the Giving History Giving Statements screen.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isDownloadRetrieving] - Boolean flag denoting the status of the history download.
 * @param {Function} [props.onDismissPaperlessPromptClick] - Handler function for the paperless prompt Dismiss button click event.
 * @param {Function} [props.onDownloadStatementListItemClick] - Handler function for the list item click event.
 * @param {Function} [props.onEnrollNowPaperlessPromptClick] - Handler function for the paperless prompt Enroll Now button click event.
 * @param {Function} [props.onPaperlessPreferenceListItemClick] - Handler function for year select click event.
 * @param {boolean} props.paperlessStatus - Boolean flag denoting user paperless statement status.
 * @param {boolean} props.showPaperlessPrompt - Optional boolean flag denoting whether or not to show the paperless prompt.
 * @param {Array<number>} props.years - Array of year values with which to populate the list of giving statements.
 *
 * @returns {React.ReactElement} The GivingHistoryList component.
 */
export function GivingStatementsScreen({
  isDownloadRetrieving,
  onDismissPaperlessPromptClick,
  onDownloadStatementListItemClick,
  onEnrollNowPaperlessPromptClick,
  onPaperlessPreferenceListItemClick,
  paperlessStatus,
  showPaperlessPrompt,
  years,
}) {
  /**
   * Handler function for Giving Statement entry list item click event.
   *
   * @param {Event} event - The Event object associated with the click event.
   * @param {number|string} year - The year associated with the list item.
   */
  function handleGivingStatementEntryListItemClick(event, year) {
    onDownloadStatementListItemClick(event, year);
  }

  return (
    <>
      <div className="giving-statements mb-16">
        {showPaperlessPrompt ? (
          <GoPaperless
            className="mb-16"
            onDismissClick={onDismissPaperlessPromptClick}
            onEnrollNowClick={onEnrollNowPaperlessPromptClick}
          />
        ) : null}
        {years?.length ? (
          <div className="list">
            <div className="giving-statements-list">
              <p className="section-label">
                {
                  givingHistoryStrings['giving-statements'].labels
                    .downloadStatements
                }
              </p>
              <div className="list list-container grouped">
                {years.map((year) => {
                  return (
                    <GivingStatementEntryListItem
                      key={`giving-statement-entry-${year}`}
                      onClick={handleGivingStatementEntryListItemClick}
                      year={year}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : null}
        <ListItem
          className="paperless-preferences-list-item"
          onClick={onPaperlessPreferenceListItemClick}
          startIcon={
            <LeafIcon
              color={
                base.colors[paperlessStatus ? 'utilityValidGreen' : 'gray50']
              }
              style={{ height: '32px', width: 'auto' }}
            />
          }
          title={
            givingHistoryStrings['giving-statements'].labels.paperlessPreference
          }
          titleProps={{ large: true, primary: true }}
        />
      </div>
      {isDownloadRetrieving ? (
        <div className="giving-history-loading-wrapper loading-wrapper">
          <Loading />
        </div>
      ) : null}
    </>
  );
}
