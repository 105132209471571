/**
 * @module Date
 */

export const MONTHS = [
  {
    full: 'January',
    short: 'Jan',
  },
  {
    full: 'February',
    short: 'Feb',
  },
  {
    full: 'March',
    short: 'Mar',
  },
  {
    full: 'April',
    short: 'Apr',
  },
  {
    full: 'May',
    short: 'May',
  },
  {
    full: 'June',
    short: 'Jun',
  },
  {
    full: 'July',
    short: 'Jul',
  },
  {
    full: 'August',
    short: 'Aug',
  },
  {
    full: 'September',
    short: 'Sep',
  },
  {
    full: 'October',
    short: 'Oct',
  },
  {
    full: 'November',
    short: 'Nov',
  },
  {
    full: 'December',
    short: 'Dec',
  },
];

/**
 * Convenience function to calculate the number of days between the specified Dates.
 *
 * @param {object} params - The function params object.
 * @param {Date} params.endDate - The end Date object.
 * @param {Date} params.startDate - The start Date object.
 *
 * @throws {Error} - Throws an error if dates are missing or are invalid type.
 *
 * @returns {number} The number of days (offset) between the start and end date.
 */
export function calculateDaysOffset({ endDate, startDate } = {}) {
  if (
    !endDate ||
    !startDate ||
    Object.prototype.toString.call(endDate) !== '[object Date]' ||
    Object.prototype.toString.call(startDate) !== '[object Date]'
  ) {
    throw new Error('Please provide a valid endDate and startDate.');
  }

  // Set hours, minutes, seconds, and milliseconds to consistent value.
  endDate.setHours(0);
  startDate.setHours(0);
  endDate.setMinutes(0);
  startDate.setMinutes(0);
  endDate.setSeconds(0);
  startDate.setSeconds(0);
  endDate.setMilliseconds(0);
  startDate.setMilliseconds(0);

  // Calculate difference in time and days, returning difference in days.
  const diffInTime = endDate.getTime() - startDate.getTime();
  const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
  return diffInDays;
}

/**
 * Convenience function to calculate the number of months between the specified Dates.
 *
 * @param {object} params - The function params object.
 * @param {Date} params.endDate - The end Date object.
 * @param {Date} params.startDate - The start Date object.
 *
 * @throws {Error} - Throws an error if dates are missing or are invalid type.
 *
 * @returns {number} The number of months between the start and end date.
 */
export function calculateMonthOffset({ endDate, startDate } = {}) {
  if (
    !endDate ||
    !startDate ||
    Object.prototype.toString.call(endDate) !== '[object Date]' ||
    Object.prototype.toString.call(startDate) !== '[object Date]'
  ) {
    throw new Error('Please provide a valid endDate and startDate.');
  }

  // Calculate difference.
  let diffInMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12;
  diffInMonths -= startDate.getMonth();
  diffInMonths += endDate.getMonth();
  return diffInMonths;
}

/**
 * Convenience function to return the closest twice-monthly date (either the 1st or 15th of a month).
 *
 * @param {Date} date - The Date object against which to test for the closest twice-monthly date.
 *
 * @throws {Error} - Throws an error if date is missing or is invalid type.
 *
 * @returns {Date} The Date object of the closest twice-monthly date.
 */
export function closestTwiceMonthlyDate(date) {
  if (!date || Object.prototype.toString.call(date) !== '[object Date]') {
    throw new Error('Please provide a valid Date object.');
  }
  if ([1, 15].includes(date.getDate())) {
    return date;
  }
  return new Date(
    date.getFullYear(),
    date.getDate() > 1 && date.getDate() <= 15
      ? date.getMonth()
      : date.getMonth() + 1,
    date.getDate() > 1 && date.getDate() <= 15 ? 15 : 1,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds(),
  );
}

/**
 * Convenience function to return a formatted date with the format: Mon DD, YYYY.
 *
 * @param {object} params - The function params object.
 * @param {Date} params.date - The Date object to be formatted.
 * @param {boolean} [params.displayFullMonth] - Boolean flag denoting whether or not to use full month value for output (e.g. 'Jan' vs 'January', respectively). Default: false.
 *
 * @throws {Error} - Throws an error if date is missing or is invalid type.
 *
 * @returns {string} Formatted date string.
 */
export function formatDate({ date, displayFullMonth } = {}) {
  if (!date || Object.prototype.toString.call(date) !== '[object Date]') {
    throw new Error('Please provide a valid Date object.');
  }
  const month = MONTHS[date.getMonth()][displayFullMonth ? 'full' : 'short'];
  return `${month} ${date.getDate()}, ${date.getFullYear()}`;
}

/**
 * Convenience function to format and return a date label relative to the provided date.
 *
 * @param {object} params - The function params object.
 * @param {Date} params.date - The Date object to check.
 * @param {Date} params.relativeDate - The Date object against which to check the date's relative label.
 *
 * @throws {Error} - Throws an error if date is missing or is invalid type.
 *
 * @returns {string} Formatted relative date label (e.g. 'Today', 'Tomorrow', 'Jan 1, 2023').
 */
export function relativeDateLabel({ date, relativeDate } = {}) {
  if (
    !date ||
    !relativeDate ||
    Object.prototype.toString.call(date) !== '[object Date]' ||
    Object.prototype.toString.call(relativeDate) !== '[object Date]'
  ) {
    throw new Error('Please provide a valid Date object.');
  }
  let diffDays = Math.abs(
    calculateDaysOffset({ endDate: date, startDate: relativeDate }),
  );
  // If calculated that there is 1 day different, make sure it is not merely a
  // matter of seconds or minutes, but of an actual day.
  if (diffDays === 1) {
    /* istanbul ignore next */
    if (date.getDate() === relativeDate.getDate()) {
      diffDays = 0;
    }
  }
  if (diffDays === 0) {
    return 'Today';
  }
  if (diffDays === 1) {
    return 'Tomorrow';
  }
  return formatDate({ date });
}
