/**
 * @module GivingHistoryList
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import { base } from '@lifechurch/web-tools-io/dist/themes';
import useGiving from '../../../../../hooks/useGiving';
import LeafIcon from '../../../../ui/LeafIcon';
import { ListItem } from '../../../../ListItem';
import SelectLabel from '../../../../SelectLabel';
import { MONTHS, STRINGS, formatWithCommas } from '../../../../../utils';
import { GoPaperless } from '../../components/GoPaperless';

/**
 * Convenience variable to store strings.
 */
const givingHistoryStrings = STRINGS.modals.givingHistory;

/**
 * Represents the view used when giving history for the selected year is none.
 *
 * @param {object} props - The component props object.
 * @param {Function} [props.onGiveNowClick] - Handler function for Give Now button click event.
 *
 * @returns {React.ReactElement} The GivingHistoryEmpty component.
 */
function GivingHistoryEmpty({ onGiveNowClick }) {
  return (
    <div className="no-history">
      <div>
        <h2>{givingHistoryStrings.empty.title}</h2>
        <p>{givingHistoryStrings.empty.body}</p>
        <StyledButton
          className="black full-width ml-0 mr-0"
          onClick={onGiveNowClick}
          variant={ButtonVariants.primary}
        >
          {givingHistoryStrings.empty.cta.label}
        </StyledButton>
      </div>
    </div>
  );
}

/**
 * Represents a list item for a specific giving history entry.
 *
 * @param {object} props - The component props object.
 * @param {History} props.data - The history data object.
 * @param {string} props.icon - Fund icon value used for the startIcon src.
 * @param {Function} props.onClick - Handler function for the list item click event.
 *
 * @returns {React.ReactElement} The GivingHistoryEntryListItem component.
 */
function GivingHistoryEntryListItem({ data, icon, onClick }) {
  /* istanbul ignore next */
  if (!data?.attributes || !data?.id) {
    return null;
  }

  const entryDate = new Date(data.attributes.payment_date * 1000);
  entryDate.setMinutes(entryDate.getMinutes() + entryDate.getTimezoneOffset());
  const entryMonthObject = MONTHS[entryDate?.getMonth()];

  return (
    <ListItem
      description={`${entryMonthObject?.short} ${entryDate?.getDate()} • ${
        data?.attributes?.fund
      }`}
      id={data?.id}
      key={data?.id}
      onClick={
        /* istanbul ignore next */ () => {
          onClick(data);
        }
      }
      startIcon={<img alt={`${data?.attributes?.fund}`} src={icon} />}
      title={`$${formatWithCommas(data?.attributes?.payment_amount)}`}
      titleProps={{ primary: true }}
    />
  );
}

/**
 * Represents a view for the Giving History entries list.
 *
 * @param {object} props - The component props object.
 * @param {React.MutableRefObject} props.contentRef - The mutable content Ref for the list screen content wrapper.
 * @param {HistoryByYear} props.history - The object of History data, with year-based keys.
 * @param {Function} [props.onDismissPaperlessPromptClick] - Handler function for the paperless prompt Dismiss button click event.
 * @param {Function} [props.onEnrollNowPaperlessPromptClick] - Handler function for the paperless prompt Enroll Now button click event.
 * @param {Function} [props.onEntryListItemClick] - Handler function for the list item click event.
 * @param {Function} [props.onGiveNowClick] - Handler function for Give Now button click event.
 * @param {Function} [props.onGivingStatementsClick] - Handler function for the download button click event.
 * @param {Function} [props.onYearSelectClick] - Handler function for year select click event.
 * @param {number} props.selectedYear - The selected and active year.
 * @param {boolean} props.showPaperlessPrompt - Optional boolean flag denoting whether or not to show the paperless prompt.
 *
 * @returns {React.ReactElement} The GivingHistoryList component.
 */
export function GivingHistoryListScreen({
  contentRef,
  history,
  onDismissPaperlessPromptClick,
  onEnrollNowPaperlessPromptClick,
  onEntryListItemClick,
  onGiveNowClick,
  onGivingStatementsClick,
  onYearSelectClick,
  selectedYear,
  showPaperlessPrompt,
}) {
  const { getFund, paperlessStatus } = useGiving();

  return (
    <>
      {showPaperlessPrompt ? (
        <GoPaperless
          onDismissClick={onDismissPaperlessPromptClick}
          onEnrollNowClick={onEnrollNowPaperlessPromptClick}
        />
      ) : null}
      <div className="action-bar">
        <div>
          <SelectLabel label={selectedYear} onClick={onYearSelectClick} />
        </div>
        <div>
          <button
            className="giving-statements-button"
            data-testid="giving-history-giving-statements"
            disabled={!history}
            onClick={onGivingStatementsClick}
          >
            <>
              <LeafIcon
                color={
                  base.colors[paperlessStatus ? 'utilityValidGreen' : 'gray50']
                }
              />
              {givingHistoryStrings.main.labels.givingStatements}
              <div className="action-icon"></div>
            </>
          </button>
        </div>
      </div>
      <div
        className="screen-content"
        data-testid="giving-history-list"
        ref={contentRef}
      >
        {history[selectedYear]?.entries &&
        Object.keys(history[selectedYear]?.entries).length ? (
          <div className="list">
            {Object.entries(history[selectedYear]?.entries)
              .sort(
                /* istanbul ignore next */ (a, b) =>
                  b[0].localeCompare(a[0], 'en', { numeric: true }),
              )
              .map(([historyMonth, monthEntries]) => {
                return (
                  <div
                    data-testid={`giving-history-list-${MONTHS[
                      historyMonth
                    ].full.toLowerCase()}`}
                    key={MONTHS[historyMonth].full}
                  >
                    <p className="month">{MONTHS[historyMonth].full}</p>
                    <div className="list list-container grouped">
                      {monthEntries.map((entry) => {
                        const fundIcon = getFund(entry?.attributes?.fund)
                          ?.attributes?.icon;
                        return (
                          <GivingHistoryEntryListItem
                            data={entry}
                            icon={fundIcon}
                            key={entry?.id}
                            onClick={onEntryListItemClick}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <GivingHistoryEmpty onGiveNowClick={onGiveNowClick} />
        )}
      </div>
    </>
  );
}
