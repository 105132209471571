/**
 * @module GivingMenuModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  callSegmentPage,
  callSegmentTrack,
} from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { ANALYTICS, APP_CONFIG, ICON_OVERRIDES, STRINGS } from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import AuthButton from '../../AuthButton';
import { GivingMenuList } from './Screens/GivingMenuList';
import './GivingMenu.scss';
import '../Modal.scss';

/**
 * Represents the modal to show Giving Menu content for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 *
 * @returns {React.ReactElement} The GivingMenuModal component.
 */
export function GivingMenuModal({ isOpen }) {
  const { isAuthenticated, logIn, user } = useAuth();
  const { preferredCampus } = useGiving();
  const { handleModalClose, handleModalVisibility, modals } = useModals();
  const { givingMenu: givingMenuStrings } = STRINGS.modals;

  /**
   * Handler function for auth button click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  function handleAuthButtonClick(event) {
    if (event) {
      event.preventDefault();
    }

    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingMenu,
        component_url: /* istanbul ignore next */ isAuthenticated
          ? `${APP_CONFIG.baseUrl}/profile/`
          : null,
        context: ANALYTICS.contexts.oneScreen,
        label: !isAuthenticated
          ? ANALYTICS.labels.signIn
          : ANALYTICS.labels.profile,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingMenu,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    if (!isAuthenticated) {
      logIn();
    } else {
      // Per decision by Product Designer/Product Manager, clicking on the
      // button when authenticated will direct the user to the Profile page.
      window.location.href = `${APP_CONFIG.baseUrl}/profile/`;
    }
  }

  /**
   * Note: Ignore directives for handler functions are only for conditional and
   * corresponding `typeof` checks. Assertions that these are triggered are
   * added to test coverage, but since conditional checks are for things set
   * within this component, they're more assurance than anything.
   */

  /**
   * Handler function for modal close event.
   */
  function handleClose() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingMenu,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.close,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingMenu,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalClose(modals.givingMenu.id);
  }

  /**
   * Handler function for Giving History list item click.
   */
  function handleGivingHistoryListItemClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingMenu,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingHistory,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingMenu,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      iconOverride: ICON_OVERRIDES.back,
      isOpen: true,
      modalId: modals.givingHistory.id,
    });
  }

  /**
   * Handler function for Manage Giving list item click.
   */
  function handleManageGivingListItemClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingMenu,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.manageGiving,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingMenu,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      iconOverride: ICON_OVERRIDES.back,
      isOpen: true,
      modalId: modals.manageGiving.id,
    });
  }

  /**
   * Handler function for Manage Payment Method list item click.
   */
  function handleManagePaymentMethodsListItemClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingMenu,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.managePaymentMethods,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingMenu,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      iconOverride: ICON_OVERRIDES.back,
      isOpen: true,
      modalId: modals.managePaymentMethod.id,
    });
  }

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    callSegmentPage({
      category: '',
      name: ANALYTICS.pages.givingMenu,
      properties: {
        logged_in: !!user,
        path: window?.location?.pathname,
        preferred_campus: preferredCampus,
        referrer: document?.referrer,
        screen_class: ANALYTICS.screens.classes.oneScreen,
        title: document?.title,
        url: /* istanbul ignore next */ window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });

    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingMenu,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingMenu,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseModal
      content={
        <div className="giving-menu" data-testid="giving-menu-modal">
          <GivingMenuList
            onGivingHistoryListItemClick={handleGivingHistoryListItemClick}
            onManageGivingListItemClick={handleManageGivingListItemClick}
            onManagePaymentMethodsListItemClick={
              handleManagePaymentMethodsListItemClick
            }
            preferredCampus={preferredCampus}
            user={user}
          />
        </div>
      }
      contentClassName={['pt-none', 'animatable'].join(' ')}
      header={
        <ModalHeader
          endButton={
            <div
              className="auth-menu giving-menu-auth justify flex-end"
              data-testid="auth-menu-giving-menu"
            >
              <ul className="menu-nav">
                <li>
                  <AuthButton onClick={handleAuthButtonClick} />
                </li>
              </ul>
            </div>
          }
          onCloseClick={handleClose}
          title={givingMenuStrings.main.title}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
