/* eslint-disable react/jsx-boolean-value */
/**
 * @module ProcessDateModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import DatePicker from 'react-datepicker';
import {
  ANALYTICS,
  FORM_MODES,
  STRINGS,
  calculateDaysOffset,
  closestTwiceMonthlyDate,
  relativeDateLabel,
} from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import useModals from '../../../hooks/useModals';
import 'react-datepicker/dist/react-datepicker.css';
import '../Modal.scss';
import './ProcessDate.scss';

/**
 * Represents the modal to show Process Date and calendar picker content for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {'main'|'manage-gift'} [props.mode] - The mode for which to use for the component (Default: 'main').
 *
 * @returns {React.ReactElement} The ProcessDateModal component.
 */
export function ProcessDateModal({ isOpen, mode = FORM_MODES.main }) {
  const { processDate: processDateStrings } = STRINGS.modals;
  const { giveForm: giveFormStrings } = STRINGS;
  const { user } = useAuth();
  const {
    getFrequency,
    preferredCampus,
    scheduledGiftData,
    storeScheduledGiftData,
    storeUserGivingData,
    today,
    userGivingData,
  } = useGiving();
  const { handleModalClose, modals } = useModals();

  // Confirm stored payment date is not in the past. If it is, it needs to be
  // adjusted and re-set to today.
  /* istanbul ignore next */
  if (mode === FORM_MODES.manageGift) {
    if (
      scheduledGiftData?.attributes?.next_payment_date &&
      today.getTime() > scheduledGiftData.attributes.next_payment_date * 1000
    ) {
      storeScheduledGiftData({
        attributes: {
          next_payment_date: today.getTime() / 1000,
        },
        id: scheduledGiftData.id,
        type: scheduledGiftData.type,
      });
    }
  } else if (
    userGivingData?.paymentDate &&
    today.getTime() > userGivingData.paymentDate * 1000
  ) {
    storeUserGivingData({
      ...userGivingData,
      paymentDate: today.getTime() / 1000,
    });
  }

  const frequencyObject = getFrequency(
    mode === FORM_MODES.manageGift
      ? scheduledGiftData?.attributes?.frequency
      : userGivingData?.frequency?.attributes?.name,
  );
  const paymentDateObject =
    mode === FORM_MODES.manageGift
      ? scheduledGiftData?.attributes?.next_payment_date
      : userGivingData?.paymentDate;
  const twiceMonthly = parseInt(frequencyObject?.id, 10) === 134;
  const day = new Date(
    /* istanbul ignore next */
    paymentDateObject ? paymentDateObject * 1000 : new Date(),
  );
  const firstOrFifteenth = closestTwiceMonthlyDate(day);

  /**
   * Handler function for the close event triggers.
   *
   * @param {Event} event - The Event object associated with the close event.
   */
  function handleClose(event) {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.givingDate,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label:
          event.target.textContent === STRINGS.labels.done
            ? STRINGS.labels.done
            : STRINGS.labels.close,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.givingDate,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalClose(modals.processDate.id);
  }

  /**
   * Convenience effect to check and store user data when twice monthly set.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (twiceMonthly && ![1, 15].includes(day.getDate())) {
      if (mode === FORM_MODES.manageGift) {
        storeScheduledGiftData({
          attributes: {
            next_payment_date: firstOrFifteenth.getTime() / 1000,
          },
          id: scheduledGiftData.id,
          type: scheduledGiftData.type,
        });
      } else {
        storeUserGivingData({
          ...userGivingData,
          paymentDate: firstOrFifteenth.getTime() / 1000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    const formattedDate = day.toISOString().split('T')[0]; // Outputs to YYYY-MM-DD format.
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        action: ANALYTICS.actions.presented,
        component: ANALYTICS.screens.names.givingDate,
        component_url: '',
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingDate,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: formattedDate,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseModal
      content={
        <>
          <div
            className="list process-date"
            data-mode={mode}
            data-testid="process-date-modal"
          >
            <DatePicker
              inline
              minDate={new Date()}
              onChange={
                /* istanbul ignore next */
                (newDate) => {
                  let updatedProcessDate = newDate;
                  if (twiceMonthly && ![1, 15].includes(newDate.getDate())) {
                    // Calculate next 1st or 15th and set to that date.
                    updatedProcessDate = closestTwiceMonthlyDate(newDate);
                  }
                  const formattedDate = updatedProcessDate
                    .toISOString()
                    .split('T')[0]; // Outputs to YYYY-MM-DD format.
                  const offset = calculateDaysOffset({
                    endDate: updatedProcessDate,
                    startDate: today,
                  });
                  callSegmentTrack({
                    event: ANALYTICS.events.givingValueUpdated,
                    properties: {
                      action: ANALYTICS.actions.updated,
                      component: ANALYTICS.screens.names.givingDate,
                      component_url: null,
                      context: ANALYTICS.contexts.oneScreen,
                      label: ANALYTICS.labels.date,
                      logged_in: !!user,
                      offset: Math.abs(offset),
                      preferred_campus: preferredCampus?.attributes?.code,
                      referrer: document?.referrer,
                      title: document?.title,
                      url: window?.location?.href,
                      user_id:
                        user?.['https://www.life.church/rock_person_alias_id'],
                      value: formattedDate,
                    },
                  });

                  if (mode === FORM_MODES.manageGift) {
                    storeScheduledGiftData({
                      attributes: {
                        next_payment_date: updatedProcessDate.getTime() / 1000,
                      },
                      id: scheduledGiftData.id,
                      type: scheduledGiftData.type,
                    });
                  } else {
                    storeUserGivingData({
                      ...userGivingData,
                      paymentDate: updatedProcessDate.getTime() / 1000,
                    });
                  }
                }
              }
              selected={
                /* istanbul ignore next */
                day
              }
              useWeekdaysShort={true}
            />
          </div>
          <div className="process">
            <p className="process-text">
              {processDateStrings.labels.process}
              {': '}
              {
                /* istanbul ignore next */
                !today
                  ? giveFormStrings.labels.today
                  : relativeDateLabel({
                      date:
                        (mode === FORM_MODES.manageGift && scheduledGiftData) ||
                        (mode === FORM_MODES.main && userGivingData)
                          ? day
                          : today,
                      relativeDate: today,
                    })
              }
            </p>
          </div>
        </>
      }
      contentClassName="pt-none"
      footer={
        <StyledButton
          className="full-width"
          onClick={handleClose}
          variant={ButtonVariants.primary}
        >
          {STRINGS.labels.done}
        </StyledButton>
      }
      header={
        <ModalHeader
          onCloseClick={handleClose}
          title={processDateStrings.title}
        />
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
}
