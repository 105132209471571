/**
 * @module ProfileApi
 */
import { apiClient } from '../core';
import { REQUEST_HEADERS } from '../../utils/constants';

/**
 * Retrieve user profile.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getUser({ accessToken } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.PROFILES_API_DOMAIN}/${process.env.PROFILES_API_VERSION}/people/me`,
  });
}

/**
 * Retrieve user paperless opt-in status for Giving.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function getPaperlessStatus({ accessToken } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  return apiClient.get({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
      },
    },
    url: `${process.env.PROFILES_API_DOMAIN}/${process.env.PROFILES_API_VERSION}/people/me/paperless-status?optin_value=True`,
  });
}

/**
 * Update the user profile.
 *
 * @param {object} params - The function params object.
 * @param {string} params.accessToken - The API access token.
 * @param {object} params.attributes - Data object of attribute key/value pairs to pass along to the endpoint. Initial target attributes are { paperless_statements_optin: 'True|False' }.
 *
 * @throws {Error} - Throws an error if access token missing or is invalid type, or if the object of attributes is null or blank.
 *
 * @returns {Promise<object>} The API response object.
 */
export async function updateUser({ accessToken, attributes } = {}) {
  if (!accessToken || typeof accessToken !== 'string') {
    throw new Error('Invalid access token provided.');
  }
  if (
    !attributes ||
    typeof attributes !== 'object' ||
    Object.keys(attributes).length < 1
  ) {
    throw new Error('Invalid attributes provided.');
  }
  return apiClient.patch({
    options: {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': REQUEST_HEADERS.contentType.values.applicationJson,
      },
    },
    params: attributes,
    url: `${process.env.PROFILES_API_DOMAIN}/${process.env.PROFILES_API_VERSION}/people/me`,
  });
}
