/**
 * @module SelectLabel
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './SelectLabel.scss';

/**
 * Represents a reusable selector element.
 *
 * @param {object} props - The component props object.
 * @param {*} [props.actionIcon] - The action icon to include, positioned on the far right side of the component (Default: Down-facing caret arrow icon).
 * @param {string} [props.label] - The text value to display.
 * @param {Function} [props.onClick] - Handler function for the click event.
 * @param {boolean} [props.showActionIcon] - Boolean flag denoting whether or not to show the action icon (Default: true).
 * @param {*} [props.startIcon] - The optional start icon to include, positioned on the far left side of the component.
 *
 * @returns {React.ReactElement} The SelectLabel component.
 */
function SelectLabel({
  actionIcon,
  label,
  onClick,
  showActionIcon = true,
  startIcon,
  ...passThroughProps
}) {
  const labelClass = ['select-label', passThroughProps?.className || ''].join(
    ' ',
  );

  return (
    <div
      className={labelClass}
      data-testid="select-label"
      id={passThroughProps.id || null}
    >
      <button className="action-button" onClick={onClick}>
        <label className="label-control">
          <div className="select-contents">
            {startIcon || null}
            <p className="truncate">{label ?? ''}</p>
          </div>
          {showActionIcon ? (
            <>{<div className="action-icon">{actionIcon || null}</div>}</>
          ) : null}
        </label>
      </button>
    </div>
  );
}

export default SelectLabel;
