/**
 * @module GoPaperless
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { StyledButton } from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import { base } from '@lifechurch/web-tools-io/dist/themes';
import LeafIcon from '../../../../ui/LeafIcon';
import { STRINGS } from '../../../../../utils';

/**
 * Convenience variable to store strings.
 */
const givingHistoryStrings = STRINGS.modals.givingHistory;

/**
 * Represents a section of content with prompts and CTAs for enrolling in paperless statements.
 *
 * @param {object} props - The component props object.
 * @param {Function} props.onDismissClick - Handler function for Dismiss button click.
 * @param {Function} props.onEnrollNowClick - Handler function for Enroll Now button click.
 *
 * @returns {React.ReactElement} The GoPaperless component.
 */
export function GoPaperless({
  onDismissClick,
  onEnrollNowClick,
  ...passThroughProps
}) {
  const paperlessPromptStrings = givingHistoryStrings.paperlessPrompt;

  return (
    <div
      className={`paperless-prompt ${passThroughProps?.className || ''}`.trim()}
    >
      <div className="flex row ai-center column-gap-16">
        <LeafIcon
          color={base.colors.utilityValidGreen}
          data-testid="paperless-prompt-leaf-icon"
          style={{ height: '48px', width: 'auto' }}
        />
        <div className="flex column">
          <h2>{paperlessPromptStrings.title}</h2>
          <p>{paperlessPromptStrings.body}</p>
        </div>
      </div>
      <div className="btn-lineup">
        <StyledButton
          onClick={onEnrollNowClick}
          variant={paperlessPromptStrings.ctas.enrollNow.buttonVariant}
        >
          {paperlessPromptStrings.ctas.enrollNow.label}
        </StyledButton>
        <StyledButton
          onClick={onDismissClick}
          variant={paperlessPromptStrings.ctas.dismiss.buttonVariant}
        >
          {paperlessPromptStrings.ctas.dismiss.label}
        </StyledButton>
      </div>
    </div>
  );
}
