/**
 * @module ModalContext
 */
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import useAuth from '../hooks/useAuth';
import useGiving from '../hooks/useGiving';
import { ANALYTICS, MODALS, SMART_PAY_PROVIDERS, STRINGS } from '../utils';

/**
 * @typedef {object} ModalContext
 * @property {ConfirmationDialogData} confirmationDialogData - Data object of confirmation dialog content.
 * @property {Function} handleModalClose - Handler function for modal close events.
 * @property {Function} handleModalVisibility - Convenience function to handle modal visibility events.
 * @property {object} modals - Data object of modals available and included in the Web Giving project.
 * @property {object} modalStateData - Convenience state to track visibility of modals, set as object containing data for modals, including eventual `include` and `isOpen` booleans.
 * @property {Function} storeConfirmationDialogData - Function to store confirmation dialog data content.
 */

export const ModalContext = React.createContext({
  confirmationDialogData: null,
  handleModalClose: null,
  handleModalVisibility: null,
  modals: null,
  modalStateData: null,
  storeConfirmationDialogData: null,
});
ModalContext.displayName = 'ModalContext';

/**
 * React Context Provider for Life.Church Web Giving Modals.
 *
 * @param {object} props - The component props object.
 * @param {React.ReactNode} props.children - The React children.
 *
 * @returns {React.ReactElement} The Modal Context provider.
 */
export function ModalProvider({ children, ...props }) {
  const { user } = useAuth();
  const { paymentMethods, preferredCampus, userGivingData } = useGiving();
  const defaultModalTiming = { dismissed: 0, presented: 0 };
  const [confirmationDialogData, setConfirmationDialogData] = React.useState({
    cancelLabel: null,
    confirmLabel: null,
    icon: null,
    isOpen: false,
    message: null,
    onCancelClick: null,
    onClose: null,
    onConfirmClick: null,
    title: null,
  });

  /**
   * Convenience state to track visibility of modals, set as object containing
   * data for modals, including eventual `include` and `isOpen` booleans.
   */
  const [modalStateData, setModalStateData] = React.useState({
    confirmation: MODALS.confirmation,
    frequency: MODALS.frequency,
    funds: MODALS.funds,
    givingHistory: MODALS.givingHistory,
    givingMenu: MODALS.givingMenu,
    help: MODALS.help,
    location: MODALS.location,
    manageGiving: MODALS.manageGiving,
    managePaymentMethod: MODALS.managePaymentMethod,
    paperlessPreference: MODALS.paperlessPreference,
    paymentMethod: MODALS.paymentMethod,
    processDate: MODALS.processDate,
  });

  /**
   * Convenience state to store and track modal timing, used for analytics.
   * When a modal is presented, it will be added/updated here as a key, set to
   * an object for `presented` and `dismissed`, which are ultimately used to
   * calculate the total time a user had the modal open.
   */
  const [modalTiming, setModalTiming] = React.useState({
    confirmation: defaultModalTiming,
    frequency: defaultModalTiming,
    funds: defaultModalTiming,
    givingHistory: defaultModalTiming,
    givingMenu: defaultModalTiming,
    help: defaultModalTiming,
    location: defaultModalTiming,
    manageGiving: defaultModalTiming,
    managePaymentMethod: defaultModalTiming,
    paperlessPreference: defaultModalTiming,
    paymentMethod: defaultModalTiming,
    processDate: defaultModalTiming,
  });

  /**
   * Convenience function to handle modal visibility events.
   *
   * @param {object} params - The function params object.
   * @param {string} [params.iconOverride] - Optional string for icon override to show in place of the default "x" close icon.
   * @param {boolean} params.isOpen - Boolean flag denoting whether or not the modal is open.
   * @param {string} params.modalId - Unique modal ID value.
   * @param {object} params.modalProps - Optional object of modal props to expose to the modal, useful for props spreading via passThroughProps or direct prop value setting.
   */
  const handleModalVisibility = React.useCallback(
    ({ iconOverride, isOpen, modalId, modalProps }) => {
      if (modalId) {
        setModalStateData((prevData) => {
          return {
            ...prevData,
            [modalId]: {
              ...prevData[modalId],
              iconOverride,
              isOpen,
              modalProps,
            },
          };
        });
      }

      if (modalId) {
        setModalTiming((prevModalTiming) => {
          return {
            ...prevModalTiming,
            [modalId]: {
              dismissed: null,
              presented: new Date().getTime(),
            },
          };
        });
      }
      if (!isOpen) {
        setTimeout(() => {
          setModalStateData((prevData) => {
            return {
              ...prevData,
              [modalId]: {
                ...prevData[modalId],
                include: isOpen,
              },
            };
          });
        }, 175); // Slightly shorter than SCSS-defined animation duration to ensure state updates w/o flicker.
      } else {
        setModalStateData((prevData) => {
          return {
            ...prevData,
            [modalId]: {
              ...prevData[modalId],
              include: isOpen,
            },
          };
        });
      }
    },
    [],
  );

  /**
   * Handler function for modal close events. Note that this function requires
   * and uses a modal `id` value to set the modal timing `dismissed` property,
   * and calculate total modal visible time from it compared with the modal's
   * property for `presented` and tracks the analytics event accordingly. It
   * also triggers the `handleModalVisibility` function with no argument, which
   * effectively closes the modal.
   *
   * @param {string} id - Unique modal ID value.
   */
  const handleModalClose = React.useCallback(
    (id) => {
      const dismissedTime = new Date().getTime();
      let modalLabel = null;
      let modalValue = null;
      switch (id) {
        case MODALS.confirmation.id:
          modalLabel =
            confirmationDialogData?.confirmLabel || STRINGS.labels.close;
          break;
        case MODALS.frequency.id:
          modalLabel = ANALYTICS.labels.givingFrequency;
          modalValue = userGivingData?.frequency?.attributes?.name;
          break;
        case MODALS.funds.id:
          modalLabel = ANALYTICS.labels.givingFund;
          modalValue = userGivingData?.fund?.attributes?.name;
          break;
        case MODALS.givingHistory.id:
          modalLabel = ANALYTICS.labels.givingHistory;
          break;
        case MODALS.givingMenu.id:
          modalLabel = ANALYTICS.labels.givingMenu;
          break;
        case MODALS.help.id:
          modalLabel = ANALYTICS.labels.givingHelp;
          break;
        case MODALS.location.id:
          modalLabel = ANALYTICS.labels.givingLocation;
          modalValue = userGivingData?.campus?.attributes?.name;
          break;
        case MODALS.manageGiving.id:
          modalLabel = ANALYTICS.labels.manageGiving;
          break;
        case MODALS.managePaymentMethod.id:
          modalLabel = ANALYTICS.labels.managePaymentMethod;
          break;
        case MODALS.paperlessPreference.id:
          modalLabel = ANALYTICS.labels.paperlessPreference;
          break;
        case MODALS.paymentMethod.id:
          modalLabel = ANALYTICS.labels.givingPaymentMethod;
          modalValue = Object.keys(SMART_PAY_PROVIDERS).includes(
            userGivingData?.paymentMethod?.attributes?.payment_method_type,
          )
            ? SMART_PAY_PROVIDERS[
                userGivingData?.paymentMethod?.attributes?.payment_method_type
              ].attributes?.display_label
            : userGivingData?.paymentMethod?.attributes?.payment_type;
          break;
        case MODALS.processDate.id:
          modalLabel = ANALYTICS.labels.givingDate;
          [modalValue] = new Date(userGivingData.paymentDate * 1000 || null)
            .toISOString()
            .split('T'); // Outputs to YYYY-MM-DD format.
          break;
        default:
          break;
      }

      setModalTiming((prevModalTiming) => {
        return {
          ...prevModalTiming,
          [id]: {
            ...prevModalTiming[id],
            dismissed: dismissedTime,
          },
        };
      });

      const segmentProps = {
        action: ANALYTICS.actions.dismissed,
        component: modalLabel,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        Duration: Math.abs(
          dismissedTime - (modalTiming[id] ? modalTiming[id].presented : 0),
        ),
        label: modalLabel,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: modalValue,
      };
      if (id === MODALS.paymentMethod.id) {
        segmentProps.Count = paymentMethods?.length;
      }

      callSegmentTrack({
        event: ANALYTICS.events.selectorDismissed,
        properties: segmentProps,
      });

      handleModalVisibility({ isOpen: false, modalId: id });
    },
    [
      confirmationDialogData,
      handleModalVisibility,
      modalTiming,
      paymentMethods,
      preferredCampus,
      user,
      userGivingData,
    ],
  );

  /**
   * Convenience function to store confirmation dialog data, which is a global
   * modal that hides/shows based on the properties within this data object.
   *
   * @param {ConfirmationDialogData} data - The confirmation dialog data object.
   */
  const storeConfirmationDialogData = React.useCallback((data) => {
    let newDialogData = {};
    setConfirmationDialogData((prevData) => {
      // Keep text-based data for transition in visibility to avoid thrash.
      const textBasedData = {
        cancelLabel: prevData.cancelLabel,
        confirmLabel: prevData.confirmLabel,
        icon: prevData.icon,
        message: prevData.message,
        title: prevData.title,
      };
      newDialogData = {
        ...textBasedData,
        ...data,
      };
      return newDialogData;
    });
    return newDialogData;
  }, []);

  const value = React.useMemo(
    () => ({
      confirmationDialogData,
      handleModalClose,
      handleModalVisibility,
      modals: MODALS,
      modalStateData,
      storeConfirmationDialogData,
    }),
    [
      confirmationDialogData,
      handleModalClose,
      handleModalVisibility,
      modalStateData,
      storeConfirmationDialogData,
    ],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ModalContext.Provider value={value} {...props}>
      {children}
    </ModalContext.Provider>
  );
}
