/**
 * @module ManageGift
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import ToggleSwitch from '@lifechurch/web-tools-io/dist/components/global/ToggleSwitch';
import { Amount } from '../../../../Amount';
import Header from '../../../../Header';
import { ListItem } from '../../../../ListItem';
import { PaymentIcon } from '../../../../PaymentIcon';
import RecurringPaymentIcon from '../../../../ui/RecurringPaymentIcon';
import LockIcon from '../../../../ui/LockIcon';
import WarningIcon from '../../../../ui/WarningIcon';
import useAuth from '../../../../../hooks/useAuth';
import useGiving from '../../../../../hooks/useGiving';
import useModals from '../../../../../hooks/useModals';
import {
  ANALYTICS,
  APPLE_PAY_PAYMENT_METHOD_OBJECT,
  FORM_MODES,
  GOOGLE_PAY_PAYMENT_METHOD_OBJECT,
  PAYMENT_METHOD_TYPES,
  PAYPAL_PAYMENT_METHOD_OBJECT,
  STRINGS,
  calculateMonthOffset,
  relativeDateLabel,
} from '../../../../../utils';
import '../../../Modal.scss';

/**
 * Represents the view for managing a scheduled gift for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {string} props.frequencyLabel - Label value for the Frequency list item.
 * @param {boolean} [props.isLocationCurrentLocation] - Boolean flag denoting whether or not the current location is the selected location.
 * @param {Function} [props.onFormChange] - Handler function for form change events.
 *
 * @returns {React.ReactElement} The ManageGift component.
 */
export function ManageGift({
  frequencyLabel,
  isLocationCurrentLocation,
  onFormChange,
}) {
  const { user } = useAuth();
  const {
    frequencies,
    getCampusByAttribute,
    getPaymentMethod,
    isScheduledGiftRecurringFrequency,
    preferredCampus,
    scheduledGiftData,
    storeScheduledGiftData,
    today,
    userPosition,
  } = useGiving();
  const { handleModalVisibility, modals, modalStateData } = useModals();
  const { giveForm: giveFormStrings } = STRINGS;
  const { manageGift: manageGiftStrings } = STRINGS.modals;
  const [campus, setCampus] = React.useState(null);
  const [
    paymentMethodListItemExpireStyle,
    setPaymentMethodListItemExpireStyle,
  ] = React.useState('default');
  const [paymentMethodDescription, setPaymentMethodDescription] =
    React.useState(null);
  const [paymentMethodTitle, setPaymentMethodTitle] = React.useState(null);
  const [isOwnedByUser, setIsOwnedByUser] = React.useState(true);
  const mode = FORM_MODES.manageGift;

  /**
   * Handler function for frequency select click event.
   */
  /* istanbul ignore next */
  function handleFrequencySelectClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.frequency,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.frequency.id,
    });
  }

  /**
   * Handler function for fund select click event.
   */
  /* istanbul ignore next */
  function handleFundSelectClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.fund,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.funds.id,
    });
  }

  /**
   * Handler function for help button click event.
   */
  /* istanbul ignore next */
  function handleHelpClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.help,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.help.id,
    });
  }

  /**
   * Handler function for location select click event.
   */
  /* istanbul ignore next */
  function handleLocationSelectClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.location,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.location.id,
    });
  }

  /**
   * Handler function for payment method select click event.
   */
  /* istanbul ignore next */
  function handlePaymentMethodSelectClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.paymentMethod,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.paymentMethod.id,
    });
  }

  /**
   * Handler function for payment method select click event.
   */
  /* istanbul ignore next */
  function handleProcessDateSelectClick() {
    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.processDate,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
      },
    });
    handleModalVisibility({
      isOpen: true,
      modalId: modals.processDate.id,
    });
  }

  /**
   * Handler function for recurring list item element click.
   *
   * Note: Ignore directive set as it's covered in main form tests, and the
   * logic for which is duplicated here.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  function handleRecurringListItemClick(event) {
    event.preventDefault();
    const newFrequencyName =
      scheduledGiftData?.attributes?.frequency
        .toLowerCase()
        .replace(' ', '')
        .replace('-', '') === 'onetime'
        ? 'monthly'
        : 'one time';
    const filteredFrequency =
      frequencies.filter((freq) => {
        return freq.attributes.name.toLowerCase() === newFrequencyName;
      })[0] || null;

    storeScheduledGiftData({
      attributes: {
        frequency: filteredFrequency?.attributes?.code,
      },
      id: scheduledGiftData.id,
      type: scheduledGiftData.type,
    });

    callSegmentTrack({
      event: ANALYTICS.events.buttonAction,
      properties: {
        action: ANALYTICS.actions.clicked,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.recurringToggle,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.manageGivingDetail,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: !isScheduledGiftRecurringFrequency ? 'on' : 'off',
      },
    });

    callSegmentTrack({
      event: ANALYTICS.events.givingValueUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.manageGivingDetail,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.frequency,
        logged_in: !!user,
        preferred_campus: preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        title: document?.title,
        url: window?.location?.href,
        user_id: user?.['https://www.life.church/rock_person_alias_id'],
        value: filteredFrequency?.attributes?.name,
      },
    });
  }

  /**
   * Represents a list item with side-by-side label and title, and down-facing caret arrow.
   *
   * @param {object} props - The component props object.
   * @param {string} props.label - The label text value.
   * @param {Function} props.onClick - Handler function for click event.
   * @param {string} props.title - The title text value.
   *
   * @returns {React.ReactElement} The DropDownButtonListItem component.
   */
  /* istanbul ignore next */
  const DropDownButtonListItem = ({
    label,
    onClick,
    title,
    ...passThroughProps
  }) => {
    const listItemClass = ['dropdown-button', passThroughProps?.className].join(
      ' ',
    );

    return (
      <ListItem
        className={listItemClass}
        description={label}
        onClick={onClick}
        title={title}
        titleProps={{ primary: true }}
      />
    );
  }; // NOSONAR

  /**
   * Represents a list item for recurring giving toggle, including a ToggleSwitch component.
   *
   * @returns {React.ReactElement} The RecurringListItem component.
   */
  /* istanbul ignore next */
  const RecurringListItem = () => {
    return (
      <ListItem
        actionIcon={<></>}
        className="flat-bottom"
        data-testid="recurring-toggle-switch"
        name="recurring-toggle-switch"
        onClick={handleRecurringListItemClick}
        showActionIcon={true}
        startIcon={<RecurringPaymentIcon color="#09c1a1" />}
        title={giveFormStrings.labels.recurring}
      />
    );
  }; // NOSONAR

  /**
   * Convenience effect to generate description and title values for payment
   * method item.
   *
   * Note: Ignore added due to scheduled gift data being set in test setup, and
   * components using it never being shown in the UI if scheduled gift data is
   * not set.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    if (scheduledGiftData?.attributes?.payment_method) {
      const paymentMethod = scheduledGiftData?.attributes?.payment_method;
      const { op_1: expirationLabel } = paymentMethod;
      const [expMonth, expYear] = expirationLabel
        ? expirationLabel.split('/')
        : [];
      const monthOffset =
        expMonth !== null && expYear !== null
          ? calculateMonthOffset({
              endDate: new Date(expYear, expMonth - 1), // Note: exp month is 1-based, not 0-based like JS Date object.
              startDate: today,
            })
          : null;
      const expirePrefix =
        STRINGS.modals.paymentMethod.select[
          `${monthOffset !== null && monthOffset < 0 ? 'expired' : 'expires'}`
        ];
      let labelExpireClass = 'default';
      /* istanbul ignore next */
      if (monthOffset < 0) {
        labelExpireClass = 'error';
      } else if (monthOffset < 2) {
        labelExpireClass = 'warning';
      }
      setPaymentMethodListItemExpireStyle(labelExpireClass);
      const icon = monthOffset < 0 ? <WarningIcon /> : null;

      setPaymentMethodDescription(
        expirationLabel && labelExpireClass === 'error' ? (
          <>
            {icon} {expirePrefix} {expirationLabel}
          </>
        ) : null,
      );

      /**
       * Since scheduled gift data payment_method is *not* the same structure as
       * those returned from payment methods retrieval, there is a need to fetch
       * the matching payment method object to get full access to its attributes
       * such as display_label.
       */
      const paymentMethodObject = getPaymentMethod(
        scheduledGiftData?.attributes?.payment_method?.id?.toString(),
      );
      let pmTitle =
        paymentMethodObject?.attributes?.name ||
        STRINGS.labels.selectPaymentMethod;
      /**
       * Note: For initial payment method title re-setting, check on the actual
       * scheduled gift data object, which does *not* have a display_label or
       * name attribute. In this check, the logic just inspects the payment
       * method type against wallet/smart pay options, overriding the value of
       * pmTitle if needed, from the default set above.
       */
      if (
        scheduledGiftData?.attributes?.payment_method?.apple_pay ||
        scheduledGiftData?.attributes?.payment_method?.payment_method_type ===
          APPLE_PAY_PAYMENT_METHOD_OBJECT.type
      ) {
        pmTitle = APPLE_PAY_PAYMENT_METHOD_OBJECT?.attributes?.display_label;
      } else if (
        scheduledGiftData?.attributes?.payment_method?.google_pay ||
        scheduledGiftData?.attributes?.payment_method?.payment_method_type ===
          GOOGLE_PAY_PAYMENT_METHOD_OBJECT.type
      ) {
        pmTitle = GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.attributes?.display_label;
      } else if (
        scheduledGiftData?.attributes?.payment_method?.payment_method_type ===
        PAYPAL_PAYMENT_METHOD_OBJECT?.attributes?.payment_method_type
      ) {
        pmTitle = paymentMethodObject
          ? paymentMethodObject?.attributes?.display_label
          : PAYPAL_PAYMENT_METHOD_OBJECT.attributes.display_label;
      }
      setPaymentMethodTitle(pmTitle);
    }
  }, [getPaymentMethod, scheduledGiftData, today]);

  /**
   * Convenience effect to set the state for whether or not the scheduled gift
   * is owned by the user.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (scheduledGiftData?.attributes) {
      setIsOwnedByUser(scheduledGiftData.attributes.is_owned_by_user);
    }
  }, [scheduledGiftData]);

  /**
   * Convenience effect to set the campus object.
   *
   * Note: Ignore added due to scheduled gift data being set in test setup, and
   * components using it never being shown in the UI if scheduled gift data is
   * not set.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (scheduledGiftData?.attributes) {
      const campusObject = getCampusByAttribute(
        'code',
        scheduledGiftData.attributes.campus,
      );
      setCampus(campusObject);
    }
  }, [getCampusByAttribute, scheduledGiftData]);

  /**
   * Convenience effect to detect scheduled gift data changes and trigger form change event.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (onFormChange && typeof onFormChange === 'function') {
      onFormChange(scheduledGiftData);
    }
  }, [onFormChange, scheduledGiftData]);

  /**
   * Note: Ignore directives added due to individual components having test
   * coverage for props and scheduledGiftData being set one-time in the test
   * setup data.
   */
  return (
    <>
      <div className="text-center position-relative" data-testid="manage-gift">
        <Header
          campusName={campus?.attributes?.name}
          includeLocation={
            /* istanbul ignore next */
            userPosition &&
            campus &&
            campus?.attributes?.code.toLowerCase() !== 'int'
          }
          isLocationCurrentLocation={isLocationCurrentLocation}
          mode={mode}
          onHelpClick={handleHelpClick}
          onLocationSelectClick={handleLocationSelectClick}
        />
        <Amount
          initialAmount={
            /* istanbul ignore next */ scheduledGiftData?.attributes?.amount ||
            ''
          }
          isModalOpen={modalStateData?.[modals?.funds?.id]?.include}
          mode={mode}
          onFundSelectClick={handleFundSelectClick}
        />
        <div className="grouped">
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            className="recurring-gift-wrapper"
            onClick={handleRecurringListItemClick}
          >
            <RecurringListItem />
            <ToggleSwitch
              checked={isScheduledGiftRecurringFrequency}
              className="toggle-switch"
            />
          </div>
          <SmoothCollapse expanded={isScheduledGiftRecurringFrequency}>
            <DropDownButtonListItem
              className={
                /* istanbul ignore next */ modalStateData[modals?.frequency?.id]
                  ?.include
                  ? 'open'
                  : 'closed'
              }
              label={giveFormStrings.labels.frequency}
              onClick={handleFrequencySelectClick}
              title={frequencyLabel}
            />
          </SmoothCollapse>
          <DropDownButtonListItem
            className={
              /* istanbul ignore next */ modalStateData[modals?.processDate?.id]
                ?.include
                ? 'open'
                : 'closed'
            }
            label={giveFormStrings.labels.processDate}
            onClick={handleProcessDateSelectClick}
            title={relativeDateLabel({
              date: /* istanbul ignore next */ scheduledGiftData?.attributes
                ?.next_payment_date
                ? new Date(
                    scheduledGiftData.attributes.next_payment_date * 1000 ||
                      today,
                  )
                : today,
              relativeDate: today,
            })}
          />
        </div>

        {
          /* istanbul ignore next */ !isOwnedByUser ? (
            <>
              <ListItem
                className="mt-24"
                showActionIcon={false}
                startIcon={
                  <PaymentIcon
                    height={21}
                    paymentMethodType={PAYMENT_METHOD_TYPES.security}
                  />
                }
                title={
                  manageGiftStrings.ownerNotUser.paymentMethodListItemLabel
                }
                titleProps={{ primary: true }}
              />
              <div className="flex row ai-center justify center gap-8">
                <LockIcon />
                <p>
                  {
                    manageGiftStrings.ownerNotUser
                      .paymentMethodListItemDisclaimer
                  }
                </p>
              </div>
            </>
          ) : (
            <ListItem
              className={`${[
                'dropdown-button',
                'mt-24',
                paymentMethodListItemExpireStyle,
                /* istanbul ignore next */ modalStateData[
                  modals?.paymentMethod?.id
                ]?.include
                  ? 'open'
                  : 'closed',
              ].join(' ')}`}
              description={paymentMethodDescription}
              onClick={handlePaymentMethodSelectClick}
              startIcon={
                <PaymentIcon
                  height={21}
                  paymentMethodType={
                    /* istanbul ignore next */ scheduledGiftData?.attributes
                      ?.payment_method.payment_method_type === 'Credit Card'
                      ? scheduledGiftData?.attributes?.payment_method
                          .payment_type
                      : scheduledGiftData?.attributes?.payment_method
                          .payment_method_type || 'Credit Card'
                  }
                />
              }
              title={paymentMethodTitle}
              titleProps={{ primary: true }}
            />
          )
        }

        {
          /* istanbul ignore next */ !isOwnedByUser ? (
            <div className="full-and-absolute z-9x7"></div>
          ) : null
        }
      </div>
    </>
  );
}
