/**
 * @module ManageGivingList
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@lifechurch/web-tools-io/dist/components/global/Buttons/StyledButton';
import useGiving from '../../../../../hooks/useGiving';
import { STRINGS } from '../../../../../utils';
import { ScheduledGiftEntryListItem } from '../../../GivingMenuModal/components/ScheduledGiftEntryListItem';
import '../../../Modal.scss';

/**
 * Convenience variable to store strings.
 */
const manageGivingStrings = STRINGS.modals.manageGiving.main;

/**
 * Represents the view used when manage giving list is empty.
 *
 * @param {object} props - The component props object.
 * @param {Function} [props.onGiveNowClick] - Handler function for Give Now button click event.
 *
 * @returns {React.ReactElement} The ManageGivingEmpty component.
 */
function ManageGivingEmpty({ onGiveNowClick }) {
  return (
    <div className="no-manage-giving">
      <div>
        <h2>{manageGivingStrings.empty.title}</h2>
        <p>{manageGivingStrings.empty.body}</p>
        <StyledButton
          className="black full-width ml-0 mr-0"
          onClick={onGiveNowClick}
          variant={ButtonVariants.primary}
        >
          {manageGivingStrings.empty.cta.label}
        </StyledButton>
      </div>
    </div>
  );
}

/**
 * Represents a list of managed giving for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {React.MutableRefObject} props.contentRef - The mutable content Ref for the list screen content wrapper.
 * @param {PaymentMethod} props.giftListData - The object of scheduled gift data.
 * @param {Function} [props.onEntryListItemClick] - Handler function for the list item click event.
 * @param {Function} [props.onGiveNowClick] - Handler function for Give Now button click event.
 *
 * @returns {React.ReactElement} The ManageGivingList component.
 */
export function ManageGivingList({
  contentRef,
  giftListData,
  onEntryListItemClick,
  onGiveNowClick,
}) {
  const { getFund } = useGiving();

  return (
    <div
      className="screen-content"
      data-testid="manage-giving-list"
      ref={contentRef}
    >
      {giftListData?.length ? (
        <div className="list list-container grouped">
          {giftListData.map((entry) => {
            const fundIcon = getFund(entry?.attributes?.fund)?.attributes?.icon;
            return (
              <ScheduledGiftEntryListItem
                data={entry}
                icon={fundIcon}
                key={entry?.id}
                onClick={onEntryListItemClick}
              />
            );
          })}
        </div>
      ) : (
        <ManageGivingEmpty onGiveNowClick={onGiveNowClick} />
      )}
    </div>
  );
}
