/**
 * @module HelpOutlineIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents an icon for an outlined help icon.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The HelpOutlineIcon element.
 */
export default function HelpOutlineIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="help-outline-icon">
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3903_828)">
          <path
            d="M14.933 22.3987H17.0663V20.2654H14.933V22.3987ZM15.9997 5.33203C10.1117 5.33203 5.33301 10.1107 5.33301 15.9987C5.33301 21.8867 10.1117 26.6654 15.9997 26.6654C21.8877 26.6654 26.6663 21.8867 26.6663 15.9987C26.6663 10.1107 21.8877 5.33203 15.9997 5.33203ZM15.9997 24.532C11.2957 24.532 7.46634 20.7027 7.46634 15.9987C7.46634 11.2947 11.2957 7.46536 15.9997 7.46536C20.7037 7.46536 24.533 11.2947 24.533 15.9987C24.533 20.7027 20.7037 24.532 15.9997 24.532ZM15.9997 9.5987C13.6423 9.5987 11.733 11.508 11.733 13.8654H13.8663C13.8663 12.692 14.8263 11.732 15.9997 11.732C17.173 11.732 18.133 12.692 18.133 13.8654C18.133 15.9987 14.933 15.732 14.933 19.1987H17.0663C17.0663 16.7987 20.2663 16.532 20.2663 13.8654C20.2663 11.508 18.357 9.5987 15.9997 9.5987Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_3903_828">
            <rect fill="white" height="32" width="32" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
