/**
 * @module ScheduledGiftEntryListItem
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import useGiving from '../../../../../hooks/useGiving';
import {
  CONTENT_MODES,
  MONTHS,
  STRINGS,
  formatWithCommas,
} from '../../../../../utils';
import { ListItem } from '../../../../ListItem';

/**
 * Convenience variable to store strings.
 */
const manageGivingStrings = STRINGS.modals.manageGiving.main;

/**
 * Represents a list item for a specific scheduled gift entry.
 *
 * @param {object} props - The component props object.
 * @param {PaymentMethod} props.data - The scheduled gift data object.
 * @param {string} props.icon - Fund icon value used for the startIcon src.
 * @param {Function} props.onClick - Handler function for the list item click event.
 *
 * @returns {React.ReactElement} The ScheduledGiftEntryListItem component.
 */
export function ScheduledGiftEntryListItem({ data, icon, onClick }) {
  const { getFrequency } = useGiving();

  /* istanbul ignore next */
  if (!data?.attributes || !data?.id) {
    return null;
  }

  const entryDate = new Date(data.attributes.next_payment_date * 1000);
  const entryMonthObject = MONTHS[entryDate?.getMonth()];

  return (
    <ListItem
      data-testid="scheduled-gift-entry-list-item"
      description={`${manageGivingStrings.list.datePrefix}: ${
        entryMonthObject?.full
      } ${entryDate?.getDate()}, ${entryDate?.getFullYear()}<br />${
        getFrequency(data?.attributes?.frequency)?.attributes?.name
      } ${manageGivingStrings.list.frequencySuffix}`}
      descriptionMode={CONTENT_MODES.dangerouslySetInnerHTML}
      id={data?.id}
      key={data?.id}
      onClick={
        /* istanbul ignore next */ () => {
          onClick(data);
        }
      }
      startIcon={<img alt={`${data?.attributes?.fund}`} src={icon} />}
      title={`$${formatWithCommas(data?.attributes?.amount)}`}
      titleProps={{ primary: true }}
    />
  );
}
