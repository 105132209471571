/**
 * @module GivingHistoryIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents an icon for Giving history.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The GivingHistoryIcon element.
 */
export default function GivingHistoryIcon({ color = '#000000' }) {
  return (
    <div className="icon" data-color={color} data-testid="giving-history-icon">
      <svg
        fill="none"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.9 28C12.5889 28 9.77778 26.8278 7.46667 24.4833C5.15556 22.1389 4 19.3 4 15.9667H6C6 18.7444 6.95555 21.1111 8.86667 23.0667C10.7778 25.0222 13.1222 26 15.9 26C18.7222 26 21.1111 25.0111 23.0667 23.0333C25.0222 21.0556 26 18.6556 26 15.8333C26 13.0778 25.0111 10.75 23.0333 8.85C21.0556 6.95 18.6778 6 15.9 6C14.3889 6 12.9722 6.34444 11.65 7.03333C10.3278 7.72222 9.17778 8.63333 8.2 9.76666H11.7V11.7667H4.73333V4.83333H6.73333V8.36667C7.88889 7.01111 9.26111 5.94444 10.85 5.16667C12.4389 4.38889 14.1222 4 15.9 4C17.5667 4 19.1333 4.31111 20.6 4.93333C22.0667 5.55556 23.35 6.40556 24.45 7.48333C25.55 8.56111 26.4167 9.82222 27.05 11.2667C27.6833 12.7111 28 14.2667 28 15.9333C28 17.6 27.6833 19.1667 27.05 20.6333C26.4167 22.1 25.55 23.3778 24.45 24.4667C23.35 25.5555 22.0667 26.4167 20.6 27.05C19.1333 27.6833 17.5667 28 15.9 28ZM20.1667 21.4333L15.0333 16.3667V9.23333H17.0333V15.5333L21.6 20L20.1667 21.4333Z"
          fill={color}
        />
      </svg>
    </div>
  );
}
