/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * @module LifeChurchIcon
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

/**
 * Represents a LifeChurch logo icon element.
 *
 * @param {object} props - The component prop object.
 * @param {string} [props.color] - The color to apply to the icon (Default: '#000000').
 *
 * @returns {React.ReactElement} The LifeChurch Icon element.
 */
export default function LifeChurchIcon({ color = '#000000' }) {
  return (
    <div className="icon">
      <svg
        fill="none"
        height="20"
        viewBox="0 0 36 20"
        width="36"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M17.7268 7.65066C17.4894 8.03559 16.144 9.96026 15.1152 11.5L21.2089 10.6531L21.6837 10.5762C22.7917 10.4222 23.1874 9.42136 23.2665 9.19039C24.5327 6.49585 25.7989 6.34188 28.1731 5.87996C33.2379 4.80214 35.6121 0.875815 35.6121 0.798828C32.7631 1.26075 28.5688 1.95363 28.5688 1.95363C20.8132 3.33939 19.0722 5.49503 17.7268 7.65066Z"
          fill={color}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M19.626 16.2765C19.626 16.2765 26.3527 15.5066 28.806 14.7367C33.0004 13.351 34.4249 10.2715 34.4249 10.2715C34.4249 10.2715 28.0147 10.9644 25.1657 12.1962C24.6117 12.4271 22.7915 12.8121 19.626 16.2765Z"
          fill={color}
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M15.7485 16.7361C19.7846 14.8884 20.8134 12.5018 20.8134 12.5018C20.8134 12.5018 19.7846 12.5788 18.3601 12.8097L13.2953 13.5026C12.8204 14.1185 11.9499 15.1193 11.7125 15.3503C11.0794 16.0432 10.6837 16.0432 9.10092 16.1971C8.70523 16.1971 8.54695 15.9662 8.78437 15.5043L10.4463 13.0407C10.6045 12.7327 10.842 12.4248 10.9211 12.2708L16.4608 4.0332C11.0794 5.18801 7.59729 7.5746 6.09366 9.80722L0 19.1996L14.5615 17.198C14.9572 17.044 15.432 16.89 15.7485 16.7361Z"
          fill={color}
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
}
