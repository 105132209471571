/**
 * @module MailingAddressScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@lifechurch/web-tools-io/dist/utils/helpers/analytics';
import { base } from '@lifechurch/web-tools-io/dist/themes';
import { ANALYTICS, COUNTRIES, STRINGS, US_STATES } from '../../../../../utils';
import lockIcon from '../../../../../assets/svg/icon-lock.svg';
import '../../PaperlessPreference.scss';

const { 'mailing-address': mailingAddressStrings } =
  STRINGS.modals.paperlessPreference;
const FORM_FIELDS = {
  city: 'city',
  country: 'country',
  postal_code: 'postal_code',
  state: 'state',
  street_1: 'street_1',
  street_2: 'street_2',
};
const FORM_FIELD_ID_PREFIX = 'mailing-address-form-';
const FORM_FIELD_IDS = {
  city: `${FORM_FIELD_ID_PREFIX}${FORM_FIELDS.city}`,
  country: `${FORM_FIELD_ID_PREFIX}${FORM_FIELDS.country}`,
  postal_code: `${FORM_FIELD_ID_PREFIX}${FORM_FIELDS.postal_code}`,
  state: `${FORM_FIELD_ID_PREFIX}${FORM_FIELDS.state}`,
  street_1: `${FORM_FIELD_ID_PREFIX}${FORM_FIELDS.street_1}`,
  street_2: `${FORM_FIELD_ID_PREFIX}${FORM_FIELDS.street_2}`,
};
const STYLES = {
  country: {
    backgroundColor: base.colors.gray30,
    backgroundImage: `url(${lockIcon})`,
    backgroundPosition: 'right 16px center',
    backgroundRepeat: 'no-repeat',
    color: base.colors.gray130,
    paddingRight: '34px', // Right padding + 8px gap + width of icon.
  },
};
const zipCodeRegEx = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

/**
 * Represents a screen for mailing address input and update.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {object} [props.formData] - Data object of form data values. Attributes align with form input field names.
 * @param {Function} [props.onFormChange] - Handler function for form change event trigger. The form data object is sent as function argument.
 *
 * @returns {React.ReactElement} The MailingAddressScreen component.
 */
export function MailingAddressScreen({
  className,
  formData,
  onFormChange,
  ...passThroughProps
}) {
  /**
   * Handler function for form field change event.
   *
   * @param {Event} event - The Event object associated with the change.
   */
  function handleFormFieldChange(event) {
    const fieldName = event?.target?.name;
    const fieldValue = event?.target?.value;
    let isValid = fieldValue;
    if (fieldName === FORM_FIELDS.postal_code) {
      isValid = fieldValue && zipCodeRegEx.test(fieldValue);
    }
    if (fieldName === FORM_FIELDS.state) {
      isValid = US_STATES.filter((stateObj) => {
        return stateObj.code === fieldValue;
      }).length;
    }
    // Street Address 2 is optional and should never be invalid.
    if (fieldName === FORM_FIELDS.street_2) {
      isValid = true;
    }
    onFormChange({
      attributes: {
        ...formData?.attributes,
        [fieldName]: /* istanbul ignore next */ fieldValue,
      },
      errors: {
        ...formData?.errors,
        [fieldName]: /* istanbul ignore next */ !isValid,
      },
    });
  }

  /**
   * Handler function for form field change event.
   *
   * @param {Event} event - The Event object associated with the change.
   */
  function handleFormFieldBlur(event) {
    const fieldName = event?.target?.name;
    callSegmentTrack({
      event: ANALYTICS.events.mailingAddressUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.mailingAddress,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels[fieldName],
        logged_in: !!passThroughProps?.user,
        preferred_campus: passThroughProps?.preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.mailingAddress,
        title: document?.title,
        url: window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
        value: /* istanbul ignore next */ event?.target?.value,
      },
    });
  }

  /**
   * Handler function for form field change event.
   *
   * @param {Event} event - The Event object associated with the change.
   */
  function handleFormFieldFocus(event) {
    const fieldName = event?.target?.name;
    callSegmentTrack({
      event: ANALYTICS.events.mailingAddressUpdated,
      properties: {
        action: ANALYTICS.actions.updated,
        component: ANALYTICS.screens.names.mailingAddress,
        component_url: null,
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels[fieldName],
        logged_in: !!passThroughProps?.user,
        preferred_campus: passThroughProps?.preferredCampus?.attributes?.code,
        referrer: document?.referrer,
        screen: ANALYTICS.screens.names.mailingAddress,
        title: document?.title,
        url: window?.location?.href,
        user_id:
          passThroughProps?.user?.[
            'https://www.life.church/rock_person_alias_id'
          ],
        value: /* istanbul ignore next */ event?.target?.value,
      },
    });
  }

  return (
    <div
      className={`mailing-address-screen ${className || ''}`.trim()}
      data-testid="mailing-address-screen"
    >
      <div className="screen-content">
        <div className="list">
          <div className="content-block form-container list-container vertical">
            <div className="form-field-control">
              <label htmlFor={FORM_FIELD_IDS.country}>
                {mailingAddressStrings.labels.country}
              </label>
              <input
                className={formData?.errors?.country ? 'error' : ''}
                data-testid={FORM_FIELD_IDS.country}
                disabled={true}
                id={FORM_FIELD_IDS.country}
                name={FORM_FIELDS.country}
                style={STYLES.country}
                type="text"
                value={`${
                  formData?.attributes?.country?.name ||
                  COUNTRIES.find((country) => {
                    return country.code.toUpperCase() === 'US';
                  })?.name
                }`}
              />
              <p className="input-disclaimer mb-0 mt-4">
                {mailingAddressStrings.disclaimers.unitedStatesOnly}
              </p>
            </div>
            <div className="form-field-control">
              <label htmlFor={FORM_FIELD_IDS.street_1}>
                {mailingAddressStrings.labels.streetAddress1}
              </label>
              <input
                className={formData?.errors?.street_1 ? 'error' : ''}
                data-testid={FORM_FIELD_IDS.street_1}
                id={FORM_FIELD_IDS.street_1}
                name={FORM_FIELDS.street_1}
                onBlur={handleFormFieldBlur}
                onChange={handleFormFieldChange}
                onFocus={handleFormFieldFocus}
                placeholder={mailingAddressStrings.placeholders.streetAddress1}
                type="text"
                value={formData?.attributes?.street_1 || ''}
              />
              {formData?.errors?.street_1 ? (
                <p className="input-disclaimer error mb-0 mt-4">
                  {mailingAddressStrings.errors.completeRequiredField}
                </p>
              ) : null}
            </div>
            <div className="form-field-control">
              <label htmlFor={FORM_FIELD_IDS.street_2}>
                {mailingAddressStrings.labels.streetAddress2}
              </label>
              <input
                className={formData?.errors?.street_2 ? 'error' : ''}
                data-testid={FORM_FIELD_IDS.street_2}
                id={FORM_FIELD_IDS.street_2}
                name={FORM_FIELDS.street_2}
                onBlur={handleFormFieldBlur}
                onChange={handleFormFieldChange}
                onFocus={handleFormFieldFocus}
                placeholder={mailingAddressStrings.placeholders.streetAddress2}
                type="text"
                value={formData?.attributes?.street_2 || ''}
              />
              {formData?.errors?.street_2 ? (
                <p className="input-disclaimer error mb-0 mt-4">
                  {mailingAddressStrings.errors.completeRequiredField}
                </p>
              ) : null}
            </div>
            <div className="form-field-control">
              <label htmlFor={FORM_FIELD_IDS.city}>
                {mailingAddressStrings.labels.city}
              </label>
              <input
                className={formData?.errors?.city ? 'error' : ''}
                data-testid={FORM_FIELD_IDS.city}
                id={FORM_FIELD_IDS.city}
                name={FORM_FIELDS.city}
                onBlur={handleFormFieldBlur}
                onChange={handleFormFieldChange}
                onFocus={handleFormFieldFocus}
                placeholder={mailingAddressStrings.placeholders.city}
                type="text"
                value={formData?.attributes?.city || ''}
              />
              {formData?.errors?.city ? (
                <p className="input-disclaimer error mb-0 mt-4">
                  {mailingAddressStrings.errors.completeRequiredField}
                </p>
              ) : null}
            </div>
            <div className="form-field-control">
              <label htmlFor={FORM_FIELD_IDS.state}>
                {mailingAddressStrings.labels.state}
              </label>
              <div className="custom-select">
                <select
                  className={formData?.errors?.state ? 'error' : ''}
                  data-testid={FORM_FIELD_IDS.state}
                  id={FORM_FIELD_IDS.state}
                  name={FORM_FIELDS.state}
                  onBlur={handleFormFieldBlur}
                  onChange={handleFormFieldChange}
                  onFocus={handleFormFieldFocus}
                  value={formData?.attributes?.state || ''}
                >
                  <option value=""></option>
                  {US_STATES.map((state) => {
                    return (
                      <option
                        key={`state-${state.code.toLowerCase()}`}
                        value={state.code}
                      >
                        {state.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {formData?.errors?.state ? (
                <p className="input-disclaimer error mb-0 mt-4">
                  {mailingAddressStrings.errors.completeRequiredField}
                </p>
              ) : null}
            </div>
            <div className="form-field-control">
              <label htmlFor={FORM_FIELD_IDS.postal_code}>
                {mailingAddressStrings.labels.zipCode}
              </label>
              <input
                className={formData?.errors?.postal_code ? 'error' : ''}
                data-testid={FORM_FIELD_IDS.postal_code}
                id={FORM_FIELD_IDS.postal_code}
                name={FORM_FIELDS.postal_code}
                onBlur={handleFormFieldBlur}
                onChange={handleFormFieldChange}
                onFocus={handleFormFieldFocus}
                placeholder={mailingAddressStrings.placeholders.postal_code}
                type="text"
                value={formData?.attributes?.postal_code || ''}
              />
              {formData?.errors?.postal_code ? (
                <p className="input-disclaimer error mb-0 mt-4">
                  {mailingAddressStrings.errors.completeRequiredField}
                </p>
              ) : null}
              <p
                className={`input-disclaimer mb-0 mt-${
                  formData?.errors?.postal_code ? '0' : '4'
                }`}
              >
                {mailingAddressStrings.disclaimers.mailCommunication}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
