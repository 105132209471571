/**
 * @module RadioListItem
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { CONTENT_MODES, LAYOUT_MODES } from '../../utils';
import './RadioListItem.scss';

/**
 * Represents an Radio List Item form control element.
 *
 * @param {object} props - The component props object.
 * @param {*} [props.currentLocationIcon] - Optional icon to render in the component when isCurrentLocation is true.
 * @param {string} [props.description] - Optional description (sub-text) for the component.
 * @param {'dangerouslySetInnerHTML'|'default'} [props.descriptionMode] - Optional mode to render the description (Default: 'default').
 * @param {*} [props.icon] - Optional icon to render on the left side of the component.
 * @param {string} props.inputName - The name of the input, used as <input> element `name` attribute.
 * @param {boolean} [props.isCurrentLocation] - Boolean flag denoting whether or not the card is user's current location.
 * @param {boolean} [props.isMyLocation] - Boolean flag denoting whether or not the card is user's location.
 * @param {string} [props.label] - Optional label to display next to the title.
 * @param {'horizontal'|'vertical'} [props.layout] - Optional layout direction for item content (Default: 'horizontal').
 * @param {*} [props.myLocationIcon] - Optional icon to render in the component when isMyLocation is true.
 * @param {Function} [props.onChange] - Handler function for change events.
 * @param {string} [props.selectedValue] - The value passed to set whether or not the item is checked/selected.
 * @param {string} [props.title] - The main text title content for the component.
 * @param {TitleProps} [props.titleProps] - Optional title props to apply to the title element.
 * @param {('checkbox'|'radio')} props.type - The type of input rendered in the component.
 * @param {number|string} props.value - The value of the component input.
 *
 * Represents the RadioListItem used for Life.Church Web Giving.
 *
 * @returns {React.ReactElement} The RadioListItem component.
 */
export function RadioListItem({
  description,
  descriptionMode = CONTENT_MODES.default,
  icon,
  inputName,
  isCurrentLocation,
  isMyLocation,
  label,
  layout = LAYOUT_MODES.horizontal,
  currentLocationIcon,
  myLocationIcon,
  onChange = () => {},
  selectedValue,
  title,
  titleProps = { primary: false },
  type,
  value,
  ...passThroughProps
}) {
  /**
   * Note: With the addition of passThroughProps (i.e. `rest`), it allows the
   * component to get and use additional props, such as `className`. As such,
   * use a dynamically-built list of class names, based on the standard for the
   * component, but adding in additionally-provided values as well.
   */
  const wrapperClass = [
    'form-control',
    'radio-list-item',
    passThroughProps?.className || '',
  ].join(' ');

  return (
    <div className={wrapperClass} data-testid="radio-list-item">
      <label className="label">
        <input
          checked={value === selectedValue}
          className={[type || '', 'radio'].join(' ')}
          data-testid={`radio-list-item-input-${value}`}
          data-value={value}
          name={inputName}
          onChange={onChange}
          type={type || 'radio'}
        />
        <div className={`content-${layout}`}>
          {icon || null}
          {label ? <div className="label-text">{label}</div> : null}
          <div className="text text-container">
            {title ? (
              <span
                className={[
                  titleProps?.primary ? 'primary' : '',
                  'title-text',
                ].join(' ')}
              >
                {title}
              </span>
            ) : null}
            {isCurrentLocation || isMyLocation ? (
              <div className="chip-container">
                {isCurrentLocation ? (
                  <span className="current-location">
                    {currentLocationIcon || null}
                  </span>
                ) : null}
                {isMyLocation ? (
                  <span className="my-location">{myLocationIcon || null}</span>
                ) : null}
              </div>
            ) : null}
            {description ? (
              <>
                {descriptionMode === CONTENT_MODES.dangerouslySetInnerHTML ? (
                  <div
                    className="description-text"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></div>
                ) : (
                  <div className="description-text">{description}</div>
                )}
              </>
            ) : null}
          </div>
        </div>
      </label>
    </div>
  );
}
export default RadioListItem;
